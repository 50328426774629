import { EventEmitter } from 'events';
import { ConnectionErrors } from './ConnectionErrors';
import EthConnection from './EthConnection';
import { NETWORK_ID } from 'common-contracts';
import { PlanetId, PlanetType } from 'common-types';
import { decodePlanetFromContract, RawValhallaPlanetWithMetadata } from 'common-serde';
import { decodePlanetFromJSON, planetIdFromEthersBN, planetIdToDecStr } from 'common-utils';
import type { BigNumber as EthersBN } from 'ethers';
import { Valhalla, ValhallaGetters } from 'common-contracts/typechain';
import { sleep } from './BackendUtils';
import {
  EthConnectionType,
  EthTxType,
  getRandomActionId,
  isClaimSpecialTx,
  isClaimWinnerTx,
  isTransferPlanetTx,
  SubmittedTx,
  UnconfirmedClaimSpecial,
  UnconfirmedClaimWinner,
  UnconfirmedTransferPlanet,
} from '../_types/transactions';
import { Planet } from '../_types/global';
import { monomitter, Monomitter } from '../Frontend/Utils/Monomitter';

const isProd = process.env.NODE_ENV === 'production';
const rawPlanetJSONs = require('./planets.json');
const twitterMap: { [address: string]: string } = require('./twitterMap.json');

export enum DataManagerEvent {
  AccountChanged = 'AccountChanged',
  LoadedInitialData = 'LoadedInitialData',
  UpdateChainId = 'UpdateChainId',
  TxInitialized = 'TxInitialized',
  TxSubmitted = 'TxSubmitted',
  TxFailed = 'TxFailed',
  TxConfirmed = 'TxConfirmed',
  TxReverted = 'TxReverted',
  ReloadedPlanet = 'ReloadedPlanet',
}
export default class DataManager extends EventEmitter {
  private ethConnection: EthConnection;
  private coreContract: Valhalla;
  private gettersContract: ValhallaGetters;
  private chainId: number;
  private myAddress: string | undefined; // undefined <> not connected to metamask

  private planets: Map<PlanetId, Planet>;

  private rankedPlanetsByRound: { [round: number]: { [rank: number]: PlanetId } } = {};

  public planetUpdated$: Monomitter<PlanetId>;

  constructor(ethConnection: EthConnection, myAddress: string | undefined, chainId: number) {
    super();

    this.planetUpdated$ = monomitter();

    this.ethConnection = ethConnection;
    this.chainId = chainId;
    this.coreContract = this.ethConnection.loadCoreContract(myAddress !== undefined);
    this.coreContract.on('Transfer', (_, __, rawId: EthersBN) => {
      this.reloadSinglePlanet(planetIdFromEthersBN(rawId));
    });
    this.gettersContract = this.ethConnection.loadGettersContract(myAddress !== undefined);

    console.log('myAddress', myAddress);

    if (myAddress) {
      this.myAddress = myAddress;
      // we are connected to metamask
      window.ethereum.on('accountsChanged', (accounts: string[]) => {
        this.myAddress = accounts[0];
        this.emit(DataManagerEvent.AccountChanged, this.myAddress);
      });
      window.ethereum.on('chainChanged', () => window.location.reload());
    }

    this.planets = new Map();
    for (const rawPlanetJSON of rawPlanetJSONs) {
      const planet: Planet = decodePlanetFromJSON(rawPlanetJSON);
      if (twitterMap[planet.originalWinner.toLowerCase()]) {
        planet.originalWinnerTwitter = twitterMap[planet.originalWinner.toLowerCase()];
      }
      this.setPlanet(planet.id, planet);

      const roundMap = this.rankedPlanetsByRound[planet.roundId];
      if (!roundMap) this.rankedPlanetsByRound[planet.roundId] = {};
      this.rankedPlanetsByRound[planet.roundId][planet.rank] = planet.id;
    }

    this.emit(DataManagerEvent.UpdateChainId);

    console.log('address:', this.myAddress);
    this.emit(DataManagerEvent.AccountChanged, this.myAddress);
    this.initialLoadAllPlanets();

    console.log(this.chainId);
  }

  public getIdWithRoundAndRank(round: number, rank: number): PlanetId | undefined {
    if (!this.rankedPlanetsByRound[round]) return undefined;
    return this.rankedPlanetsByRound[round][rank] || undefined;
  }

  public getPlanetById(id: PlanetId | undefined): Planet | undefined {
    return id ? this.planets.get(id) : undefined;
  }

  public getSpecialPrizePlanetByWinner(
    round: number,
    ownerId: string | undefined
  ): Planet | undefined {
    if (ownerId === undefined) {
      return undefined;
    }

    return Array.from(this.planets.values()).find(
      (p) => p.originalWinner === ownerId && p.roundId === round && p.rank === 0
    );
  }

  public getPlanetByRoundAndWinner(round: number, ownerId: string | undefined): Planet | undefined {
    if (ownerId === undefined) {
      return undefined;
    }

    return Array.from(this.planets.values()).find(
      (p) => p.originalWinner === ownerId && p.roundId === round
    );
  }

  public static async create(connectionType: EthConnectionType): Promise<DataManager> {
    // if you're already connected to metamask, ignore the user-provided arg and
    // just use metamask
    if (window.ethereum?.selectedAddress) {
      connectionType = EthConnectionType.METAMASK;
    }

    let myAddress: string | undefined;

    console.log('connection type:', connectionType);
    if (connectionType === EthConnectionType.METAMASK) {
      const retValue = await EthConnection.enableMetamask();
      console.log('return value', retValue);
      myAddress = retValue;
    }
    const ethConnection = new EthConnection(connectionType);

    const chainId = await ethConnection.getChainId();
    if (chainId === undefined) {
      throw new Error(ConnectionErrors.UNKNOWN_ERROR);
    } else if (chainId !== NETWORK_ID) {
      throw new Error(ConnectionErrors.WRONG_NETWORK);
    }

    return new DataManager(ethConnection, myAddress, chainId);
  }

  /**
   * This must be called from outside this class, so that the user of this class
   * can set up event listeners before txs are loaded
   */
  public loadSubmittedTxsFromStorage(): void {
    const txList = JSON.parse(
      localStorage.getItem(`${this.coreContract.address}-submittedTxs`) || '[]'
    ) as string[];
    for (const txHash of txList) {
      const submittedTxStr = localStorage.getItem(`${this.coreContract.address}-${txHash}`);
      if (submittedTxStr) {
        const submittedTx = JSON.parse(submittedTxStr) as SubmittedTx;
        this.onTxSubmit(submittedTx, false);
      }
    }
  }

  public isCorrectNetwork() {
    return this.chainId === NETWORK_ID;
  }

  public checkChainId() {
    if (this.chainId !== NETWORK_ID) {
      throw new Error(ConnectionErrors.WRONG_NETWORK);
    }
  }

  public async upgradeToMetamask(): Promise<DataManager> {
    if (this.myAddress) {
      // already connected to metamask
      return this;
    }

    this.myAddress = await EthConnection.enableMetamask();
    this.emit(DataManagerEvent.AccountChanged, this.myAddress);
    this.ethConnection.loadInjectedSigner();

    if (this.coreContract) {
      this.coreContract.removeAllListeners('Transfer');
    }

    this.coreContract = this.ethConnection.loadCoreContract(true);
    this.coreContract.on('Transfer', (_from: string, _to: string, rawId: EthersBN) => {
      this.reloadSinglePlanet(planetIdFromEthersBN(rawId));
    });
    this.gettersContract = this.ethConnection.loadGettersContract(true);

    const newChainId = await this.ethConnection.getChainId();
    if (newChainId === undefined) {
      throw new Error(ConnectionErrors.UNKNOWN_ERROR);
    }

    this.chainId = newChainId;

    window.ethereum.on('accountsChanged', (accounts: string[]) => {
      this.myAddress = accounts[0];
      this.emit(DataManagerEvent.AccountChanged, this.myAddress);
    });
    window.ethereum.on('chainChanged', () => window.location.reload());

    this.emit(DataManagerEvent.UpdateChainId);
    return this;
  }

  private onTxSubmit(submittedTx: SubmittedTx, saveToLocalStorage: boolean) {
    if (isClaimWinnerTx(submittedTx) || isClaimSpecialTx(submittedTx)) {
      const planet = this.planets.get(submittedTx.tokenId);
      if (planet) {
        planet.pendingClaim = submittedTx;
      }
    } else if (isTransferPlanetTx(submittedTx)) {
      const planet = this.planets.get(submittedTx.tokenId);
      if (planet) {
        planet.pendingTransfer = submittedTx;
      }
    }

    const { txHash } = submittedTx;

    this.ethConnection.waitForTransaction(txHash).then((receipt) => {
      this.onTxMined(submittedTx, receipt.status === 0);
    });

    if (saveToLocalStorage) {
      localStorage.setItem(`${this.coreContract.address}-${txHash}`, JSON.stringify(submittedTx));
      const txList = JSON.parse(
        localStorage.getItem(`${this.coreContract.address}-submittedTxs`) || '[]'
      ) as string[];
      if (!txList.includes(txHash)) {
        // onTxSubmit is called on constructor, so txHash might already be in this list
        txList.push(txHash);
        localStorage.setItem(`${this.coreContract.address}-submittedTxs`, JSON.stringify(txList));
      }
    }

    this.emit(DataManagerEvent.TxSubmitted, submittedTx);
  }

  private async onTxMined(submittedTx: SubmittedTx, reverted: boolean) {
    await sleep(isProd ? 25000 : 5000); // in prod: usually get 1-2 block confirmations in

    const { txHash } = submittedTx;

    localStorage.removeItem(`${this.coreContract.address}-${txHash}`);
    const txList = (
      JSON.parse(
        localStorage.getItem(`${this.coreContract.address}-submittedTxs`) || '[]'
      ) as string[]
    ).filter((it) => it !== txHash);
    localStorage.setItem(`${this.coreContract.address}-submittedTxs`, JSON.stringify(txList));

    if (isClaimWinnerTx(submittedTx) || isClaimSpecialTx(submittedTx)) {
      const planet = this.planets.get(submittedTx.tokenId);
      if (planet) {
        delete planet.pendingClaim;
      }
      if (!reverted) {
        this.reloadSinglePlanet(submittedTx.tokenId);
      }
    } else if (isTransferPlanetTx(submittedTx)) {
      const planet = this.planets.get(submittedTx.tokenId);
      if (planet) {
        delete planet.pendingTransfer;
      }
      if (!reverted) {
        this.reloadSinglePlanet(submittedTx.tokenId);
      }
    }

    if (reverted) {
      this.emit(DataManagerEvent.TxReverted, submittedTx, 'tx reverted');
    } else {
      this.emit(DataManagerEvent.TxConfirmed, submittedTx);
    }
  }

  public async reloadSinglePlanet(id: PlanetId): Promise<void> {
    this.checkChainId();

    const rawPlanet: RawValhallaPlanetWithMetadata = (
      await this.gettersContract.bulkGetPlanetsByIds([planetIdToDecStr(id)])
    )[0];
    const reloadedPlanet = this.setPlanetFromContractData(rawPlanet);

    this.emit(DataManagerEvent.ReloadedPlanet, reloadedPlanet);
  }

  public async initialLoadAllPlanets(): Promise<void> {
    this.checkChainId();

    const planetIds = await this.gettersContract.getAllTokenIds();
    const rawPlanets: RawValhallaPlanetWithMetadata[] =
      await this.gettersContract.bulkGetPlanetsByIds(planetIds);
    for (const rawPlanet of rawPlanets) {
      this.setPlanetFromContractData(rawPlanet);
    }

    this.emit(DataManagerEvent.LoadedInitialData);
  }

  private setPlanet(id: PlanetId, planet: Planet) {
    this.planets.set(id, planet);
    this.planetUpdated$.publish(id);
  }

  private setPlanetFromContractData(rawPlanet: RawValhallaPlanetWithMetadata): Planet {
    const planet = decodePlanetFromContract(rawPlanet);
    const oldPlanet = this.planets.get(planet.id) || {};
    const ret = Object.assign(oldPlanet, planet);
    this.setPlanet(planet.id, ret);
    return ret;
  }

  public getCoreAddress(): string {
    return this.coreContract.address;
  }

  public getMyAddress(): string | undefined {
    return this.myAddress;
  }

  public getPlanets(): Map<PlanetId, Planet> {
    return this.planets;
  }

  public async claimPlanet(id: PlanetId): Promise<string | undefined> {
    this.checkChainId();

    if (!this.myAddress) {
      throw new Error('must connect to metamask to claim planet');
    }

    const planet = this.planets.get(id);

    if (!planet) {
      throw new Error('cannot claim unknown planet');
    }
    if (planet.claimed) {
      throw new Error('planet already claimed');
    }
    if (planet.originalWinner.toLowerCase() !== this.myAddress.toLowerCase()) {
      throw new Error('not your planet to claim');
    }

    let txHash: string | undefined;

    if (planet.planetType === PlanetType.WINNER) {
      const txIntent: UnconfirmedClaimWinner = {
        actionId: getRandomActionId(),
        type: EthTxType.CLAIM_WINNER,
        tokenId: planet.id,
        roundId: planet.roundId,
        rank: planet.rank,
      };
      planet.pendingClaim = txIntent;

      try {
        this.emit(DataManagerEvent.TxInitialized, txIntent);
        const resp = await this.coreContract.claimWinner(planet.roundId);
        txHash = resp.hash;
        this.onTxSubmit({ ...txIntent, txHash }, true);
      } catch (e) {
        delete planet.pendingClaim;
        this.emit(DataManagerEvent.TxFailed, txIntent, e.message);
      }
    } else if (planet.planetType === PlanetType.SPECIAL) {
      const txIntent: UnconfirmedClaimSpecial = {
        actionId: getRandomActionId(),
        type: EthTxType.CLAIM_SPECIAL,
        tokenId: planet.id,
        roundId: planet.roundId,
        level: planet.level,
      };

      planet.pendingClaim = txIntent;

      try {
        this.emit(DataManagerEvent.TxInitialized, txIntent);
        const resp = await this.coreContract.claimSpecial(planet.roundId);
        txHash = resp.hash;
        this.onTxSubmit({ ...txIntent, txHash: resp.hash }, true);
      } catch (e) {
        delete planet.pendingClaim;
        this.emit(DataManagerEvent.TxFailed, txIntent, e.message);
      }
    }

    this.setPlanet(planet.id, planet);

    return txHash;
  }

  public async transferPlanet(id: PlanetId, receiverAddr: string): Promise<string | undefined> {
    this.checkChainId();

    if (!this.myAddress) {
      throw new Error('must connect to metamask to claim planet');
    }

    const planet = this.planets.get(id);

    if (!planet) {
      throw new Error('cannot claim unknown planet');
    }
    if (!planet.claimed) {
      throw new Error('planet not yet claimed');
    }
    if (planet.owner.toLowerCase() !== this.myAddress.toLowerCase()) {
      throw new Error("you don't own this planet");
    }

    let txHash: string | undefined = undefined;

    const txIntent: UnconfirmedTransferPlanet = {
      actionId: getRandomActionId(),
      type: EthTxType.TRANSFER_PLANET,
      tokenId: id,
      receiverAddr,
    };

    planet.pendingTransfer = txIntent;

    try {
      this.emit(DataManagerEvent.TxInitialized, txIntent);
      const resp = await this.coreContract.transferFrom(this.myAddress, receiverAddr, id);
      txHash = resp.hash;
      this.onTxSubmit({ ...txIntent, txHash }, true);
    } catch (e) {
      delete planet.pendingTransfer;
      this.emit(DataManagerEvent.TxFailed, txIntent, e.message);
    }

    return txHash;
  }
}
