import React from 'react';
import styled from 'styled-components';
import {
  StyledValhallaImage,
  ValhallaImageAnchor,
  ValhallaImagePositioner,
  ValhallaImageProps,
} from '../Pages/ValhallaGlobal/ValhallaImage';
import {
  BackgroundPlacement,
  BoundingBox,
  ValhallaZIndex,
} from '../Pages/ValhallaGlobal/ValhallaTypes';
import { useRes } from '../Utils/AppHooks';
import { IMAGE_URL } from '../Utils/consts';
import { bobAnim } from '../Utils/CSSAnims';

const WIDTH = 211;
const HEIGHT = 32;

const BannerText = styled.p<{ res: number }>`
  ${({ res }) => `
    text-align: center;
    position: absolute;
    top: 0; left: 0; right: 0; bottom: 0;
    padding-top: ${res * 4}px;
    z-index: ${ValhallaZIndex.Banners + 1};
    font-size: ${res * 12}px;
    overflow: visible;
    font-weight: 600;
    margin-top: ${res * 8}px;
`}
`;

export function Banner({ text, bottom }: { text: string; bottom: number }) {
  const res = useRes();

  const BannerProps: ValhallaImageProps = {
    anim: bobAnim(10, 1.5),
    res,
    url: `${IMAGE_URL}/banner.png`,
    imgWidth: WIDTH,
    imgHeight: HEIGHT,
    zIndex: ValhallaZIndex.Banners,
    boundingBox: BoundingBox.FitXFitY,
    placement: BackgroundPlacement.BottomCenterObject,
    position: { bottom, left: 0, right: 0 },
  };

  return (
    <ValhallaImageAnchor {...BannerProps}>
      <ValhallaImagePositioner {...BannerProps}>
        <StyledValhallaImage {...BannerProps}>
          <BannerText res={res}>{text}</BannerText>
        </StyledValhallaImage>
      </ValhallaImagePositioner>
    </ValhallaImageAnchor>
  );
}
