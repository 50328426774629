import React from 'react';
import { RoundImage } from '../ValhallaGlobal/ValhallaImage';
import {
  BackgroundPlacement,
  BoundingBox,
  R5FileName,
  ImageProps,
  RoundNumber,
} from '../ValhallaGlobal/ValhallaTypes';

export const R5ImageProps: Record<R5FileName, ImageProps> = {
  [R5FileName.Tier_1]: {
    imgWidth: 640,
    imgHeight: 628,
    zIndex: 0,
    boundingBox: BoundingBox.FillXFitY,
    placement: BackgroundPlacement.Background,
  },
  [R5FileName.Tier_2]: {
    imgWidth: 640,
    imgHeight: 791,
    zIndex: 0,
    boundingBox: BoundingBox.FillXFitY,
    placement: BackgroundPlacement.Background,
  },
  [R5FileName.Tier_3]: {
    imgWidth: 640,
    imgHeight: 1222,
    zIndex: 0,
    boundingBox: BoundingBox.FillXFitY,
    placement: BackgroundPlacement.Background,
  },
  [R5FileName.Tier_4]: {
    imgWidth: 640,
    imgHeight: 1434,
    zIndex: 0,
    boundingBox: BoundingBox.FillXFitY,
    placement: BackgroundPlacement.Background,
  },
  [R5FileName.Tier_5]: {
    imgWidth: 640,
    imgHeight: 2162,
    zIndex: 0,
    boundingBox: BoundingBox.FillXFitY,
    placement: BackgroundPlacement.Background,
  },
  [R5FileName.Tier_6]: {
    imgWidth: 640,
    imgHeight: 5002,
    zIndex: 0,
    boundingBox: BoundingBox.FillXFitY,
    placement: BackgroundPlacement.Background,
  },
  [R5FileName.Special_Mentions]: {
    imgWidth: 640,
    imgHeight: 2209,
    zIndex: 0,
    boundingBox: BoundingBox.FillXFitY,
    placement: BackgroundPlacement.Background,
  },
};

export function R5Images() {
  return (
    <>
      <RoundImage fileName={R5FileName.Tier_1} round={RoundNumber.Round5} />
      <RoundImage fileName={R5FileName.Special_Mentions} round={RoundNumber.Round5} />
      <RoundImage fileName={R5FileName.Tier_2} round={RoundNumber.Round5} />
      <RoundImage fileName={R5FileName.Tier_3} round={RoundNumber.Round5} />
      <RoundImage fileName={R5FileName.Tier_4} round={RoundNumber.Round5} />
      <RoundImage fileName={R5FileName.Tier_5} round={RoundNumber.Round5} />
      <RoundImage fileName={R5FileName.Tier_6} round={RoundNumber.Round5} />
    </>
  );
}
