import React from 'react';
import styled from 'styled-components';
import DataManager from '../../Backend/DataManager';
import { LOAD_DUR } from '../Components/LoadingPage';
import { PlanetIntegration } from '../Components/PlanetIntegration';
import { AnimationManager } from '../Utils/AnimationManager';
import {
  AccountContext,
  AnimManagerProvider,
  MobileContext,
  NetworkContext,
  ResContext,
} from '../Utils/AppHooks';
import { BACKGROUND_COLOR } from '../Utils/consts';
import { LoaderManager } from '../Utils/ImageLoader';
import { R1Page } from './Round1/R1Page';
import { R2Page } from './Round2/R2Page';
import { R3Page } from './Round3/R3Page';
import { R4Page } from './Round4/R4Page';
import { R5Page } from './Round5/R5Page';
import { RoundNumber } from './ValhallaGlobal/ValhallaTypes';

const ValhallaContainer = styled.div<{ loaded: boolean }>`
  width: 100vw;
  min-height: 100vh;
  position: relative;
  image-rendering: pixelated;
  overflow: hidden;
  padding: 0;
  margin: 0;
  background: ${BACKGROUND_COLOR};
  transition: filter ${LOAD_DUR}s;
  filter: ${({ loaded }) => (loaded ? 'none' : 'brightness(0)')};
  ${({ loaded }) => !loaded && `pointer-events: none;`}
`;

export function ValhallaPage({
  loaded,
  setLoaded,
  address,
  isRightNetwork,
  hasScrolled,
  res,
  mobile,
  animManager,
  loaderManager,
  dataManager,
  round,
}: {
  loaded: boolean;
  setLoaded: (loaded: boolean) => void;
  address: string | undefined;
  isRightNetwork: boolean;
  hasScrolled: boolean;
  res: number;
  mobile: boolean;
  animManager: AnimationManager | undefined;
  loaderManager: LoaderManager;
  dataManager: DataManager;
  round: RoundNumber;
}) {
  let roundPageContent;

  switch (round) {
    case RoundNumber.Round1:
      roundPageContent = (
        <R1Page
          loaded={loaded}
          setLoaded={setLoaded}
          loaderManager={loaderManager}
          dataManager={dataManager}
        />
      );
      break;
    case RoundNumber.Round2:
      roundPageContent = (
        <R2Page
          loaded={loaded}
          setLoaded={setLoaded}
          loaderManager={loaderManager}
          dataManager={dataManager}
        />
      );
      break;
    case RoundNumber.Round3:
      roundPageContent = (
        <R3Page
          loaded={loaded}
          setLoaded={setLoaded}
          loaderManager={loaderManager}
          dataManager={dataManager}
        />
      );
      break;
    case RoundNumber.Round4:
      roundPageContent = (
        <R4Page
          loaded={loaded}
          setLoaded={setLoaded}
          loaderManager={loaderManager}
          dataManager={dataManager}
        />
      );
      break;
    case RoundNumber.Round5:
      roundPageContent = (
        <R5Page
          loaded={loaded}
          setLoaded={setLoaded}
          loaderManager={loaderManager}
          dataManager={dataManager}
        />
      );
      break;
    default:
      throw new Error('not a valid round');
  }
  return (
    <>
      {animManager && (
        <AnimManagerProvider value={animManager}>
          <AccountContext.Provider value={address}>
            <ResContext.Provider value={res}>
              <MobileContext.Provider value={mobile}>
                <NetworkContext.Provider value={isRightNetwork}>
                  <ValhallaContainer loaded={loaded && hasScrolled}>
                    <PlanetIntegration />
                    {roundPageContent}
                  </ValhallaContainer>
                </NetworkContext.Provider>
              </MobileContext.Provider>
            </ResContext.Provider>
          </AccountContext.Provider>
        </AnimManagerProvider>
      )}
    </>
  );
}
