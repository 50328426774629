import { PlanetType } from 'common-types';
import React, { useEffect, useMemo } from 'react';
import DataManager from '../../../Backend/DataManager';
import { Banner } from '../../Components/Banner';
import { Header } from '../../Components/Header';
import { planetUrl, FileType } from '../../Planet/PlanetUtils';
import { LoaderManager } from '../../Utils/ImageLoader';
import { getUrl } from '../../Utils/Utils';
import { R2FileName, R3FileName, RoundNumber } from '../ValhallaGlobal/ValhallaTypes';
import { R3Images } from './R3ImageProps';
import { R3Planets } from './R3Planets';

const R3_BG: string[] = [
  R3FileName.Tier_3,
  R3FileName.Tier_3,
  R3FileName.Tier_4,
  R3FileName.Tier_5,
  R3FileName.Tier_6,
].map((str) => getUrl(str, RoundNumber.Round3));

const R3_ALL: string[] = Object.values(R3FileName).map((str) => getUrl(str, RoundNumber.Round3));

export function R3Page({
  setLoaded,
  loaded,
  loaderManager,
  dataManager,
}: {
  setLoaded: (value: boolean) => void;
  loaderManager: LoaderManager;
  dataManager: DataManager;
  loaded: boolean;
}) {
  /* create loading lists */
  const r3planets = useMemo(
    () =>
      dataManager
        ? Array.from(dataManager.getPlanets().values())
            .filter((p) => p.planetType === PlanetType.WINNER)
            .map((p) => planetUrl(p, FileType.Png))
        : undefined,
    [dataManager]
  );

  useEffect(() => {
    if (!loaderManager) return;
    if (!r3planets) return;
    loaderManager.loadMany(
      [
        { urls: R3_BG, loaderId: 'background' },
        { urls: R3_ALL, loaderId: 'foreground' },
        // { urls: r2Planets, loaderId: 'planets' },
      ],
      () => setLoaded(true)
    );
  }, [loaderManager, r3planets]);

  if (!loaded) {
    return <></>;
  }

  return (
    <>
      <R3Images />
      <R3Planets />

      <Header round={RoundNumber.Round3} bottom={100} />

      <Banner text={'Tier 1'} bottom={13994} />
      <Banner text={'Special Prizes'} bottom={12060} />
      <Banner text={'Tier 2'} bottom={10060} />
      <Banner text={'Tier 3'} bottom={7725} />
      <Banner text={'Tier 4'} bottom={5295} />
      <Banner text={'Tier 5'} bottom={3737} />
      <Banner text={'Tier 6'} bottom={1550} />
      <Banner text={'Scroll Up'} bottom={400} />
    </>
  );
}
