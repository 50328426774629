import * as THREE from 'three';

export enum PlanetUniform {
  landColor = 'u_colorL',
  waterColor = 'u_colorW',
  sandColor = 'u_colorS',
  sandColor2 = 'u_colorS2',
  iceColor = 'u_colorI',
}

export type PlanetUniforms = {
  [PlanetUniform.landColor]: {
    type: 'v3';
    value: THREE.Vector3;
  };
  [PlanetUniform.waterColor]: {
    type: 'v3';
    value: THREE.Vector3;
  };
  [PlanetUniform.sandColor]: {
    type: 'v3';
    value: THREE.Vector3;
  };
  [PlanetUniform.sandColor2]: {
    type: 'v3';
    value: THREE.Vector3;
  };
  [PlanetUniform.iceColor]: {
    type: 'v3';
    value: THREE.Vector3;
  };
};

const planetVert = `
  uniform vec3 ${PlanetUniform.landColor};
  uniform vec3 ${PlanetUniform.waterColor};
  uniform vec3 ${PlanetUniform.sandColor};
  uniform vec3 ${PlanetUniform.sandColor2};
  uniform vec3 ${PlanetUniform.iceColor};

  varying vec3 v_color;

  void main()	{
    v_color = color;
    // gl_Position = vec4( position, 1.0 );

    gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
  }
`;

const planetFrag = `
  uniform vec3 ${PlanetUniform.landColor};
  uniform vec3 ${PlanetUniform.waterColor};
  uniform vec3 ${PlanetUniform.sandColor};
  uniform vec3 ${PlanetUniform.sandColor2};
  uniform vec3 ${PlanetUniform.iceColor};

  varying vec3 v_color;

  void main()	{
    float n = v_color.r;

    vec4 c1 = vec4(${PlanetUniform.landColor} / vec3(255.), 1.); // green
    vec4 c2 = vec4(${PlanetUniform.waterColor} / vec3(255.), 1.); // blue
    vec4 c3 = vec4(${PlanetUniform.sandColor} / vec3(255.), 1.); // sand

    vec4 c4 = vec4(${PlanetUniform.iceColor} / vec3(255.), 1.); // ice
    vec4 c5 = vec4(${PlanetUniform.sandColor2} / vec3(255.), 1.); // sand 2

    vec4 landColor = n > 0.28 ? c4 : c1;
    vec4 sandColor = n > 0.05 ? c3 : c5;
    vec4 waterColor = c2;

    gl_FragColor = n < 0.01 ? waterColor : n < 0.09 ? sandColor: landColor;

    // gl_FragColor = vec4(v_color.rgb, 1.);
  }
`;

export function getPlanetMaterial(): { uniforms: PlanetUniforms; material: THREE.ShaderMaterial } {
  const uniforms: PlanetUniforms = {
    [PlanetUniform.landColor]: {
      type: 'v3',
      value: new THREE.Vector3(162, 204, 140),
    },
    [PlanetUniform.waterColor]: {
      type: 'v3',
      value: new THREE.Vector3(81, 108, 131),
    },
    [PlanetUniform.sandColor]: {
      type: 'v3',
      value: new THREE.Vector3(255, 232, 177),
    },
    [PlanetUniform.sandColor2]: {
      type: 'v3',
      value: new THREE.Vector3(190, 210, 225),
    },
    [PlanetUniform.iceColor]: {
      type: 'v3',
      value: new THREE.Vector3(240, 250, 255),
    },
  };

  const material = new THREE.ShaderMaterial({
    vertexColors: true,
    uniforms,
    vertexShader: planetVert,
    fragmentShader: planetFrag,
  });

  return { uniforms, material };
}
