import React from 'react';
import styled from 'styled-components';
import DataManager from '../../Backend/DataManager';
import {
  isClaimWinnerTx,
  isSubmitted,
  isClaimSpecialTx,
  isTransferPlanetTx,
} from '../../_types/transactions';
import { useTxIntents } from '../Utils/AppHooks';
import { TEXT_COLOR } from '../Utils/consts';

const StyledTxViewer = styled.p`
  position: fixed;
  top: 0;
  right: 0;

  text-align: left;

  color: ${TEXT_COLOR};
  z-index: 9999;
  margin: 0;
  padding: 0.5em;
  background: rgba(0, 0, 0, 0.3);
  width: fit-content;
  min-width: 10em;
`;

const Error = styled.p`
  color: red;
`;

function TxLink({ hash }: { hash: string }) {
  return <a href={'https://etherscan.io/tx/' + hash}>etherscan link</a>;
}

export function TxViewer({ dataManager }: { dataManager: DataManager }) {
  const { intents, error } = useTxIntents(dataManager);

  return (
    <>
      {(intents.length > 0 || error) && (
        <StyledTxViewer>
          {error && <Error>{error}</Error>}
          {intents.map((txIntent) => {
            if (isClaimWinnerTx(txIntent)) {
              return (
                <div key={txIntent.actionId}>
                  <div>=======PENDING TX=======</div>
                  <div>{txIntent.type}</div>
                  <div>{`round: ${txIntent.roundId}`}</div>
                  <div>{`rank: ${txIntent.rank}`}</div>
                  {isSubmitted(txIntent) ? (
                    <div>
                      <TxLink hash={txIntent.txHash} />
                    </div>
                  ) : null}
                </div>
              );
            } else if (isClaimSpecialTx(txIntent)) {
              return (
                <div key={txIntent.actionId}>
                  <div>=======PENDING TX=======</div>
                  <div>{txIntent.type}</div>
                  <div>{`round: ${txIntent.roundId}`}</div>
                  <div>{`level: ${txIntent.level}`}</div>
                  {isSubmitted(txIntent) ? (
                    <div>
                      <TxLink hash={txIntent.txHash} />
                    </div>
                  ) : null}
                </div>
              );
            } else if (isTransferPlanetTx(txIntent)) {
              return (
                <div key={txIntent.actionId}>
                  <div>=======PENDING TX=======</div>
                  <div>{txIntent.type}</div>
                  <div>{`token id: ${txIntent.tokenId}`}</div>
                  <div>{`send to: ${txIntent.receiverAddr}`}</div>
                  {isSubmitted(txIntent) ? (
                    <div>
                      <TxLink hash={txIntent.txHash} />
                    </div>
                  ) : null}
                </div>
              );
            }
            return null;
          })}
        </StyledTxViewer>
      )}
    </>
  );
}
