import React from 'react';
import styled from 'styled-components';
import { TEXT_COLOR } from '../Utils/consts';

const StyledButton = styled.div<{ disabled?: boolean }>`
  width: 100%;
  box-sizing: border-box;
  font-weight: 300;
  font-size: 12pt;

  margin: 0.5em;
  margin-bottom: 0;

  text-align: center;
  border: 1px solid ${TEXT_COLOR};
  border-radius: 5px;
  padding: 0.5em;

  transition: background 0.2s, color 0.2s;

  ${({ disabled }) =>
    !disabled &&
    `
  &:hover {
    font-weight: 400;
    cursor: pointer;
    color: rgb(30, 60, 70);
    background: ${TEXT_COLOR};
  }
  &:active {
    filter: brightness(0.8);
  }
`}
`;

export function Button({
  children,
  disabled,
  click,
}: {
  click?: () => void;
  children: React.ReactNode;
  disabled?: boolean;
}) {
  return (
    <StyledButton onClick={() => !disabled && click && click()} disabled={disabled}>
      {children}
    </StyledButton>
  );
}
