import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { PlanetId } from '../../../../../packages/types/dist';
import { planetIntegration$ } from '../../Components/PlanetIntegration';
import { FileType, planetUrl } from '../../Planet/PlanetUtils';
import {
  useAccount,
  useCanClaim,
  useDataManager,
  useIsCorrectNetwork,
  useLoaderManager,
  useMobile,
  usePlanetWithId,
  useRes,
} from '../../Utils/AppHooks';
import { TEXT_COLOR } from '../../Utils/consts';
import { getTextSize } from '../../Utils/Utils';
import { PlanetLevel, ValhallaZIndex } from './ValhallaTypes';

type ValhallaPlanetProps = {
  bottom: number;
  left: number;
  res: number;
  radius: number;
};

// centered 640 x H rectangle to hold planets in a column
const ValhallaPlanetAnchor = styled.div<ValhallaPlanetProps>`
  pointer-events: none;
  z-index: ${ValhallaZIndex.Planets};
  ${({ res }) => `max-width: ${res * 640}px`};
  width: 100%;
  margin: auto;
  height: fit-content;
  min-height: 10px;

  position: absolute;
  ${({ bottom, res }) => `bottom: ${res * bottom}px`};
  left: 0;
  right: 0;

  overflow: visible;
`;

const fade = keyframes`
  from {
    background: rgba(100, 20, 20, 0.5);
  }
  to {
    background: rgba(0, 0, 0, 0);
  }
`;

const fadeAnim = css`
  animation: 2s ${fade} ease-in-out infinite alternate;
`;

const ValhallaPlanetWrapper = styled.div<{
  left: number;
  res: number;
  centered?: boolean;
  canClaim: boolean;
  claimedByMe: boolean;
  textColor?: string;
}>`
  pointer-events: all;

  ${({ canClaim, claimedByMe }) => canClaim && !claimedByMe && fadeAnim}
  ${({ claimedByMe }) => claimedByMe && `border: 1px dashed white;`}
  
  position: relative;
  width: fit-content;
  height: fit-content;

  ${({ centered, left }) => (centered ? 'margin: 0 auto' : `margin-left: ${(left / 640) * 100}%`)};
  display: flex;
  flex-direction: column;
  align-items: center;

  transition: background 0.2s;
  padding: 8px;
  border-radius: 8px;
  border: #777;

  overflow: visible;
  background: rgba(0, 0, 0, 0.5);

  &:hover {
    cursor: pointer;
    animation: none;
    background: rgba(0, 0, 0, 0.8);
  }

  &:active {
    background: rgba(0.5, 0.6, 0.7, 0.7);
  }
`;

const StyledValhallaPlanetCircle = styled.div<{ res: number; radius: number }>`
  ${({ radius, res }) => `
    min-height: ${radius * res}px;
    min-width: ${radius * res}px;
    height: ${(radius / 640) * 100}%;
    width: ${(radius / 640) * 100}%;
  `}
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
`;

const PlanetCircle = styled.div<{ filler: string; radius: number; res: number }>`
  ${({ filler }) => `background: ${filler}`};
  ${({ radius, res }) => `
    min-height: ${0.75 * radius * res}px;
    min-width: ${0.75 * radius * res}px;
    width: 75%;
    height: 75%;
    border-radius: 50%;
  `}
`;

function ValhallaPlanetCircle({
  filler,
  res,
  radius,
}: {
  filler: string;
  res: number;
  radius: number;
}) {
  return (
    <StyledValhallaPlanetCircle res={res} radius={radius}>
      <PlanetCircle filler={filler} res={res} radius={radius} />
    </StyledValhallaPlanetCircle>
  );
}

const ValhallaPlanetImage = styled.div<{ url: string; res: number; radius: number }>`
  ${({ url }) => `background-image: url('${url}');`}
  background-size: contain;
  ${({ radius, res }) => `
    min-height: ${radius * res}px;
    min-width: ${radius * res}px;
    height: ${(radius / 640) * 100}%;
    width: ${(radius / 640) * 100}%;
  `}
`;

const Label = styled.span<{ res: number; textColor?: string }>`
  color: ${({ res, textColor }) => css`
    font-weight: 400;
    font-size: ${getTextSize(res)}px;
    color: ${textColor !== undefined ? textColor : TEXT_COLOR};
    padding: 2px 8px;
    border-radius: 3px;
  `};
`;

const PlanetRadii: Record<PlanetLevel, number> = {
  [PlanetLevel.Level2]: 30,
  [PlanetLevel.Level3]: 52,
  [PlanetLevel.Level4]: 75,
  [PlanetLevel.Level5]: 107,
  [PlanetLevel.Level6]: 150,
  [PlanetLevel.Level7]: 220,
};

const FillerColors: Record<PlanetLevel, string> = {
  [PlanetLevel.Level2]: '#8be7e2',
  [PlanetLevel.Level3]: '#de8228',
  [PlanetLevel.Level4]: '#cc374c',
  [PlanetLevel.Level5]: '#9b4f25',
  [PlanetLevel.Level6]: '#a0a6a7',
  [PlanetLevel.Level7]: '#e4d134',
};

type PlanetProps = {
  textColor?: string;
  bottom: number;
  left: number;
  level: PlanetLevel;
  centered?: boolean;
  rank?: number;
  originalWinner?: string; // if provided, overrides any automated id calculations
};

export function ValhallaPagePlanet({
  bottom,
  left,
  level,
  centered,
  round,
  rank,
  originalWinner,
  textColor,
}: PlanetProps & { round: number }) {
  // sync the planet data to this element
  const dataManager = useDataManager();

  let id =
    dataManager.getSpecialPrizePlanetByWinner(round, originalWinner)?.id ||
    dataManager.getIdWithRoundAndRank(round, rank || 0);

  if (originalWinner !== undefined && rank !== undefined) {
    id = dataManager.getPlanetByRoundAndWinner(round, originalWinner)?.id;
  }

  const planet = usePlanetWithId(dataManager, id);
  const url = useMemo(() => (planet ? planetUrl(planet, FileType.Png) : undefined), [planet]);

  // resolution stuff
  const res = useRes();
  const isMobile = useMobile();
  const radius = (isMobile ? 0.7 : 1) * PlanetRadii[level];
  const filler = FillerColors[level];
  const props: ValhallaPlanetProps = { bottom, left, res, radius };

  // handler to open the integration pane
  const open = useCallback(() => planetIntegration$.publish(id), [id]);

  // check if you can claim it
  const account = useAccount();
  const isCorrect = useIsCorrectNetwork();
  const canClaim = useCanClaim(account, planet) && isCorrect;

  const claimedByMe = !!(canClaim && planet?.claimed);

  return (
    <ValhallaPlanetAnchor {...props}>
      <ValhallaPlanetWrapper
        {...props}
        textColor={textColor}
        centered={centered}
        onClick={open}
        canClaim={canClaim}
        claimedByMe={claimedByMe}
      >
        <Unclaimed>{!planet?.claimed && 'Unclaimed'}</Unclaimed>

        <br />
        {planet && url ? (
          <ValhallaPlanetImage {...props} url={url} />
        ) : (
          <ValhallaPlanetCircle {...props} filler={filler} />
        )}
        <Label res={res} textColor={textColor}>
          {planet?.rank !== undefined && planet?.rank !== 0 && <> #{planet?.rank} </>}
          {planet?.originalWinnerTwitter
            ? `@${planet?.originalWinnerTwitter}`
            : planet?.originalWinner?.substring(0, 6)}
        </Label>
      </ValhallaPlanetWrapper>
    </ValhallaPlanetAnchor>
  );
}

export const R1Planet = (props: PlanetProps) => <ValhallaPagePlanet {...props} round={1} />;
export const R2Planet = (props: PlanetProps) => <ValhallaPagePlanet {...props} round={2} />;
export const R3Planet = (props: PlanetProps) => <ValhallaPagePlanet {...props} round={3} />;
export const R4Planet = (props: PlanetProps) => <ValhallaPagePlanet {...props} round={4} />;
export const R5Planet = (props: PlanetProps) => <ValhallaPagePlanet {...props} round={5} />;

const Unclaimed = styled.div`
  color: white;
`;
