import React, { useCallback, useEffect, useState } from 'react';
import DataManager, { DataManagerEvent } from '../../Backend/DataManager';
import { EthConnectionType } from '../../_types/transactions';
import { LoadingPage } from '../Components/LoadingPage';
import { TxViewer } from '../Components/TxViewer';
import { AnimationManager } from '../Utils/AnimationManager';
import { DataManagerProvider, LoaderManagerProvider } from '../Utils/AppHooks';
import { LoaderDiv, LoaderManager } from '../Utils/ImageLoader';
import { RoundNumber } from './ValhallaGlobal/ValhallaTypes';
import { ValhallaPage } from './ValhallaPage';

const getMobile = () => window.innerWidth < 640;
const getRes = () => (getMobile() ? 1 : 2);

export function LandingPage({ round }: { round: RoundNumber }) {
  const [loaded, setLoaded] = useState(false);
  const [loadingError, setLoadingError] = useState<string | undefined>();
  const [dataManager, setDataManager] = useState<DataManager | undefined>();
  const [loaderDiv, setLoaderDiv] = useState<HTMLDivElement | null>(null);
  const [loaderManager, setLoaderManager] = useState<LoaderManager | undefined>();

  useEffect(() => {
    if (loaderDiv) setLoaderManager(new LoaderManager(loaderDiv));
  }, [loaderDiv]);

  useEffect(() => {
    DataManager.create(EthConnectionType.DEFAULT_RPC)
      .then((newDataManager: DataManager) => {
        console.log(newDataManager);
        newDataManager.loadSubmittedTxsFromStorage();
        setDataManager(newDataManager);
      })
      .catch(setLoadingError);
  }, []);

  /* anatomy:
    <LoaderDiv /> is used for image loading
    <LoadingPage /> starts opaque and fades out
    <ValhallaLandingPage /> starts black and fades in
  */

  return (
    <>
      <LoaderDiv ref={(el) => setLoaderDiv(el)}></LoaderDiv>
      {loaderManager && (
        <LoaderManagerProvider value={loaderManager}>
          <LoadingPage
            dataManager={dataManager}
            loaded={loaded}
            round={round}
            loadingError={loadingError}
          />
          {dataManager && !loadingError && (
            <ValhallaLandingPage
              loaded={loaded}
              setLoaded={setLoaded}
              dataManager={dataManager}
              loadedManager={loaderManager}
              round={round}
            />
          )}
        </LoaderManagerProvider>
      )}
    </>
  );
}

export function ValhallaLandingPage({
  dataManager,
  loaded,
  setLoaded,
  loadedManager,
  round,
}: {
  dataManager: DataManager;
  loaded: boolean;
  setLoaded: (loaded: boolean) => void;
  loadedManager: LoaderManager;
  round: RoundNumber;
}) {
  const [animManager, setAnimManager] = useState<AnimationManager | undefined>();

  useEffect(() => {
    const manager = new AnimationManager();
    setAnimManager(manager);
    return () => manager.destroy();
  }, []);

  /* get and manage resolution */
  const [res, setRes] = useState<number>(getRes);
  const [mobile, setMobile] = useState<boolean>(getMobile);

  const update = useCallback(() => {
    setRes(getRes);
    setMobile(getMobile);
  }, [setRes, setMobile]);

  useEffect(() => {
    window.addEventListener('resize', update);
    return () => {
      window.removeEventListener('resize', update);
    };
  }, [update]);

  /* scroll once loaded */
  const scrollUser = useCallback(() => {
    console.log('scrolling user to bottom!');
    window.scrollTo(0, document.body.scrollHeight);
  }, []);

  const [hasScrolled, setHasScrolled] = useState<boolean>(false);
  useEffect(() => {
    if (loaded) {
      scrollUser();
      setHasScrolled(true);
    }
  }, [loaded, scrollUser]);

  /* sync account */
  const [address, setAddress] = useState<string | undefined>(dataManager.getMyAddress());
  useEffect(() => {
    setAddress(dataManager.getMyAddress());
    const update = (addr: string | undefined) => setAddress(addr);
    dataManager.on(DataManagerEvent.AccountChanged, update);
    return () => void dataManager.removeListener(DataManagerEvent.AccountChanged, update);
  }, [dataManager]);

  /* sync network id */
  const [isRightNetwork, setIsRightNetwork] = useState<boolean>(!!dataManager.isCorrectNetwork());
  useEffect(() => {
    const update = () => setIsRightNetwork(!!dataManager.isCorrectNetwork());
    dataManager.on(DataManagerEvent.UpdateChainId, update);
    return () => void dataManager.removeListener(DataManagerEvent.UpdateChainId, update);
  }, [dataManager]);

  return (
    <>
      {dataManager && (
        <>
          <TxViewer dataManager={dataManager} />
          <DataManagerProvider value={dataManager}>
            <ValhallaPage
              loaded={loaded}
              setLoaded={setLoaded}
              address={address}
              isRightNetwork={isRightNetwork}
              hasScrolled={hasScrolled}
              res={res}
              mobile={mobile}
              animManager={animManager}
              dataManager={dataManager}
              loaderManager={loadedManager}
              round={round}
            />
          </DataManagerProvider>
        </>
      )}
    </>
  );
}
