import styled from 'styled-components';
import React from 'react';

const StyledPlanetCanvas = styled.div<{ width: number; height: number }>`
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;

  overflow: hidden;

  display: inline-block;
  margin-right: 32px;

  canvas {
    image-rendering: pixelated;
    width: 100%;
    height: 100%;
  }
`;

export function PlanetCanvas({
  size,
  setter,
}: {
  size: number;
  setter: (el: HTMLCanvasElement | null) => void;
}) {
  return (
    <>
      <StyledPlanetCanvas width={size} height={size}>
        <canvas width={size} height={size} ref={setter}></canvas>
      </StyledPlanetCanvas>
    </>
  );
}
