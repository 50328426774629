import React from 'react';
import { R5Planet } from '../ValhallaGlobal/ValhallaPagePlanet';
import { PlanetLevel } from '../ValhallaGlobal/ValhallaTypes';

export function R5Planets() {
  return (
    <div>
      <>
        <>
          <R5Planet rank={63} bottom={1300} left={300} level={PlanetLevel.Level2} />
          <R5Planet rank={62} bottom={1300} left={100} level={PlanetLevel.Level2} />
          <R5Planet rank={61} bottom={1440} left={250} level={PlanetLevel.Level2} />

          <R5Planet rank={60} bottom={1580} left={350} level={PlanetLevel.Level2} />
          <R5Planet rank={59} bottom={1580} left={525} level={PlanetLevel.Level2} />
          <R5Planet rank={58} bottom={1720} left={100} level={PlanetLevel.Level2} />
          <R5Planet rank={57} bottom={1720} left={400} level={PlanetLevel.Level2} />

          <R5Planet rank={56} bottom={1860} left={450} level={PlanetLevel.Level2} />
          <R5Planet rank={55} bottom={1860} left={280} level={PlanetLevel.Level2} />
          <R5Planet rank={54} bottom={2000} left={100} level={PlanetLevel.Level2} />
          <R5Planet rank={53} bottom={2000} left={510} level={PlanetLevel.Level2} />

          <R5Planet rank={52} bottom={2139} left={350} level={PlanetLevel.Level2} />
          <R5Planet rank={51} bottom={2279} left={220} level={PlanetLevel.Level2} />
          <R5Planet rank={50} bottom={2349} left={480} level={PlanetLevel.Level2} />
          <R5Planet rank={49} bottom={2349} left={100} level={PlanetLevel.Level2} />

          <R5Planet rank={48} bottom={2419} left={270} level={PlanetLevel.Level2} />
          <R5Planet rank={47} bottom={2559} left={80} level={PlanetLevel.Level2} />
          <R5Planet rank={46} bottom={2630} left={430} level={PlanetLevel.Level2} />
          <R5Planet rank={45} bottom={2700} left={200} level={PlanetLevel.Level2} />

          <R5Planet rank={44} bottom={2740} left={350} level={PlanetLevel.Level2} />
          <R5Planet rank={43} bottom={2840} left={120} level={PlanetLevel.Level2} />
          <R5Planet rank={42} bottom={2910} left={300} level={PlanetLevel.Level2} />
          <R5Planet rank={41} bottom={2980} left={520} level={PlanetLevel.Level2} />

          <R5Planet rank={40} bottom={2980} left={150} level={PlanetLevel.Level2} />
          <R5Planet rank={39} bottom={3120} left={300} level={PlanetLevel.Level2} />
          <R5Planet rank={38} bottom={3190} left={100} level={PlanetLevel.Level2} />
          <R5Planet rank={37} bottom={3260} left={420} level={PlanetLevel.Level2} />

          <R5Planet rank={36} bottom={3400} left={265} level={PlanetLevel.Level2} />
          <R5Planet rank={35} bottom={3400} left={510} level={PlanetLevel.Level2} />
          <R5Planet rank={34} bottom={3540} left={100} level={PlanetLevel.Level2} />
          <R5Planet rank={33} bottom={3540} left={350} level={PlanetLevel.Level2} />
        </>

        <>
          <R5Planet rank={32} bottom={3980} left={200} level={PlanetLevel.Level3} />
          <R5Planet rank={31} bottom={4120} left={100} level={PlanetLevel.Level3} />
          <R5Planet rank={30} bottom={4260} left={210} level={PlanetLevel.Level3} />
          <R5Planet rank={29} bottom={4400} left={450} level={PlanetLevel.Level3} />

          <R5Planet rank={28} bottom={4540} left={140} level={PlanetLevel.Level3} />
          <R5Planet rank={27} bottom={4680} left={340} level={PlanetLevel.Level3} />
          <R5Planet rank={26} bottom={4820} left={170} level={PlanetLevel.Level3} />
          <R5Planet rank={25} bottom={4960} left={490} level={PlanetLevel.Level3} />

          <R5Planet rank={24} bottom={5100} left={310} level={PlanetLevel.Level3} />
          <R5Planet rank={23} bottom={5240} left={100} level={PlanetLevel.Level3} />
          <R5Planet rank={22} bottom={5380} left={250} level={PlanetLevel.Level3} />
          <R5Planet rank={21} bottom={5520} left={360} level={PlanetLevel.Level3} />

          <R5Planet rank={20} bottom={5660} left={170} level={PlanetLevel.Level3} />
          <R5Planet rank={19} bottom={5800} left={470} level={PlanetLevel.Level3} />
          <R5Planet rank={18} bottom={5940} left={380} level={PlanetLevel.Level3} />
          <R5Planet rank={17} bottom={5980} left={200} level={PlanetLevel.Level3} />
        </>
        <>
          <R5Planet rank={16} bottom={6520} left={110} level={PlanetLevel.Level4} />
          <R5Planet rank={15} bottom={6545} left={280} level={PlanetLevel.Level4} />
          <R5Planet rank={14} bottom={6670} left={350} level={PlanetLevel.Level4} />
          <R5Planet rank={13} bottom={6795} left={200} level={PlanetLevel.Level4} />

          <R5Planet rank={12} bottom={6920} left={380} level={PlanetLevel.Level4} />
          <R5Planet rank={11} bottom={7045} left={120} level={PlanetLevel.Level4} />
          <R5Planet rank={10} bottom={7170} left={460} level={PlanetLevel.Level4} />
          <R5Planet rank={9} bottom={7295} left={190} level={PlanetLevel.Level4} />
        </>
        <>
          <R5Planet rank={8} bottom={7600} left={400} level={PlanetLevel.Level5} />
          <R5Planet rank={7} bottom={7800} left={100} level={PlanetLevel.Level5} />
          <R5Planet rank={6} bottom={8000} left={210} level={PlanetLevel.Level5} />
          <R5Planet rank={5} bottom={8200} left={480} level={PlanetLevel.Level5} />
        </>

        {/* Lv 6-7 */}
        <>
          <R5Planet rank={4} bottom={9300} left={100} level={PlanetLevel.Level6} />
          <R5Planet rank={3} bottom={9300} left={400} level={PlanetLevel.Level6} />
        </>

        {/* special prize winners */}
        <>
          <R5Planet
            originalWinner='0x8dc13e92246b9e9a494173f28b07262b30cc545c'
            bottom={10900}
            left={100 + 210 + 50}
            level={PlanetLevel.Level2}
          />
          <R5Planet
            originalWinner='0x7a67928bB379974768668D50ab991Eefb5F08c0e'
            bottom={11000}
            left={100 + 250}
            level={PlanetLevel.Level2}
          />
          <R5Planet
            originalWinner='0xaa23208770da9ae738a7a5069bcdfdc06e487821'
            bottom={11100}
            left={100 + 200}
            level={PlanetLevel.Level2}
          />
          <R5Planet
            originalWinner='0x4CEaa0A16d0831d7d46cb72006F959a87f618A47'
            bottom={11200}
            left={100 + 180}
            level={PlanetLevel.Level2}
          />
          <R5Planet
            originalWinner='0xf0dBDe527c63B63178576578777f0b7E24755ABb'
            bottom={11300}
            left={100 + 190}
            level={PlanetLevel.Level2}
          />
          <R5Planet
            originalWinner='0x10A8877F1818cDc49F27Cce438275c991097DbFA'
            bottom={11400}
            left={100 + 80}
            level={PlanetLevel.Level2}
          />
          <R5Planet
            originalWinner='0x1315B9510Cd7f75A63BB59bA7d9D1FAd083d0667'
            bottom={11500}
            left={100 + 300}
            level={PlanetLevel.Level2}
          />
          <R5Planet
            originalWinner='0x5A56E9aB6303794224fF8AB1c14bF2A5c26505bc'
            bottom={11600}
            left={100 + 250}
            level={PlanetLevel.Level2}
          />
          <R5Planet
            originalWinner='0x351a04ab0acf172ebd01e829fdcbc50938c581f7'
            bottom={11700}
            left={100 + 220}
            level={PlanetLevel.Level2}
          />
          <R5Planet
            originalWinner='0xe8d3dd97cd3a33b7b8f94e3195e98d3912ac50e9'
            bottom={11800}
            left={100 + 170}
            level={PlanetLevel.Level2}
          />
          <R5Planet
            originalWinner='0xa6a6299ae4B0C91Ce9b7D24E96CBd48dC29cac7f'
            bottom={11900}
            left={100 + 100}
            level={PlanetLevel.Level2}
          />

          <R5Planet
            originalWinner='0xe20Ce9B9Fe3E91B92eBe719909c5623675722a77'
            bottom={12050}
            left={100 + 125}
            level={PlanetLevel.Level3}
          />
          <R5Planet
            originalWinner='0xa765ae8b428617728c0a6fc26674e6fa157ce7c5'
            bottom={12200}
            left={100}
            level={PlanetLevel.Level3}
          />
          <R5Planet
            originalWinner='0xCF1a82c49364D4523E23e24a1C342466613B1e03'
            bottom={12100}
            left={100 + 240}
            level={PlanetLevel.Level3}
          />
          <R5Planet
            originalWinner='0x5Ea5879E3B887788616752E3a229DA32ef580661'
            bottom={12300}
            left={100 + 100}
            level={PlanetLevel.Level3}
          />
          <R5Planet
            originalWinner='0x00000000e29FD822365856bDe355F5713F917D8f'
            bottom={12300 + 150}
            left={150}
            level={PlanetLevel.Level4}
          />
          <R5Planet
            originalWinner='0xFC857512DC886502942e835569b81A2d0Ab711f9'
            bottom={12250 + 300}
            left={300}
            level={PlanetLevel.Level4}
          />
          <R5Planet
            originalWinner='0xa36ab50044b51c6f6f00a5da1ffa7727876d3b7d'
            bottom={12250 + 450}
            left={100 + 190}
            level={PlanetLevel.Level4}
          />
        </>

        <>
          <R5Planet
            rank={1}
            originalWinner='0x6d11551d33c7ef22e342049fec72028b83f6a92d'
            bottom={3806 + 2527 + 2700 + 1500 + 2800 - 200}
            left={75}
            level={PlanetLevel.Level7}
          />

          <R5Planet
            rank={1}
            bottom={3806 + 2527 + 2700 + 1500 + 2800 - 200}
            left={375}
            level={PlanetLevel.Level7}
          />
        </>
      </>
    </div>
  );
}
