import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { PlanetId } from '../../../../packages/types/dist';
import { planetName } from '../../../../packages/utils/dist';
import DataManager, { DataManagerEvent } from '../../Backend/DataManager';
import { Planet } from '../../_types/global';
import { SubmittedTx } from '../../_types/transactions';
import { ValhallaZIndex } from '../Pages/ValhallaGlobal/ValhallaTypes';
import { PlanetCanvas } from '../Planet/PlanetCanvas';
import {
  useAccount,
  useCanClaim,
  useDataManager,
  useIsCorrectNetwork,
  usePlanetWithId,
  usePreviewRenderer,
} from '../Utils/AppHooks';
import { TEXT_COLOR } from '../Utils/consts';
import { useEmitterSubscribe } from '../Utils/EmitterHooks';
import { monomitter } from '../Utils/Monomitter';
import { Button } from './Button';
import { LoadingSpinner } from './LoadingSpinner';

const StyledPlanetIntegration = styled.div<{ visible: boolean }>`
  position: fixed;
  min-width: 20em;
  min-height: 20em;

  font-weight: 400;

  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  background: rgba(30, 40, 60, 0.9);
  color: ${TEXT_COLOR};

  height: fit-content;
  width: fit-content;

  font-size: 12pt;

  ${({ visible }) =>
    visible
      ? `
    opacity: 1;
    z-index: ${ValhallaZIndex.Integraton};
  `
      : `
    opacity: 0;
    pointer-events: none;
    z-index: -1;
  `}

  padding: 0.5em;
  border-radius: 8px;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CloseButton = styled.span`
  font-size: 16pt;
  font-weight: 300;
  display: inline-block;
  transform: scaleX(1.5);

  position: absolute;
  right: 0;
  top: 0;
  margin: 0.5em;

  transition: filter 0.1s;

  &:hover {
    cursor: pointer;
    filter: brightness(0.75);
  }

  &:active {
    filter: brightness(0.5);
  }
`;

const PlanetTag = styled.div`
  margin: 0.5em;
`;
const PlanetHeader = styled.p`
  font-weight: 600;
  font-size: 18pt;
  text-align: center;
  margin: 0.25em;
`;

const PlanetSubtitle = styled.p`
  font-weight: 300;
  font-size: 12pt;
  text-align: center;
  margin: 0.25em;
`;

const PlanetTable = styled.div``;
const PlanetRow = styled.div`
  font-size: 12pt;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.25em;

  & span {
    flex-grow: 1;
    &:first-child {
      text-align: left;
      font-weight: 600;
      margin-right: 1em;
    }
    &:last-child {
      text-align: right;
      font-weight: 300;
    }
    max-width: 10em;
    text-overflow: ellipsis;
  }
`;

export const planetIntegration$ = monomitter<PlanetId | undefined>();
const close = () => planetIntegration$.publish(undefined);

export function PlanetIntegration() {
  const dataManager = useDataManager();

  // get selected planet
  const [id, setId] = useState<PlanetId | undefined>();

  const syncId = useCallback((i) => setId(i), [setId]);
  useEmitterSubscribe(planetIntegration$, syncId);

  const planet = usePlanetWithId(dataManager, id);

  // renderer stuff
  const size = 250;
  const { setter } = usePreviewRenderer(size, planet);

  // planet stuff
  const account = useAccount();
  const visible = !!planet;
  const won = useCanClaim(account, planet);

  return (
    <StyledPlanetIntegration visible={visible}>
      <CloseButton onClick={close}>X</CloseButton>

      <PlanetCanvas size={size} setter={setter} />

      <PlanetTag>
        <PlanetHeader>
          {planet && (
            <>
              {planetName(planet)} (lv{planet.level})
            </>
          )}
        </PlanetHeader>
        <PlanetSubtitle>
          {planet && (
            <>
              Round {planet.roundId} / Rank {planet.rank}
            </>
          )}
        </PlanetSubtitle>
      </PlanetTag>

      <PlanetTable>
        <PlanetRow>
          <span>Original Winner</span>
          <span>{planet && planet.originalWinner}</span>
        </PlanetRow>
        {planet && planet.originalWinnerTwitter ? (
          <PlanetRow>
            <span>Handle</span>
            <span>{`@${planet.originalWinnerTwitter}`}</span>
          </PlanetRow>
        ) : null}
        <PlanetRow>
          <span>Current Owner</span>
          <span>{planet && planet?.claimed ? planet.owner : 'unclaimed'}</span>
        </PlanetRow>
      </PlanetTable>

      {account ? (
        <>
          {won && (
            <>
              {planet?.claimed ? (
                <p>You have claimed this planet!</p>
              ) : (
                <ClaimPlanetButton planet={planet} dataManager={dataManager} />
              )}
            </>
          )}
        </>
      ) : (
        <MetamaskButton dataManager={dataManager} />
      )}
    </StyledPlanetIntegration>
  );
}

function ClaimPlanetButton({
  dataManager,
  planet,
}: {
  planet: Planet | undefined;
  dataManager: DataManager;
}) {
  const isCorrectNetwork = useIsCorrectNetwork();

  const [error, setError] = useState<boolean>(false);
  const [claiming, setClaiming] = useState<boolean>(false);

  const [txHash, setTxHash] = useState<string | undefined>();

  const claim = useCallback(async () => {
    if (!planet) return;
    setClaiming(true);
    try {
      const hash = await dataManager.claimPlanet(planet.id);
      if (!hash) {
        setError(true);
      } else {
        setTxHash(hash);
      }
    } catch (err) {
      console.error(err);
      setError(true);
    }
  }, [planet, setClaiming, dataManager]);

  useEffect(() => {
    if (!txHash) return;

    const onRevert = (tx: SubmittedTx) => {
      if (tx.txHash === txHash) {
        console.error('tx reverted!');
        setError(true);
      }
    };

    dataManager.addListener(DataManagerEvent.TxReverted, onRevert);

    return () => {
      dataManager.removeListener(DataManagerEvent.TxReverted, onRevert);
    };
  }, [txHash, dataManager]);

  if (!isCorrectNetwork) return <Button disabled>Switch to Ethereum Mainnet!</Button>;

  return (
    <Button click={claim} disabled={!!(claiming || error || planet?.pendingClaim)}>
      {error ? (
        'Error Claiming!'
      ) : planet?.pendingClaim ? (
        <LoadingSpinner initialText={'Claim Submitted...'} />
      ) : claiming ? (
        <LoadingSpinner initialText={'Claiming...'} />
      ) : (
        'Claim Planet'
      )}
    </Button>
  );
}

function MetamaskButton({ dataManager }: { dataManager: DataManager }) {
  const [error, setError] = useState<boolean>(false);
  const [connecting, setConnecting] = useState<boolean>(false);

  const connect = useCallback(async () => {
    setConnecting(true);
    try {
      await dataManager.upgradeToMetamask();
    } catch (err) {
      console.error(err);
      setError(true);
    }
  }, [setConnecting, dataManager]);

  return (
    <Button click={connect} disabled={connecting || error}>
      {error ? (
        'Error Connecting!'
      ) : connecting ? (
        <LoadingSpinner initialText={'Connecting...'} />
      ) : (
        'Connect to Metamask'
      )}
    </Button>
  );
}
