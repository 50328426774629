import React from 'react';
import { RoundImage } from '../ValhallaGlobal/ValhallaImage';
import {
  BackgroundPlacement,
  BoundingBox,
  R3FileName,
  ImageProps,
  RoundNumber,
} from '../ValhallaGlobal/ValhallaTypes';

export const R3ImageProps: Record<R3FileName, ImageProps> = {
  [R3FileName.Tier_1]: {
    imgWidth: 640,
    imgHeight: 1618,
    zIndex: 0,
    boundingBox: BoundingBox.FillXFitY,
    placement: BackgroundPlacement.Background,
  },
  [R3FileName.Tier_2]: {
    imgWidth: 640,
    imgHeight: 4323,
    zIndex: 0,
    boundingBox: BoundingBox.FillXFitY,
    placement: BackgroundPlacement.Background,
  },
  [R3FileName.Tier_3]: {
    imgWidth: 640,
    imgHeight: 2609,
    zIndex: 0,
    boundingBox: BoundingBox.FillXFitY,
    placement: BackgroundPlacement.Background,
  },
  [R3FileName.Tier_4]: {
    imgWidth: 640,
    imgHeight: 2783,
    zIndex: 0,
    boundingBox: BoundingBox.FillXFitY,
    placement: BackgroundPlacement.Background,
  },
  [R3FileName.Tier_5]: {
    imgWidth: 640,
    imgHeight: 1840,
    zIndex: 0,
    boundingBox: BoundingBox.FillXFitY,
    placement: BackgroundPlacement.Background,
  },
  [R3FileName.Tier_6]: {
    imgWidth: 640,
    imgHeight: 3017,
    zIndex: 0,
    boundingBox: BoundingBox.FillXFitY,
    placement: BackgroundPlacement.Background,
  },
};

export function R3Images() {
  return (
    <>
      <RoundImage fileName={R3FileName.Tier_6} round={RoundNumber.Round3} />
      <RoundImage fileName={R3FileName.Tier_5} round={RoundNumber.Round3} />
      <RoundImage fileName={R3FileName.Tier_4} round={RoundNumber.Round3} />
      <RoundImage fileName={R3FileName.Tier_3} round={RoundNumber.Round3} />
      <RoundImage fileName={R3FileName.Tier_2} round={RoundNumber.Round3} />
      <RoundImage fileName={R3FileName.Tier_1} round={RoundNumber.Round3} />
    </>
  );
}
