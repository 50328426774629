import { PlanetType } from 'common-types';
import React, { useEffect, useMemo } from 'react';
import DataManager from '../../../Backend/DataManager';
import { Banner } from '../../Components/Banner';
import { Header } from '../../Components/Header';
import { planetUrl, FileType } from '../../Planet/PlanetUtils';
import { LoaderManager } from '../../Utils/ImageLoader';
import { getUrl } from '../../Utils/Utils';
import { R4FileName, RoundNumber } from '../ValhallaGlobal/ValhallaTypes';
import { R4Images } from './R4ImageProps';
import { R4Planets } from './R4Planets';

const R4_BG: string[] = [
  R4FileName.Tier_1,
  R4FileName.Tier_2,
  R4FileName.Tier_3,
  R4FileName.Tier_4,
  R4FileName.Tier_5,
  R4FileName.Tier_6,
].map((str) => getUrl(str, RoundNumber.Round4));

const R4_ALL: string[] = Object.values(R4FileName).map((str) => getUrl(str, RoundNumber.Round4));

export function R4Page({
  setLoaded,
  loaded,
  loaderManager,
  dataManager,
}: {
  setLoaded: (value: boolean) => void;
  loaderManager: LoaderManager;
  dataManager: DataManager;
  loaded: boolean;
}) {
  /* create loading lists */
  const r4planets = useMemo(
    () =>
      dataManager
        ? Array.from(dataManager.getPlanets().values())
            .filter((p) => p.planetType === PlanetType.WINNER)
            .map((p) => planetUrl(p, FileType.Png))
        : undefined,
    [dataManager]
  );

  useEffect(() => {
    if (!loaderManager) return;
    if (!r4planets) return;
    loaderManager.loadMany(
      [
        { urls: R4_BG, loaderId: 'background' },
        { urls: R4_ALL, loaderId: 'foreground' },
      ],
      () => setLoaded(true)
    );
  }, [loaderManager, r4planets]);

  if (!loaded) {
    return <></>;
  }

  return (
    <>
      <R4Images />
      <R4Planets />

      <Header round={RoundNumber.Round4} bottom={100} />

      <Banner text={'Tier 1'} bottom={3806 + 2527 + 2700 + 1500 + 2800} />
      <Banner text={'Special Prizes'} bottom={3806 + 2527 + 2700 + 1500} />
      <Banner text={'Tier 2'} bottom={3806 + 2527 + 2800} />
      <Banner text={'Tier 3'} bottom={3806 + 2527 + 1150} />
      <Banner text={'Tier 4'} bottom={3806 + 2527} />
      <Banner text={'Tier 5'} bottom={3806} />
      <Banner text={'Tier 6'} bottom={1000} />
      <Banner text={'Scroll Up'} bottom={400} />
    </>
  );
}
