import React from 'react';
import styled from 'styled-components';
import { RoundNumber, ValhallaZIndex } from '../Pages/ValhallaGlobal/ValhallaTypes';
import { useRes } from '../Utils/AppHooks';
import { GRAY, TEXT_COLOR, TITLE_FONT } from '../Utils/consts';
import { getBottom } from '../Utils/Utils';

export const RoundNames: Record<RoundNumber, string> = {
  [RoundNumber.Round1]: 'Round 1: Fascinated Manager',
  [RoundNumber.Round2]: 'Round 2: Inspired Hallowed',
  [RoundNumber.Round3]: 'Round 3: Grape Extra-Small',
  [RoundNumber.Round4]: 'Round 4: Society Eggnog',
  [RoundNumber.Round5]: 'Round 5: The Junk Wars',
};

const StyledHeader = styled.div<{ res: number; bottom: number; round: RoundNumber }>`
  background: black;
  position: absolute;
  z-index: ${ValhallaZIndex.Banners};

  margin: auto;
  left: 0;
  right: 0;

  ${({ res, bottom, round }) => `
    bottom: ${res * getBottom(bottom, round)}px;
    padding: ${res * 12}px;
  `}

  border-radius: 8px;

  width: fit-content;
  text-align: center;
`;

const Title = styled.p<{ res: number }>`
  margin: 0;
  font-family: ${TITLE_FONT};
  ${({ res }) => `
    font-size: ${res * 36}px;
  `}
  color: ${TEXT_COLOR};
`;

const Subtitle = styled.p<{ res: number }>`
  margin: 0;
  color: ${GRAY};
  ${({ res }) => `
    font-size: ${res * 18}px;
    margin-top: ${res * 12}px;
  `}
  font-weight: 300;
`;

const Green = styled.span`
  color: #00ff00;
`;

export function Header({ bottom, round }: { bottom: number; round: RoundNumber }) {
  const roundName = RoundNames[round];
  const res = useRes();

  const props = { bottom, round, res };

  return (
    <StyledHeader {...props}>
      <Title res={res}>
        <Green>{'>'}</Green>
        {' dark forest v0.6'}
      </Title>
      <Subtitle res={res}>{roundName}</Subtitle>
    </StyledHeader>
  );
}
