import { PlanetId, ValhallaPlanet } from 'common-types';
import { PlanetLevel } from '../Pages/ValhallaGlobal/ValhallaTypes';
import * as THREE from 'three';
import { planetFileName } from '../../../../packages/utils/dist';

export type PlanetColors = {
  landColor: THREE.Vector3;
  waterColor: THREE.Vector3;
  sandColor: THREE.Vector3;
  sandColor2: THREE.Vector3;
  iceColor: THREE.Vector3;
  cloudColor?: THREE.Color;
};

export type PlanetProps = {
  octaves: number;
  colors: PlanetColors;
  clouds: boolean;
};

type HSLVec = [number, number, number];
type RGBVec = [number, number, number];

// [0-360, 0-100, 0-100]
// prettier-ignore
export function hslToRgb([h, s, l]: HSLVec): RGBVec {
  h = h % 360;
  s = Math.max(Math.min(s, 100), 0);
  l = Math.max(Math.min(l, 100), 0);

  s /= 100;
  l /= 100;

  const c = (1 - Math.abs(2 * l - 1)) * s,
    x = c * (1 - Math.abs(((h / 60) % 2) - 1)),
    m = l - c / 2;

  let r = 0, g = 0, b = 0;

  if (0 <= h && h < 60) {
    r = c; g = x; b = 0;
  } else if (60 <= h && h < 120) {
    r = x; g = c; b = 0;
  } else if (120 <= h && h < 180) {
    r = 0; g = c; b = x;
  } else if (180 <= h && h < 240) {
    r = 0; g = x; b = c;
  } else if (240 <= h && h < 300) {
    r = x; g = 0; b = c;
  } else if (300 <= h && h < 360) {
    r = c; g = 0; b = x;
  }

  r = Math.round((r + m) * 255);
  g = Math.round((g + m) * 255);
  b = Math.round((b + m) * 255);

  return [r, g, b];
}

function seededRandom(seed: number) {
  const x = Math.sin(seed) * 10000;
  return x - Math.floor(x);
}

export function planetRandom(id: PlanetId) {
  // shouldn't need to clone since loc is primitive but just to be safe
  let count = 0;
  const countOffset = parseInt(id.substring(0, 10));

  return () => {
    count++;
    const ret = seededRandom(count + countOffset);
    return ret;
  };
}

export function planetRandomInt(loc: PlanetId) {
  const rand = planetRandom(loc);
  return () => Math.floor(rand() * 2 ** 24);
}

/*
  return {
    landColor: new THREE.Vector3(162, 204, 140),
    iceColor: new THREE.Vector3(240, 250, 255),
    waterColor: new THREE.Vector3(81, 108, 131),
    sandColor: new THREE.Vector3(255, 232, 177),
    sandColor2: new THREE.Vector3(190, 210, 225),
  };
*/

function baseColorsFromPlanet(planet: ValhallaPlanet): PlanetColors {
  const randInt = planetRandomInt(planet.id);

  let landColor = new THREE.Vector3(0, 0, 0);
  let waterColor = new THREE.Vector3(0, 0, 0);
  let sandColor = new THREE.Vector3(0, 0, 0);
  let sandColor2 = new THREE.Vector3(0, 0, 0);
  let iceColor = new THREE.Vector3(0, 0, 0);

  let cloudColor = undefined;

  if (planet.level === 2) {
    const hueShift = (randInt() % 80) - 40;
    const landHsl: HSLVec = [200 + hueShift, 40, 67];
    const land = new THREE.Vector3(...hslToRgb(landHsl));
    const waterHsl: HSLVec = [234 + hueShift, 40, 67];
    const water = new THREE.Vector3(...hslToRgb(waterHsl));

    landColor = land;
    iceColor = land;
    waterColor = water;
    sandColor = water;
    sandColor2 = water;
  } else if (planet.level === 3) {
    const hueShift = (randInt() % 20) - 4;
    const landHsl: HSLVec = [30 + hueShift, 80, 64];
    const land = new THREE.Vector3(...hslToRgb(landHsl));
    const waterHsl: HSLVec = [hueShift + 154, 65, 80];
    const water = new THREE.Vector3(...hslToRgb(waterHsl));
    const sandHsl: HSLVec = [350 + hueShift, 39, 70];
    const sand = new THREE.Vector3(...hslToRgb(sandHsl));

    landColor = land;
    iceColor = land;
    waterColor = water;
    sandColor = sand;
    sandColor2 = sand;
  } else if (planet.level === 4) {
    const hueShift = (randInt() % 20) - 4;
    const landHsl: HSLVec = [hueShift + 352, 73, 49];
    const land = new THREE.Vector3(...hslToRgb(landHsl));
    const waterHsl: HSLVec = [hueShift + 181, 70, 65];
    const water = new THREE.Vector3(...hslToRgb(waterHsl));
    const sandHsl: HSLVec = [hueShift + 316, 31, 79];
    const sand = new THREE.Vector3(...hslToRgb(sandHsl));

    landColor = land;
    iceColor = land;
    waterColor = water;
    sandColor = sand;
    sandColor2 = sand;
  } else if (planet.level === 5) {
    const hueShift = (randInt() % 16) - 4;
    const landHsl: HSLVec = [hueShift + 21, 76, 43];
    const land = new THREE.Vector3(...hslToRgb(landHsl));
    const waterHsl: HSLVec = [hueShift + 158, 61, 60];
    const water = new THREE.Vector3(...hslToRgb(waterHsl));
    const sandHsl: HSLVec = [hueShift + 28, 99, 84];
    const sand = new THREE.Vector3(...hslToRgb(sandHsl));
    const iceHsl: HSLVec = [hueShift + 193, 20, 94];
    const ice = new THREE.Vector3(...hslToRgb(iceHsl));

    landColor = land;
    iceColor = ice;
    waterColor = water;
    sandColor = sand;
    sandColor2 = sand;
  } else if (planet.level === 6) {
    const hueShift = (randInt() % 16) - 4;
    const landHsl: HSLVec = [hueShift + 189, 10, 70];
    const land = new THREE.Vector3(...hslToRgb(landHsl));
    const waterHsl: HSLVec = [hueShift + 205, 53, 54];
    const water = new THREE.Vector3(...hslToRgb(waterHsl));
    const sandHsl: HSLVec = [hueShift + 205, 16, 85];
    const sand = new THREE.Vector3(...hslToRgb(sandHsl));
    const sand2Hsl: HSLVec = [hueShift + 205, 53, 70];
    const sand2 = new THREE.Vector3(...hslToRgb(sand2Hsl));
    const iceHsl: HSLVec = [hueShift + 205, 0, 94];
    const ice = new THREE.Vector3(...hslToRgb(iceHsl));

    landColor = land;
    iceColor = ice;
    waterColor = water;
    sandColor = sand;
    sandColor2 = sand2;
  } else if (planet.level === 7) {
    const landHsl: HSLVec = [60, 100, 80];
    const land = new THREE.Vector3(...hslToRgb(landHsl));
    const waterHsl: HSLVec = [52, 100, 70];
    const water = new THREE.Vector3(...hslToRgb(waterHsl));
    const sand = new THREE.Vector3(255, 191, 100);

    landColor = land;
    iceColor = water;
    waterColor = water;
    sandColor = water;
    sandColor2 = sand;
    cloudColor = new THREE.Color(0xf7c3ff);
  }

  return { landColor, waterColor, sandColor, sandColor2, iceColor, cloudColor };
}

const Octaves: Record<PlanetLevel, number> = {
  [PlanetLevel.Level2]: 1,
  [PlanetLevel.Level3]: 2,
  [PlanetLevel.Level4]: 3,
  [PlanetLevel.Level5]: 3,
  [PlanetLevel.Level6]: 4,
  [PlanetLevel.Level7]: 5,
};

// level 2: 1 noise octaves, 2 colors
// level 3: 2 noise octaves, 3 colors (+ sand)
// level 4: 3 noise octaves, 3 colors
// level 5: 3 noise octaves, 4 colors (+ ice)
// level 6: 4 noise octaves, 5 colors (+ sand 2)
// level 7: 5 noise octaves, 5 colors  + clouds
export function getPlanetProps(planet: ValhallaPlanet): PlanetProps {
  let octaves = Octaves[planet.level as PlanetLevel];
  if (planet.level < PlanetLevel.Level2 || planet.level > PlanetLevel.Level7) octaves = 1; // fallback for an undefined level

  const baseColors = baseColorsFromPlanet(planet);
  const { landColor, waterColor, sandColor, sandColor2, iceColor, cloudColor } = baseColors;

  // const colors = baseColors;
  const sColor = planet.level >= 3 ? sandColor : landColor;
  const colors: PlanetColors = {
    landColor,
    waterColor,
    sandColor: sColor,
    sandColor2: planet.level >= 6 ? sandColor2 : sColor,
    iceColor: planet.level >= 5 ? iceColor : landColor,
    cloudColor,
  };

  const clouds = planet.level === PlanetLevel.Level7;

  return { octaves, clouds, colors };
}

export enum FileType {
  Png = 'png',
  Gif = 'gif',
  Webm = 'webm',
}

const isProd = process.env.NODE_ENV === 'production';

const TEST_URL = '/public/planets';
const PROD_URL = 'https://darkforest-valhalla.s3.us-east-2.amazonaws.com/resources';

const BASE_URL = isProd ? PROD_URL : TEST_URL;

export function planetUrl(planet: ValhallaPlanet, fileType: FileType): string {
  return `${BASE_URL}/${fileType}/${planetFileName(planet)}.${fileType}`;
}
