import React from 'react';
import { Round1Image, RoundImage } from '../ValhallaGlobal/ValhallaImage';
import {
  BackgroundPlacement,
  BoundingBox,
  R2FileName,
  ImageProps,
  RoundNumber,
  R1FileName,
} from '../ValhallaGlobal/ValhallaTypes';

export const R2ImageProps: Record<R2FileName, ImageProps> = {
  [R2FileName.Intro_Banner]: {
    imgWidth: 1751,
    imgHeight: 426,
    zIndex: 1,
    boundingBox: BoundingBox.FillXFitY,
    placement: BackgroundPlacement.BottomLeftObject,
    position: { bottom: 0, left: 0 },
  },
  [R2FileName.Intro]: {
    imgWidth: 640,
    imgHeight: 1466,
    zIndex: 0,
    boundingBox: BoundingBox.FillXFitY,
    placement: BackgroundPlacement.Background,
  },
  [R2FileName.Tier_1]: {
    imgWidth: 640,
    imgHeight: 1816,
    zIndex: 0,
    boundingBox: BoundingBox.FillXFitY,
    placement: BackgroundPlacement.Background,
  },
  [R2FileName.Tier_2]: {
    imgWidth: 640,
    imgHeight: 1973,
    zIndex: 0,
    boundingBox: BoundingBox.FillXFitY,
    placement: BackgroundPlacement.Background,
  },
  [R2FileName.Tier_3]: {
    imgWidth: 640,
    imgHeight: 1726,
    zIndex: 0,
    boundingBox: BoundingBox.FillXFitY,
    placement: BackgroundPlacement.Background,
  },
  [R2FileName.Tier_4]: {
    imgWidth: 640,
    imgHeight: 2048,
    zIndex: 0,
    boundingBox: BoundingBox.FillXFitY,
    placement: BackgroundPlacement.Background,
  },
  [R2FileName.Tier_5]: {
    imgWidth: 640,
    imgHeight: 2452,
    zIndex: 0,
    boundingBox: BoundingBox.FillXFitY,
    placement: BackgroundPlacement.Background,
  },
  [R2FileName.Tier_6]: {
    imgWidth: 640,
    imgHeight: 3487,
    zIndex: 0,
    boundingBox: BoundingBox.FillXFitY,
    placement: BackgroundPlacement.Background,
  },
};

export function R2Images() {
  return (
    <>
      <RoundImage fileName={R2FileName.Tier_1} round={RoundNumber.Round2} />
      <RoundImage fileName={R2FileName.Tier_2} round={RoundNumber.Round2} />
      <RoundImage fileName={R2FileName.Tier_3} round={RoundNumber.Round2} />
      <RoundImage fileName={R2FileName.Tier_4} round={RoundNumber.Round2} />
      <RoundImage fileName={R2FileName.Tier_5} round={RoundNumber.Round2} />
      <RoundImage fileName={R2FileName.Tier_6} round={RoundNumber.Round2} />
      <RoundImage fileName={R2FileName.Intro} round={RoundNumber.Round2} />
    </>
  );
}
