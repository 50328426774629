import React from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { RoundNames } from './Components/Header';
import { RoundNumber } from './Pages/ValhallaGlobal/ValhallaTypes';

export const EmSpacer = styled.div`
  ${({ width, height }: { width?: number; height?: number }) => css`
    width: 1px;
    height: 1px;
    ${width && !height ? 'display: inline-block;' : ''}
    ${width ? `width: ${width}em;` : ''}
    ${height ? `height: ${height}em;min-height:${height}em;` : ''}
  `}
`;

const SelectRoundPageContainer = styled.div`
  color: white;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  a {
    cursor: pointer;
    color: #ddd;
    &:hover {
      text-decoration: underline;
      color: #fff;
    }
  }
`;

const SelectRoundTitle = styled.div`
  font-size: 1.2em;
`;

export function SelectRoundPage() {
  return (
    <SelectRoundPageContainer>
      <SelectRoundTitle>DARK FOREST v0.6 Valhalla</SelectRoundTitle>
      <EmSpacer height={1} />
      <EmSpacer height={1} />
      <Link to='/round-1'>{RoundNames[RoundNumber.Round1]}</Link>
      <EmSpacer height={1} />
      <Link to='/round-2'>{RoundNames[RoundNumber.Round2]}</Link>
      <EmSpacer height={1} />
      <Link to='/round-3'>{RoundNames[RoundNumber.Round3]}</Link>
      <EmSpacer height={1} />
      <Link to='/round-4'>{RoundNames[RoundNumber.Round4]}</Link>
      <EmSpacer height={1} />
      <Link to='/round-5'>{RoundNames[RoundNumber.Round5]}</Link>
    </SelectRoundPageContainer>
  );
}
