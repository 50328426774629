"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.planetNameWords3 = void 0;
exports.planetNameWords3 = [
    'abandon',
    'abate',
    'aberrant',
    'blaze',
    'abounding',
    'absorb',
    'acoustic',
    'harmony',
    'act',
    'action',
    'actor',
    'add',
    'addition',
    'adjustment',
    'admit',
    'advice',
    'advise',
    'afraid',
    'aggressive',
    'agonizing',
    'ahead',
    'airplane',
    'airport',
    'alight',
    'alike',
    'aloof',
    'ambitious',
    'amuck',
    'amusement',
    'anger',
    'animated',
    'annoyed',
    'anxious',
    'apparel',
    'applaud',
    'apple',
    'apples',
    'apply',
    'aquatic',
    'argument',
    'aromatic',
    'arrest',
    'assert',
    'attempt',
    'audacious',
    'auspicious',
    'authority',
    'avoid',
    'awake',
    'badge',
    'bait',
    'ball',
    'band',
    'band',
    'banish',
    'barbarous',
    'base',
    'base',
    'baseball',
    'basket',
    'bat',
    'bawdy',
    'bead',
    'bear',
    'beast',
    'beautify',
    'beds',
    'befall',
    'befitting',
    'behold',
    'believe',
    'bell',
    'bells',
    'bend',
    'berserk',
    'beseech',
    'big',
    'birthday',
    'bite',
    'bite',
    'blade',
    'blow',
    'blush',
    'bone',
    'books',
    'books',
    'boot',
    'bottle',
    'bouncy',
    'boundary',
    'bow',
    'brainy',
    'brass',
    'breath',
    'breathe',
    'bridge',
    'bright',
    'bring',
    'broadcast',
    'brother',
    'brothers',
    'bulb',
    'burly',
    'burst',
    'bury',
    'bushes',
    'busy',
    'button',
    'cabbage',
    'cagey',
    'calculating',
    'callous',
    'calm',
    'can',
    'canvas',
    'capable',
    'careless',
    'carriage',
    'cars',
    'cast',
    'catch',
    'cease',
    'celebrate',
    'cent',
    'chain',
    'chairs',
    'change',
    'changeable',
    'channel',
    'charge',
    'charming',
    'chat',
    'cheese',
    'cherries',
    'chide',
    'chief',
    'children',
    'chip',
    'choke',
    'clean',
    'cleave',
    'clocks',
    'closed',
    'clutch',
    'cluttered',
    'coast',
    'collapse',
    'collapse',
    'collar',
    'colour',
    'combative',
    'comfortable',
    'competition',
    'complain',
    'complete',
    'complex',
    'condition',
    'confuse',
    'connection',
    'connote',
    'conserve',
    'conspire',
    'constitute',
    'constrain',
    'contribute',
    'convene',
    'converge',
    'convict',
    'convince',
    'coo',
    'cooing',
    'cool',
    'cooperative',
    'copper',
    'cork',
    'corrod',
    'cough',
    'country',
    'cow',
    'cowardly',
    'crabby',
    'cracker',
    'crashing',
    'creep',
    'crib',
    'cry',
    'cub',
    'cumbersome',
    'cup',
    'cure',
    'curly',
    'curve',
    'cut',
    'cute',
    'cute',
    'daffy',
    'daffy',
    'daily',
    'dance',
    'dark',
    'daughter',
    'dazzling',
    'deafening',
    'debonair',
    'decision',
    'declare',
    'decorous',
    'defiant',
    'degree',
    'delay',
    'delicate',
    'delirious',
    'depend',
    'desire',
    'destruction',
    'develop',
    'die',
    'different',
    'digestion',
    'diligent',
    'dim',
    'diminish',
    'dinner',
    'direct',
    'dirty',
    'disgusted',
    'dispensable',
    'display',
    'display',
    'distinct',
    'dive',
    'dive',
    'divide',
    'dock',
    'doctor',
    'dog',
    'doll',
    'drain',
    'draw',
    'dream',
    'dreary',
    'drink',
    'drive',
    'drop',
    'dry',
    'dull',
    'dust',
    'dynamic',
    'dysfunctional',
    'earn',
    'earthy',
    'eatable',
    'edge',
    'education',
    'efficacious',
    'egg',
    'eggnog',
    'elephant',
    'elite',
    'elite',
    'eminent',
    'empower',
    'empty',
    'enchanting',
    'encourage',
    'end',
    'end',
    'endorse',
    'enjoy',
    'enlighten',
    'enormous',
    'enthusiastic',
    'envious',
    'erratic',
    'escape',
    'evasive',
    'event',
    'excited',
    'exclusive',
    'expensive',
    'expert',
    'extend',
    'extra-large',
    'extra-small',
    'eyes',
    'fabulous',
    'fair',
    'fall',
    'fan',
    'far',
    'fascinated',
    'fast',
    'fear',
    'feather',
    'feed',
    'feet',
    'field',
    'fight',
    'finger',
    'flaky',
    'flame',
    'flap',
    'flee',
    'flesh',
    'flock',
    'floor',
    'flop',
    'flower',
    'fly',
    'fly',
    'fog',
    'fold',
    'follow',
    'foregoing',
    'four',
    'freeze',
    'friend',
    'frighten',
    'frightened',
    'fulfil',
    'funny',
    'garrulous',
    'gash',
    'gentle',
    'gentle',
    'giant',
    'giant',
    'gigantic',
    'giraffe',
    'glamorous',
    'glass',
    'glass',
    'glib',
    'glorious',
    'glove',
    'glow',
    'godly',
    'golden',
    'govern',
    'grade',
    'grape',
    'grass',
    'green',
    'green',
    'grind',
    'ground',
    'group',
    'grow',
    'growth',
    'gruesome',
    'grumpy',
    'guarded',
    'guess',
    'guide',
    'gusty',
    'haircut',
    'hallowed',
    'halting',
    'handsome',
    'hang',
    'happy',
    'harbor',
    'harm',
    'harmonious',
    'hat',
    'heal',
    'hearing',
    'heart',
    'heat',
    'hiss',
    'honey',
    'hope',
    'hose',
    'hospitable',
    'humorous',
    'hurt',
    'hurt',
    'hush',
    'hustle',
    'hydrant',
    'hypnotic',
    'hypnotize',
    'ice',
    'idealize',
    'ill-fated',
    'illustrate',
    'imaginary',
    'imagine',
    'imminent',
    'immolate',
    'impart',
    'impartial',
    'impend',
    'impend',
    'imperfect',
    'imperil',
    'imperil',
    'implant',
    'implicate',
    'impolite',
    'imported',
    'income',
    'incompetent',
    'increase',
    'incredible',
    'indicate',
    'induce',
    'indulge',
    'industry',
    'infect',
    'inherit',
    'initiate',
    'innocent',
    'inspire',
    'insult',
    'insurance',
    'invent',
    'invention',
    'iron',
    'jaded',
    'jail',
    'jam',
    'jealous',
    'jeans',
    'jellyfish',
    'jobless',
    'jumbled',
    'kill',
    'kiss',
    'knot',
    'knowing',
    'lade',
    'ladybug',
    'lamentable',
    'lamp',
    'languid',
    'latch',
    'laugh',
    'lavish',
    'lead',
    'leak',
    'lean',
    'leather',
    'lend',
    'let',
    'letters',
    'lick',
    'lift',
    'lip',
    'list',
    'listen',
    'listen',
    'lively',
    'lock',
    'lock',
    'locket',
    'lonely',
    'longing',
    'look',
    'loose',
    'lose',
    'love',
    'low',
    'lowly',
    'ludicrous',
    'lumpy',
    'lunch',
    'lyrical',
    'maddening',
    'magnificent',
    'mailbox',
    'make',
    'manage',
    'manager',
    'marble',
    'mass',
    'materialistic',
    'meal',
    'measly',
    'measure',
    'meat',
    'meek',
    'meet',
    'mellow',
    'mere',
    'mind',
    'mine',
    'miniature',
    'minister',
    'mint',
    'miscreant',
    'motionless',
    'motivate',
    'mountain',
    'multiply',
    'mundane',
    'mushy',
    'music',
    'nappy',
    'need',
    'nerve',
    'nest',
    'news',
    'next',
    'nifty',
    'nimble',
    'noise',
    'noiseless',
    'noisy',
    'nostalgic',
    'notify',
    'notify',
    'null',
    'nut',
    'oatmeal',
    'oatmeal',
    'observation',
    'oceanic',
    'odd',
    'old-fashioned',
    'onerous',
    'open',
    'operation',
    'opinion',
    'oranges',
    'order',
    'ordinary',
    'organization',
    'originate',
    'outgoing',
    'output',
    'outrageous',
    'outstanding',
    'oven',
    'overflow',
    'overrated',
    'owe',
    'own',
    'pacify',
    'pain',
    'pale',
    'pale',
    'paltry',
    'pan',
    'paper',
    'partake',
    'participate',
    'party',
    'passenger',
    'past',
    'patch',
    'pay',
    'peace',
    'peaceful',
    'penitent',
    'permissible',
    'pet',
    'phone',
    'physical',
    'pink',
    'piquant',
    'place',
    'plain',
    'plan',
    'please',
    'pointless',
    'poison',
    'police',
    'ponder',
    'poor',
    'possessive',
    'praise',
    'precious',
    'premium',
    'prescribe',
    'preserve',
    'preset',
    'prickly',
    'probable',
    'proceed',
    'protect',
    'public',
    'pull',
    'pull',
    'punish',
    'puny',
    'purpose',
    'push',
    'puzzling',
    'quack',
    'qualify',
    'quarrel',
    'quilt',
    'quince',
    'quirky',
    'quit',
    'quiver',
    'quixotic',
    'ragged',
    'rain',
    'rapid',
    'rate',
    'ratty',
    'reach',
    'reaction',
    'read',
    'rebel',
    'recall',
    'receipt',
    'receptive',
    'recondite',
    'redo',
    'refer',
    'reflective',
    'refuse',
    'regret',
    'regular',
    'relate',
    'relation',
    'rend',
    'renew',
    'repair',
    'repeat',
    'reply',
    'resell',
    'resolve',
    'resolve',
    'respect',
    'respect',
    'review',
    'rhythm',
    'rifle',
    'ring',
    'ring',
    'river',
    'roar',
    'roasted',
    'rob',
    'rob',
    'rock',
    'rock',
    'roomy',
    'rose',
    'rotten',
    'route',
    'rush',
    'sable',
    'sag',
    'salt',
    'salve',
    'same',
    'sample',
    'sanctify',
    'sash',
    'sassy',
    'satirise',
    'savor',
    'saw',
    'say',
    'scar',
    'scarf',
    'scarify',
    'scary',
    'school',
    'scientific',
    'scold',
    'scorch',
    'scrawl',
    'scrawny',
    'sea',
    'search',
    'seashore',
    'seat',
    'secretive',
    'sedate',
    'seemly',
    'sense',
    'sentence',
    'sever',
    'sew',
    'shake',
    'sharp',
    'shear',
    'shed',
    'sheet',
    'ship',
    'shivering',
    'shock',
    'shun',
    'shy',
    'side',
    'sight',
    'signify',
    'silky',
    'silly',
    'silver',
    'simple',
    'sing',
    'sink',
    'sit',
    'six',
    'skid',
    'skillful',
    'sleep',
    'sleepy',
    'slide',
    'slim',
    'slink',
    'slip',
    'slippery',
    'slow',
    'smell',
    'smooth',
    'snail',
    'snake',
    'snap',
    'sneeze',
    'soap',
    'sob',
    'society',
    'soda',
    'soggy',
    'solicit',
    'sore',
    'sorrow',
    'sound',
    'soup',
    'sour',
    'spade',
    'spark',
    'spectacular',
    'spill',
    'spiteful',
    'splendid',
    'spotted',
    'spotty',
    'spray',
    'spring',
    'square',
    'squeamish',
    'standing',
    'stare',
    'statuesque',
    'steady',
    'step',
    'sticky',
    'stir',
    'stitch',
    'stomach',
    'store',
    'story',
    'straight',
    'strain',
    'straw',
    'stream',
    'street',
    'strew',
    'strive',
    'strong',
    'stupendous',
    'submit',
    'succeed',
    'suck',
    'suggest',
    'summon',
    'sun',
    'superb',
    'superficial',
    'suppose',
    'surround',
    'survive',
    'swallow',
    'sway',
    'sweet',
    'swim',
    'swing',
    'tail',
    'talented',
    'talk',
    'tall',
    'tan',
    'tart',
    'taste',
    'tasteless',
    'tax',
    'teach',
    'tearful',
    'teeth',
    'tense',
    'terminate',
    'terrible',
    'terrific',
    'terrify',
    'testy',
    'thankful',
    'theory',
    'therapeutic',
    'thing',
    'think',
    'thinkable',
    'third',
    'thought',
    'throne',
    'thumb',
    'ticket',
    'tie',
    'tiger',
    'tiger',
    'tightfisted',
    'time',
    'time',
    'time',
    'tired',
    'tongue',
    'toothbrush',
    'toothsome',
    'toss',
    'town',
    'toys',
    'trample',
    'transfer',
    'tray',
    'tree',
    'tremble',
    'tremendous',
    'tricky',
    'triumph',
    'trouble',
    'truculent',
    'trust',
    'try',
    'tub',
    'turn',
    'twig',
    'twist',
    'two',
    'type',
    'ugliest',
    'ugly',
    'ultra',
    'umbrella',
    'undesirable',
    'undo',
    'unequaled',
    'unkempt',
    'unsightly',
    'unsuitable',
    'upset',
    'used',
    'utopian',
    'utter',
    'vacation',
    'vacation',
    'vanish',
    'vengeful',
    'verify',
    'vessel',
    'vigorous',
    'walk',
    'wander',
    'warn',
    'wasteful',
    'watch',
    'water',
    'waylay',
    'weak',
    'weigh',
    'welcome',
    'wheel',
    'whistle',
    'wide-eyed',
    'wing',
    'witty',
    'wool',
    'wrench',
    'write',
    'wry',
    'xenology',
    'yarn',
    'year',
    'yield',
    'yoke',
    'zephyr',
    'zipper',
    'zippy',
    'otter',
    'llama',
    'aardvark',
    'aggravated',
    'luck',
    'luxury',
    'satisfaction',
    'trust',
    'wisdom',
    'worry',
    'pride',
    'sin',
    'sloth',
    'greed',
    'opinion',
    'movement',
    'infancy',
    'envy',
    'evil',
    'failure',
    'success',
    'birth',
    'calm',
    'shallow',
    'insect',
    'fox',
    'panda',
    'penguin',
    'lodge',
    'town',
    'awareness',
    'anxiety',
    'childhood',
    'chaos',
];
