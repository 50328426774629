import React from 'react';
import styled, { FlattenSimpleInterpolation } from 'styled-components';
import { RoundNumber, ValhallaZIndex } from '../Pages/ValhallaGlobal/ValhallaTypes';
import { useRes } from '../Utils/AppHooks';
import { getTextSize } from '../Utils/Utils';

type Props = {
  round: RoundNumber;
  bottom: number;
  left?: number;
  right?: number;
  color?: string;
  anim?: FlattenSimpleInterpolation;
};

const FloatingTextAnchor = styled.p<Props & { res: number }>`
  position: absolute;
  z-index: ${ValhallaZIndex.Banners};
  font-weight: 400;
  width: fit-content;

  ${({ color }) => color && `color: ${color};`}

  margin: auto;

  ${({ bottom, res }) => `bottom: ${bottom * res}px;`}
  ${({ res, left }) => left !== undefined && `left: ${left * res}px;`}
  ${({ res, right }) => right !== undefined && `right: ${right * res}px;`}

  font-size: ${({ res }) => getTextSize(res)}px;

  overflow: visible;
`;

const FloatingTextContent = styled.span<{ anim?: FlattenSimpleInterpolation }>`
  ${({ anim }) => anim};
  position: relative;
`;

export function FloatingText({
  children,
  round,
  bottom,
  left,
  right,
  color,
  anim,
}: Props & { children: React.ReactNode }) {
  const res = useRes();
  const props = { round, bottom, left, right, res, color };

  return (
    <FloatingTextAnchor {...props}>
      <FloatingTextContent anim={anim}>{children}</FloatingTextContent>
    </FloatingTextAnchor>
  );
}
