import { decodePlanetFromJSON } from 'common-utils';
import { ValhallaPlanet } from 'common-types';
import React, { useCallback, useState } from 'react';
import { useFileRenderer, usePreviewRenderer } from '../Utils/AppHooks';
import { PlanetCanvas } from './PlanetCanvas';
import { FileType } from './PlanetUtils';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

if (window.localStorage.getItem('guid') === null) {
  window.localStorage.setItem('guid', uuidv4());
}

const rawWinners = require('../../Backend/planets.json');
const roundNumber = 5;

const planets: ValhallaPlanet[] = [];

for (const rawPlanetJSON of rawWinners) {
  const planet = decodePlanetFromJSON(rawPlanetJSON);
  if (planet.roundId === roundNumber) {
    planets.push(planet);
  }
}

export function PlanetRenderPage() {
  const [size, setSize] = useState(350); // 350 for webms, 500 for gifs / pngs
  const [guid, setGuid] = useState(window.localStorage.getItem('guid'));
  const [planet, setPlanet] = useState<ValhallaPlanet | undefined>();
  const { setter: previewSetter, renderer: _previewRenderer } = usePreviewRenderer(size, planet);
  const { setter: fileSetter, renderer: fileRenderer } = useFileRenderer(size, planet);

  const getAll = useCallback(
    (
      fileName: string,
      type: FileType,
      begin: number | undefined = undefined,
      end: number | undefined = undefined
    ) => {
      planets && fileRenderer?.getAll(fileName, type, planets, begin, end);
    },
    [fileRenderer, planets]
  );

  const changeGuid = useCallback(() => {
    window.localStorage.setItem('guid', uuidv4());
    setGuid(window.localStorage.getItem('guid'));
  }, []);

  return (
    <Container>
      <h3>Some Useful Links</h3>
      <ol>
        <li>
          <a href='https://s3.console.aws.amazon.com/s3/buckets/darkforest-valhalla?region=us-east-2&tab=objects'>
            https://s3.console.aws.amazon.com/s3/buckets/darkforest-valhalla?region=us-east-2{'&'}
            tab=objects
          </a>
        </li>
      </ol>
      <Button onClick={changeGuid}>Update Guid ({guid})</Button>
      <br />
      <br />
      Set Image Size:{' '}
      <input
        value={size}
        onChange={(e) => {
          const newValue = e.target.value;
          const parsedValue = parseFloat(newValue);
          if (isNaN(parsedValue)) {
            return;
          }
          setSize(Math.floor(parsedValue));
        }}
      />
      <br />
      <h3>Steps:</h3>
      The following steps are all necessary for generating all of the required assets for each
      valhalla planet.
      <br />
      <br />
      <ol>
        <li>
          <Button onClick={() => getAll('webms-' + guid, FileType.Webm)}>get webms</Button>
          <br />
          Generate all webms for all of the planets. You can follow the progress of this process in
          the developer console (applies to the rest of the steps as well). Due to some specific
          encoding options that we need (webms with transparency), this part only works in Chrome.
        </li>
        <li>
          <Button onClick={() => getAll('pngs-' + guid, FileType.Png)}>get pngs</Button>
          <br />
          Generate all pngs for all of the planets. The pngs are used as the initial image that is
          rendered on the valhalla webpage. The reason for this is that they are smaller than the
          gifs, which means they load faster, which means that the user can see something quickly,
          without having to load all the heavy gifs and webms.
        </li>
        <li>
          <Button onClick={() => getAll('gif frames (0-20)-' + guid, FileType.Gif, 0, 20)}>
            get gif frames (0 - 20)
          </Button>
          <br />
          This step, along with the next two, generates frames which can be stitched together via a
          CLI script into gifs. This is done as the final step of this process.
        </li>
        <li>
          <Button onClick={() => getAll('gif frames (20-40)-' + guid, FileType.Gif, 20, 40)}>
            get gif frames (20 - 40)
          </Button>
          <br />
          Same as above.
        </li>
        <li>
          <Button onClick={() => getAll('gif frames (40-end)', FileType.Gif, 40, planets?.length)}>
            get gif frames (40 - end)
          </Button>
          <br />
          Same as above.
        </li>
        <li>
          <pre>./scripts/finalize.sh {guid}</pre>
          <br />
          Run the above in the root directory of the valhalla project.
        </li>
      </ol>
      <h3>Preview Planet:</h3>
      {planets?.map((p: ValhallaPlanet) => (
        <ButtonRow key={p.id}>
          (round {p.roundId}, rank {p.rank}, level {p.level}) {p.id}{' '}
          <Button onClick={() => setPlanet(p)}>preview</Button>
          {[1, 3, 7, 15, 31, 63].includes(p.rank) && <br />}
        </ButtonRow>
      ))}
      <br />
      <br />
      <PlanetCanvas size={size} setter={previewSetter} />
      <PlanetCanvas size={size} setter={fileSetter} />
    </Container>
  );
}

const Button = styled.button`
  background-color: transparent;
  border: 1px solid #b3b3b3;
  color: #b3b3b3;
  margin: 2px;
  padding: 4px 8px;
  border-radius: 3px;
  user-select: none;

  &:hover {
    background-color: #b3b3b3;
    color: black;
    cursor: pointer;
  }
`;

const ButtonRow = styled.div`
  margin-bottom: 4px;
`;

const Container = styled.div`
  background-color: #000115;
  padding: 16px;
  color: #b3b3b3;
  min-height: 100vh;

  a {
    color: white;
    text-decoration: underline;
  }

  ol {
    list-style-type: decimal;
    list-style-position: inside;
  }

  li {
    margin-bottom: 32px;
    max-width: 600px;
  }
`;
