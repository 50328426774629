import React, { useState } from 'react';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import { useRes } from '../../Utils/AppHooks';
import { R2ImageProps } from '../Round2/R2ImageProps';
import { R1ImageProps } from '../Round1/R1ImageProps';
import { R3ImageProps } from '../Round3/R3ImageProps';
import {
  ImageProps,
  BoundingBox,
  BackgroundPlacement,
  FileName,
  ValhallaZIndex,
  RoundNumber,
  LoadedImageProps,
  R1FileName,
  R2FileName,
  R3FileName,
  R4FileName,
  R5FileName,
} from './ValhallaTypes';
import { getBottom, getUrl, loadImageProps } from '../../Utils/Utils';
import { R4ImageProps } from '../Round4/R4ImageProps';
import { R5ImageProps } from '../Round5/R5ImageProps';

// maps from url to image props
const ValhallaImageProps: Map<string, LoadedImageProps> = new Map();
loadImageProps<R1FileName>(ValhallaImageProps, R1ImageProps, RoundNumber.Round1);
loadImageProps<R2FileName>(ValhallaImageProps, R2ImageProps, RoundNumber.Round2);
loadImageProps<R3FileName>(ValhallaImageProps, R3ImageProps, RoundNumber.Round3);
loadImageProps<R4FileName>(ValhallaImageProps, R4ImageProps, RoundNumber.Round4);
loadImageProps<R5FileName>(ValhallaImageProps, R5ImageProps, RoundNumber.Round5);

export type ValhallaImageProps = {
  anim?: FlattenSimpleInterpolation;
  res: number;
} & LoadedImageProps;

function widthAndHeight({ imgWidth, imgHeight, boundingBox, res }: ValhallaImageProps) {
  if (boundingBox === BoundingBox.FillXFitY)
    return css`
      width: 100%;
      height: ${res * imgHeight}px;
    `;
  else if (boundingBox === BoundingBox.FitXFitY)
    return css`
      width: ${res * imgWidth}px;
      height: ${res * imgHeight}px;
    `;

  return;
}

function background({ placement }: ImageProps) {
  if (placement === BackgroundPlacement.Background)
    return css`
      background-position: bottom center;
      background-repeat: repeat-x;
    `;
  else if (placement === BackgroundPlacement.BottomCenterObject)
    return css`
      background-position: bottom center;
      background-repeat: no-repeat;
    `;
  else if (placement === BackgroundPlacement.HorizontalLoop)
    return css`
      background-position: bottom left 0;
      background-repeat: repeat-x;
    `;
  else if (placement === BackgroundPlacement.HorizontalLoopCenter)
    return css`
      background-position: bottom center;
      background-repeat: repeat-x;
    `;
  else if (placement === BackgroundPlacement.BottomLeftObject)
    return css`
      background-position: bottom left;
      background-repeat: no-repeat;
    `;
  else if (placement === BackgroundPlacement.BottomRightObject)
    return css`
      background-position: bottom right;
      background-repeat: no-repeat;
    `;
  return;
}

export const StyledValhallaImage = styled.div<ValhallaImageProps>`
  ${({ anim }) => anim}
  width: 100%;
  height: 100%;
  position: absolute;

  ${background}
  ${({ url }) => `background-image: url('${url}');`}
  ${({ imgWidth, imgHeight, res }) => `
    background-size: ${res * imgWidth}px ${res * imgHeight}px;
  `}
`;

// this one anchors onto the page
export const ValhallaImageAnchor = styled.div<ValhallaImageProps>`
  ${({ zIndex }) => `z-index: ${ValhallaZIndex.Background + zIndex};`}
  margin: auto;
  overflow: visible;
  ${widthAndHeight}
  ${({ position: p, res, round }) =>
    p
      ? css`
          position: absolute;
          bottom: ${res * getBottom(p.bottom, round)}px;
          ${p.left !== undefined && `left: ${res * p.left}px;`}
          ${p.right !== undefined && `right: ${res * p.right}px;`}
        `
      : 'position: relative;'}
`;

export const ValhallaImagePositioner = styled.div<ValhallaImageProps>`
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: visible;
`;

/* Anchor is the diagetic 'ground truth' position, Positioner moves
 the page position relative to that (parallax), Image carries the animation */

function ValhallaImageRaw(
  {
    fileName,
    round,
    anim,
  }: {
    fileName: FileName;
    round?: RoundNumber;
    anim?: FlattenSimpleInterpolation;
  },
  ref: React.RefObject<HTMLDivElement>
) {
  const res = useRes();
  const url = getUrl(fileName, round);
  const loadedProps = ValhallaImageProps.get(url);
  if (!loadedProps) return <></>;

  const imageProps = { fileName, ...loadedProps, res, round, anim };

  return (
    <ValhallaImageAnchor {...imageProps}>
      <ValhallaImagePositioner {...imageProps} ref={ref}>
        <StyledValhallaImage {...imageProps} />
      </ValhallaImagePositioner>
    </ValhallaImageAnchor>
  );
}

export const ValhallaImage = React.forwardRef(ValhallaImageRaw);

export const Round1Image = React.forwardRef(function Round1(
  { fileName, anim }: { fileName: FileName; anim?: FlattenSimpleInterpolation },
  ref: React.RefObject<HTMLDivElement>
) {
  return <ValhallaImage ref={ref} fileName={fileName} anim={anim} round={RoundNumber.Round1} />;
});

export const RoundImage = React.forwardRef(function Round(
  {
    fileName,
    anim,
    round,
  }: { fileName: FileName; anim?: FlattenSimpleInterpolation; round: RoundNumber },
  ref: React.RefObject<HTMLDivElement>
) {
  return <ValhallaImage ref={ref} fileName={fileName} anim={anim} round={round} />;
});
