import React from 'react';
import { R3Planet } from '../ValhallaGlobal/ValhallaPagePlanet';
import { PlanetLevel } from '../ValhallaGlobal/ValhallaTypes';

export function R3Planets() {
  return (
    <div>
      <>
        <>
          <R3Planet rank={63} bottom={2775 - 1100} left={105} level={PlanetLevel.Level2} />
          <R3Planet rank={62} bottom={2890 - 1100} left={300} level={PlanetLevel.Level2} />
          <R3Planet rank={61} bottom={3000 - 1100} left={100} level={PlanetLevel.Level2} />
          <R3Planet rank={60} bottom={3140 - 1100} left={250} level={PlanetLevel.Level2} />

          <R3Planet rank={59} bottom={3040 - 1100} left={420} level={PlanetLevel.Level2} />
          <R3Planet rank={58} bottom={2860 - 1100} left={525} level={PlanetLevel.Level2} />
          <R3Planet rank={57} bottom={3390 - 1200} left={100} level={PlanetLevel.Level2} />
          <R3Planet rank={56} bottom={3370 - 1300} left={400} level={PlanetLevel.Level2} />

          <R3Planet rank={55} bottom={3420 - 1200} left={450} level={PlanetLevel.Level2} />
          <R3Planet rank={54} bottom={3470 - 1200} left={280} level={PlanetLevel.Level2} />
          <R3Planet rank={53} bottom={3630 - 1200} left={100} level={PlanetLevel.Level2} />
          <R3Planet rank={52} bottom={3650 - 1200} left={510} level={PlanetLevel.Level2} />

          <R3Planet rank={51} bottom={3645 - 1200} left={350} level={PlanetLevel.Level2} />
          <R3Planet rank={50} bottom={3820 - 1200} left={220} level={PlanetLevel.Level2} />
          <R3Planet rank={49} bottom={3810 - 1200} left={480} level={PlanetLevel.Level2} />
          <R3Planet rank={48} bottom={3995 - 1150} left={460} level={PlanetLevel.Level2} />

          <R3Planet rank={47} bottom={4040 - 1200} left={270} level={PlanetLevel.Level2} />
          <R3Planet rank={46} bottom={3995 - 1200} left={80} level={PlanetLevel.Level2} />
          <R3Planet rank={45} bottom={4040 - 1300} left={430} level={PlanetLevel.Level2} />
          <R3Planet rank={44} bottom={4140 - 1200} left={200} level={PlanetLevel.Level2} />

          <R3Planet rank={43} bottom={4510 - 1400} left={350} level={PlanetLevel.Level2} />
          <R3Planet rank={42} bottom={4570 - 1500} left={120} level={PlanetLevel.Level2} />
          <R3Planet rank={41} bottom={4650 - 1400} left={300} level={PlanetLevel.Level2} />
          <R3Planet rank={40} bottom={4710 - 1400} left={520} level={PlanetLevel.Level2} />

          <R3Planet rank={39} bottom={4810 - 1650} left={150} level={PlanetLevel.Level2} />
          <R3Planet rank={38} bottom={5200 - 1800} left={300} level={PlanetLevel.Level2} />
          <R3Planet rank={37} bottom={5150 - 1800} left={100} level={PlanetLevel.Level2} />
          <R3Planet rank={36} bottom={5070 - 1800} left={420} level={PlanetLevel.Level2} />

          <R3Planet rank={35} bottom={5530 - 2000} left={265} level={PlanetLevel.Level2} />
          <R3Planet rank={34} bottom={5540 - 2100} left={510} level={PlanetLevel.Level2} />
          <R3Planet rank={33} bottom={5690 - 2200} left={100} level={PlanetLevel.Level2} />
          <R3Planet rank={32} bottom={5690 - 2050} left={350} level={PlanetLevel.Level2} />
        </>
        <>
          <R3Planet rank={31} bottom={5690 - 1800} left={200} level={PlanetLevel.Level3} />
          <R3Planet rank={30} bottom={5690 - 1700} left={100} level={PlanetLevel.Level3} />
          <R3Planet rank={29} bottom={5690 - 1600} left={210} level={PlanetLevel.Level3} />
          <R3Planet rank={28} bottom={5690 - 1600} left={540} level={PlanetLevel.Level3} />

          <R3Planet rank={27} bottom={5690 - 1400} left={140} level={PlanetLevel.Level3} />
          <R3Planet rank={26} bottom={5690 - 1300} left={340} level={PlanetLevel.Level3} />
          <R3Planet rank={25} bottom={5690 - 1200} left={170} level={PlanetLevel.Level3} />
          <R3Planet rank={24} bottom={5690 - 1100} left={490} level={PlanetLevel.Level3} />

          <R3Planet rank={23} bottom={5690 - 1000} left={310} level={PlanetLevel.Level3} />
          <R3Planet rank={22} bottom={5690 - 900} left={100} level={PlanetLevel.Level3} />
          <R3Planet rank={21} bottom={5690 - 800} left={250} level={PlanetLevel.Level3} />
          <R3Planet rank={20} bottom={5690 - 850} left={360} level={PlanetLevel.Level3} />

          <R3Planet rank={19} bottom={5690 - 700} left={170} level={PlanetLevel.Level3} />
          <R3Planet rank={18} bottom={5690 - 700} left={470} level={PlanetLevel.Level3} />
          <R3Planet rank={17} bottom={5690 - 600} left={380} level={PlanetLevel.Level3} />
          <R3Planet rank={16} bottom={5690 - 540} left={200} level={PlanetLevel.Level3} />
        </>
        <>
          <R3Planet rank={15} bottom={5690} left={110} level={PlanetLevel.Level4} />
          <R3Planet rank={14} bottom={5690 + 200} left={280} level={PlanetLevel.Level4} />
          <R3Planet rank={13} bottom={5690 + 400} left={350} level={PlanetLevel.Level4} />
          <R3Planet rank={12} bottom={5690 + 800} left={200} level={PlanetLevel.Level4} />

          <R3Planet rank={11} bottom={5690 + 1200} left={380} level={PlanetLevel.Level4} />
          <R3Planet rank={10} bottom={5690 + 1400} left={120} level={PlanetLevel.Level4} />
          <R3Planet rank={9} bottom={5690 + 1600} left={460} level={PlanetLevel.Level4} />
          <R3Planet rank={8} bottom={5690 + 1800} left={190} level={PlanetLevel.Level4} />
        </>
        <>
          <R3Planet rank={7} bottom={5690 + 2400} left={400} level={PlanetLevel.Level5} />
          <R3Planet rank={6} bottom={5690 + 2800} left={100} level={PlanetLevel.Level5} />
          <R3Planet rank={5} bottom={5690 + 3200} left={180} level={PlanetLevel.Level5} />
          <R3Planet rank={4} bottom={5690 + 3600} left={480} level={PlanetLevel.Level5} />
        </>

        {/* Lv 6-7 */}
        <>
          <R3Planet rank={3} bottom={5690 + 5600} left={100} level={PlanetLevel.Level6} />
          <R3Planet rank={2} bottom={5690 + 5600} left={400} level={PlanetLevel.Level6} />
        </>

        {/* Special prize winners */}
        <>
          <R3Planet
            originalWinner={'0x77d217908cd66cb3198bb94cbf46eb7fa5c9b62a'}
            bottom={12260}
            left={350}
            level={PlanetLevel.Level2}
          />

          <R3Planet
            originalWinner={'0xaa23208770da9ae738a7a5069bcdfdc06e487821'}
            bottom={12360}
            left={200}
            level={PlanetLevel.Level2}
          />

          <R3Planet
            originalWinner={'0x45bdf8b10aeb8b00ccf23b86f3d435eb52e973e0'}
            bottom={12470}
            left={150}
            level={PlanetLevel.Level2}
          />

          <R3Planet
            originalWinner={'0x102e277c34668e96cbed6169fa1195002c11d746'}
            bottom={12500}
            left={350}
            level={PlanetLevel.Level2}
          />

          <R3Planet
            originalWinner={'0x1f3440cda4bdd6f22aac145e24d604cf05f3cc3b'}
            bottom={12560}
            left={50}
            level={PlanetLevel.Level2}
          />

          <R3Planet
            originalWinner={'0x217F000e3fa0b5C237a257747760DfD40C479A49'}
            bottom={12660}
            left={100}
            level={PlanetLevel.Level2}
          />

          <R3Planet
            originalWinner={'0x8dc13e92246b9e9a494173f28b07262b30cc545c'}
            bottom={12760}
            left={380}
            level={PlanetLevel.Level2}
          />

          <R3Planet
            originalWinner={'0xe8d3dd97cd3a33b7b8f94e3195e98d3912ac50e9'}
            bottom={12760}
            left={80}
            level={PlanetLevel.Level2}
          />

          <R3Planet
            originalWinner={'0xdead080e55b6918788d33959774c19ff7e914367'}
            bottom={12850}
            left={150}
            level={PlanetLevel.Level2}
          />

          <R3Planet
            originalWinner={'0xdeadeE24fF359b01B1B64312e3DFC42B8CCa8780'}
            bottom={12600}
            left={400}
            level={PlanetLevel.Level2}
          />

          <R3Planet
            originalWinner={'0x9029CCc6563a8f2b1FA70e6265D90398061cB7A9'}
            bottom={12850}
            left={400}
            level={PlanetLevel.Level2}
          />

          <R3Planet
            originalWinner={'0xa329de77762942833dafde7eecb176d74cb9101d'}
            bottom={12600}
            left={200}
            level={PlanetLevel.Level2}
          />

          <R3Planet
            originalWinner={'0x96397c50f6ecb858d35302cde983eaa230464c34'}
            bottom={12720}
            left={200}
            level={PlanetLevel.Level2}
          />

          {/* <!-- --> */}

          <R3Planet
            originalWinner={'0x4b7b6d3dc60adbfd35271bcdfacc5fca5e3a2107'}
            bottom={13600}
            left={150}
            level={PlanetLevel.Level3}
          />
          <R3Planet
            originalWinner={'0x7699d691234245f9cf7f332ec7875676bde9169e'}
            bottom={12960}
            left={150}
            level={PlanetLevel.Level3}
          />
          <R3Planet
            originalWinner={'0x9098628DE1221b1BD3a6059A64168828414a74bB'}
            bottom={13060}
            left={70}
            level={PlanetLevel.Level3}
          />
          <R3Planet
            originalWinner={'0x5b49b09650b050ec36454e310bce67073fe8048e'}
            bottom={13060}
            left={250}
            level={PlanetLevel.Level3}
          />

          <R3Planet
            originalWinner={'0x92e3487b40f53e1cf3af901aac00451c9a745806'}
            bottom={12960}
            left={400}
            level={PlanetLevel.Level3}
          />
          <R3Planet
            originalWinner={'0x0797846bdb85e3303ad745e9d4e7d563a8ca1702'}
            bottom={13160}
            left={400}
            level={PlanetLevel.Level3}
          />
          <R3Planet
            originalWinner={'0xe0a0a42de89c695cffee76c50c3da710bb22c112'}
            bottom={13280}
            left={220}
            level={PlanetLevel.Level3}
          />
          <R3Planet
            originalWinner={'0x0b652c646678ca09487435d8f329836fbc2ec6e1'}
            bottom={13150}
            left={220}
            level={PlanetLevel.Level3}
          />
          <R3Planet
            originalWinner={'0x4750950cc2878e7441ecf6882e2ed2e487e938c2'}
            bottom={13460}
            left={400}
            level={PlanetLevel.Level3}
          />
          <R3Planet
            originalWinner={'0x4ddd223e5bc88422ae047cc57d4198f68519e42b'}
            bottom={13430}
            left={150}
            level={PlanetLevel.Level3}
          />
          <R3Planet
            originalWinner={'0xdead903dd63d7019e5feb98802afc925e8bdcfef'}
            bottom={13850}
            left={100}
            level={PlanetLevel.Level4}
          />
          <R3Planet
            originalWinner={'0xb5ce86c2ab9e2403ab47acfbe501845e2480fad9'}
            bottom={13750}
            left={250}
            level={PlanetLevel.Level4}
          />
          <R3Planet
            originalWinner={'0xe9de7e3b83de99005f4df810a346a2ed9a07f4c1'}
            bottom={13820}
            left={400}
            level={PlanetLevel.Level4}
          />
        </>
        <>
          <R3Planet rank={1} bottom={15800} left={300} level={PlanetLevel.Level7} centered />
        </>
      </>
    </div>
  );
}
