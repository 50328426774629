import { PlanetId } from 'common-types';

export const getRandomActionId = () => {
  const hex = '0123456789abcdef';

  let ret = '';
  for (let i = 0; i < 10; i += 1) {
    ret += hex[Math.floor(hex.length * Math.random())];
  }
  return ret;
};

export enum EthConnectionType {
  DEFAULT_RPC,
  METAMASK,
}

export enum EthTxType {
  CLAIM_WINNER = 'CLAIM_WINNER',
  CLAIM_SPECIAL = 'CLAIM_SPECIAL',
  TRANSFER_PLANET = 'TRANSFER_PLANET',
}

export type TxIntent = {
  actionId: string;
  type: EthTxType;
};

export type SubmittedTx = TxIntent & {
  txHash: string;
};

export function isSubmitted(txIntent: TxIntent): txIntent is SubmittedTx {
  return (txIntent as SubmittedTx).txHash !== undefined;
}

export type UnconfirmedClaimWinner = TxIntent & {
  type: EthTxType.CLAIM_WINNER;
  tokenId: PlanetId;
  roundId: number;
  rank: number;
};

export type SubmittedClaimWinner = UnconfirmedClaimWinner & SubmittedTx;

export function isClaimWinnerTx(txIntent: TxIntent): txIntent is UnconfirmedClaimWinner {
  return txIntent.type === EthTxType.CLAIM_WINNER;
}

export type UnconfirmedClaimSpecial = TxIntent & {
  type: EthTxType.CLAIM_SPECIAL;
  tokenId: PlanetId;
  roundId: number;
  level: number;
};

export type SubmittedClaimSpecial = UnconfirmedClaimSpecial & SubmittedTx;

export function isClaimSpecialTx(txIntent: TxIntent): txIntent is UnconfirmedClaimSpecial {
  return txIntent.type === EthTxType.CLAIM_SPECIAL;
}

export type UnconfirmedTransferPlanet = TxIntent & {
  type: EthTxType.TRANSFER_PLANET;
  tokenId: PlanetId;
  receiverAddr: string;
};

export type SubmittedTransferPlanet = UnconfirmedTransferPlanet & SubmittedTx;

export function isTransferPlanetTx(txIntent: TxIntent): txIntent is UnconfirmedTransferPlanet {
  return txIntent.type === EthTxType.TRANSFER_PLANET;
}
