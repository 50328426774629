import React from 'react';
import { hot } from 'react-hot-loader/root';
import { Switch, Route, HashRouter } from 'react-router-dom';
import { PlanetRenderPage } from '../Planet/PlanetRenderPage';
import { SelectRoundPage } from '../SelectRoundPage';
import { LandingPage } from './LandingPage';
import { RoundNumber } from './ValhallaGlobal/ValhallaTypes';

const isProd = process.env.NODE_ENV === 'production';

function App() {
  return (
    <HashRouter>
      <Switch>
        <Route path='/' exact component={SelectRoundPage} />
        <Route path='/round-1' exact component={() => <LandingPage round={RoundNumber.Round1} />} />
        <Route path='/round-2' exact component={() => <LandingPage round={RoundNumber.Round2} />} />
        <Route path='/round-3' exact component={() => <LandingPage round={RoundNumber.Round3} />} />
        <Route path='/round-4' exact component={() => <LandingPage round={RoundNumber.Round4} />} />
        <Route path='/round-5' exact component={() => <LandingPage round={RoundNumber.Round5} />} />
        {!isProd && <Route path='/planet' component={PlanetRenderPage} />}
      </Switch>
    </HashRouter>
  );
}

export default hot(App);
