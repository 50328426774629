import React, { useEffect, useState } from 'react';
import { useAnimManager, useParallax, useRes } from '../../Utils/AppHooks';
import { bobAnim, panXAnim, tiltAnim } from '../../Utils/CSSAnims';
import { scrollTopViewCenter } from '../../Utils/Utils';
import { Round1Image } from '../ValhallaGlobal/ValhallaImage';
import { R1FileName } from '../ValhallaGlobal/ValhallaTypes';
import { ASTEROID_MIDDLE, SPACESHIP_BOTTOM } from './R1ImageProps';

const Background = () => <Round1Image fileName={R1FileName.Background} />;

function GroundObjs() {
  return (
    <>
      <Round1Image fileName={R1FileName.Petals} />

      <Round1Image fileName={R1FileName.Sun} anim={bobAnim(7, 2)} />
      <Round1Image fileName={R1FileName.Grass} />
      <>
        <Round1Image fileName={R1FileName.Flowers_FG_L} />
        <Round1Image fileName={R1FileName.Flowers_FG_R} />
      </>
      <Round1Image fileName={R1FileName.Tree} />
      <>
        <Round1Image fileName={R1FileName.Flowers_MG} />
        <Round1Image fileName={R1FileName.Flowers_BG} />
      </>
    </>
  );
}

function CloudsGround() {
  const res = useRes();
  const anim = (dur: number) => panXAnim(res, 640, dur);

  return (
    <>
      <Round1Image fileName={R1FileName.Clouds_Ground_1} anim={anim(10)} />
      <Round1Image fileName={R1FileName.Clouds_Ground_2} anim={anim(15)} />
      <Round1Image fileName={R1FileName.Clouds_Ground_3} anim={anim(20)} />
    </>
  );
}

function BackgroundObjs() {
  const manager = useAnimManager();
  const res = useRes();

  const spaceshipP = useParallax(manager, res, SPACESHIP_BOTTOM, 1);
  const satelliteP = useParallax(manager, res, 2169, 0.2);
  const moonP = useParallax(manager, res, 4028, 0.1);
  const ufoP = useParallax(manager, res, 4300, 0.3);

  return (
    <>
      <Round1Image fileName={R1FileName.Spaceship} ref={spaceshipP} />
      <Round1Image fileName={R1FileName.Satellite} anim={bobAnim(15, 3)} ref={satelliteP} />
      <Round1Image fileName={R1FileName.Moon} ref={moonP} />
      <Round1Image fileName={R1FileName.UFO} anim={tiltAnim(15, 1)} ref={ufoP} />
    </>
  );
}

function GemsAsteroids() {
  const manager = useAnimManager();
  const res = useRes();

  const setter1 = useParallax(manager, res, ASTEROID_MIDDLE, 0.5);
  const setter2 = useParallax(manager, res, ASTEROID_MIDDLE, 0.8);
  const setter3 = useParallax(manager, res, ASTEROID_MIDDLE, 1.3);
  const setter4 = useParallax(manager, res, ASTEROID_MIDDLE, 2.2);
  return (
    <>
      <Round1Image fileName={R1FileName.Gems_1} ref={setter1} />
      <Round1Image fileName={R1FileName.Gems_2} ref={setter2} />
      <Round1Image fileName={R1FileName.Gems_3} ref={setter3} />
      <Round1Image fileName={R1FileName.Asteroids} ref={setter4} />
    </>
  );
}

function Galaxies() {
  return (
    <>
      <Round1Image fileName={R1FileName.Galaxy_1} />
      <Round1Image fileName={R1FileName.Galaxy_2} />
      <Round1Image fileName={R1FileName.Galaxy_3} />
    </>
  );
}

function AngelsFeathers() {
  const manager = useAnimManager();
  const res = useRes();

  const leftP = useParallax(manager, res, 7778, 0.4);
  const rightP = useParallax(manager, res, 7925, 0.6);
  const topP = useParallax(manager, res, 8160, 0.2);
  return (
    <>
      <Round1Image fileName={R1FileName.Feathers} />
      <>
        <Round1Image fileName={R1FileName.AngelLeft} ref={leftP} />
        <Round1Image fileName={R1FileName.AngelRight} ref={rightP} anim={bobAnim(14, 1.7)} />
        <Round1Image fileName={R1FileName.AngelTop} ref={topP} anim={bobAnim(12, 1.3)} />
      </>
    </>
  );
}

enum FlowerState {
  Start,
  Blooming,
  Bloomed,
}

function Flower() {
  const [animState, setAnimState] = useState<FlowerState>(FlowerState.Start);
  const res = useRes();
  const SCROLL_TRIGGER = 730;
  const BLOOM_LEN = 2170;
  const animManager = useAnimManager();

  useEffect(() => {
    let completed = false;
    animManager.addScrollHandler(() => {
      if (scrollTopViewCenter() < SCROLL_TRIGGER * res && !completed) {
        setAnimState(FlowerState.Blooming);
        setTimeout(() => {
          setAnimState(FlowerState.Bloomed);
        }, BLOOM_LEN);
        completed = true;
      }
    });
  }, [animManager, setAnimState, res]);

  return (
    <>
      {animState === FlowerState.Start && <Round1Image fileName={R1FileName.Flower_Start} />}
      {animState === FlowerState.Blooming && <Round1Image fileName={R1FileName.Flower_Blooming} />}
      {animState === FlowerState.Bloomed && <Round1Image fileName={R1FileName.Flower_Bloomed} />}
    </>
  );
}

function CloudsHeaven() {
  const res = useRes();
  const anim = (dur: number) => panXAnim(res, 640, dur);

  return (
    <>
      <Flower />
      <>
        <Round1Image fileName={R1FileName.Clouds_Top_L} />
        <Round1Image fileName={R1FileName.Clouds_Top_R} />
      </>
      <>
        <Round1Image fileName={R1FileName.Clouds_Heaven_1} anim={anim(20)} />
        <Round1Image fileName={R1FileName.Clouds_Heaven_2} anim={anim(15)} />
        <Round1Image fileName={R1FileName.Clouds_Heaven_3} anim={anim(10)} />
      </>
    </>
  );
}

export function R1Images() {
  return (
    <>
      <Background />
      <GroundObjs />
      <CloudsGround />
      <BackgroundObjs />
      <GemsAsteroids />
      <Galaxies />
      <AngelsFeathers />
      <CloudsHeaven />
    </>
  );
}
