import autoBind from 'auto-bind';
import { scrollBotViewCenter } from './Utils';

// export type Handler = (frameCount: number) => void;

/**
 * Create a `ScrollHandler` that sets the position of a div with fancy parallax
 * @param div div whose position to set
 * @param anchorBot `top: 0` the center of the viewport points at this point (measured from the bottom)
 * @param velocity units to move with each pixel of scroll movement
 * @param res global resolution
 */
export function makeParallaxHandler(
  div: HTMLDivElement,
  res: number,
  anchorBot: number,
  velocity: number
): ScrollHandler {
  return () => {
    if (document.documentElement.scrollHeight < 100) {
      div.style.top = `0`;
      return;
    }

    const diff = scrollBotViewCenter() - anchorBot * res;

    div.style.top = diff * velocity + `px`;
  };
}

export type ScrollHandler = () => void;
export type RemoveHandler = () => void;

export class AnimationManager {
  scrollHandlers: Set<ScrollHandler>;
  frameRequestId: number;

  frameCount = 0;

  constructor() {
    autoBind(this);
    this.scrollHandlers = new Set<ScrollHandler>();
    document.addEventListener('scroll', this.onScroll);
    document.addEventListener('resize', this.onScroll);
  }

  private onScroll(_e: Event) {
    this.scrollHandlers.forEach((handler) => handler());
  }

  public addScrollHandler(handler: ScrollHandler): RemoveHandler {
    this.scrollHandlers.add(handler);

    return () => this.scrollHandlers.delete(handler);
  }

  public destroy() {
    // window.cancelAnimationFrame(this.frameRequestId);
    document.removeEventListener('scroll', this.onScroll);
    document.removeEventListener('resize', this.onScroll);
  }
}
