import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import DataManager from '../../Backend/DataManager';
import { RoundNumber, roundNumberNames } from '../Pages/ValhallaGlobal/ValhallaTypes';
import { useLoaderManager } from '../Utils/AppHooks';
import { BACKGROUND_COLOR, TEXT_COLOR } from '../Utils/consts';
import { useEmitterSubscribe } from '../Utils/EmitterHooks';
import { LoadProgress } from '../Utils/ImageLoader';
import { LoadingSpinner } from './LoadingSpinner';

export const LOAD_DUR = 2.5;

const StyledLoadingPage = styled.div<{ loaded: boolean; destroySelf: boolean }>`
  pointer-events: none;

  ${({ destroySelf }) => destroySelf && `display: none;`}

  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;

  width: 100vw;
  height: 100vh;

  transition: opacity ${LOAD_DUR}s;
  opacity: ${({ loaded }) => (loaded ? '0' : '1')};
  background: ${BACKGROUND_COLOR};
  /* background: red; */

  color: ${TEXT_COLOR};

  & > div {
    text-align: center;
    max-width: 30em;
    max-height: 16em;
    position: absolute;
    margin: auto;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
  }

  p {
    margin: 0.25em 0;
  }
`;

type LoadingBars = {
  [loaderId: string]: {
    length: number;
    current: number;
  };
};

const NBSP = '\xa0';

export function LoadingPage({
  dataManager,
  loaded,
  round,
  loadingError,
}: {
  dataManager: DataManager | undefined;
  loaded: boolean;
  round: RoundNumber;
  loadingError: string | undefined;
}) {
  const loaderManager = useLoaderManager();

  let topLine;
  if (loadingError) {
    topLine = <p>{`${loadingError}`}</p>;
  } else if (!dataManager)
    topLine = (
      <p>
        <LoadingSpinner initialText='loading...' />
      </p>
    );
  else {
    topLine = (
      <p>
        <LoadingSpinner initialText='loading assets...' />
      </p>
    );
  }

  const [loadingBars, setLoadingBars] = useState<LoadingBars>({});

  const updateLoadingBars = useCallback(
    (progress: LoadProgress) => {
      setLoadingBars((bars) => {
        bars[progress.loaderId] = {
          length: progress.length,
          current: progress.current,
        };
        return { ...bars };
      });
    },
    [setLoadingBars]
  );
  useEmitterSubscribe(loaderManager.loadedImage$, updateLoadingBars);

  const [destroySelf, setDestroySelf] = useState<boolean>(false);
  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout> | undefined;

    if (loaded) {
      timeout = setTimeout(() => setDestroySelf(true), 3000);
    }

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [loaded]);

  return (
    <StyledLoadingPage loaded={loaded} destroySelf={destroySelf}>
      <div>
        <p>DARK FOREST v0.6 Valhalla {roundNumberNames[round]}</p> <br />
        {topLine}
        <br />
        {Object.keys(loadingBars)
          .sort()
          .map((loaderId: string) => {
            const { current, length } = loadingBars[loaderId];
            const pct = current / length;
            const total = 20;
            const num = Math.min(Math.round(pct * total), total);
            return (
              <p key={loaderId}>
                {`${loaderId}:`.padEnd(14, NBSP)} [{'='.repeat(num)}
                {NBSP.repeat(total - num)}]
              </p>
            );
          })}
      </div>
    </StyledLoadingPage>
  );
}
