import { useEffect } from 'react';
import { R1ImageProps } from '../Pages/Round1/R1ImageProps';
import {
  FileName,
  ImageProps,
  LoadedImageProps,
  R1FileName,
  RoundNumber,
} from '../Pages/ValhallaGlobal/ValhallaTypes';
import { IMAGE_URL } from './consts';

// scroll to bottom, so that our users can scroll up!
export function useScrollToBottomOnFirstInit(div: HTMLDivElement | null) {
  console.log('hit the hook!', div);
  useEffect(() => {
    if (!div) return;
    console.log('made it inside the function');

    function doScroll() {
      if (div && div.offsetHeight >= window.innerHeight) {
        console.log('scrolling to:', div.offsetHeight);
        window.scrollTo(0, document.body.scrollHeight);
      } else {
        setTimeout(doScroll, 100);
      }
    }

    setTimeout(doScroll, 100);
  }, [div]);
}

export function getUrl(s: string, round: RoundNumber | undefined = undefined): string {
  return `${IMAGE_URL}/${round ? round + '/' : ''}${s}`;
}

// https://stackoverflow.com/questions/13896407/x-y-positions-of-viewports-right-bottom-edges-respectively
const body = document.body;
const html = document.documentElement;
export const docHeight = () =>
  Math.max(
    body.scrollHeight,
    body.offsetHeight,
    html.clientHeight,
    html.scrollHeight,
    html.offsetHeight
  );

const scroll = () => document.documentElement.scrollTop || document.body.scrollTop;

/** Get the position of the top of the viewport relative to document `top` */
export const scrollTopViewTop = () => scroll();
/** Get the position of the center of the viewport relative to document `top` */
export const scrollTopViewCenter = () => scroll() + window.innerHeight / 2;

export const scrollBotViewTop = () => docHeight() - scroll();
export const scrollBotViewCenter = () => docHeight() - scroll() - window.innerHeight / 2;
export const scrollBotViewBottom = () => docHeight() - scroll() - window.innerHeight;

// @ts-ignore
window['s'] = {
  docHeight,
  scrollTopViewTop,
  scrollTopViewCenter,
  scrollBotViewTop,
  scrollBotViewCenter,
  scrollBotViewBottom,
};

/** Loads the `roundImageProps` object into a map from image url to `LoadedImageProps` */
export function loadImageProps<T extends Partial<FileName>>(
  map: Map<string, LoadedImageProps>,
  roundImageProps: Record<T, ImageProps>,
  round: RoundNumber
) {
  for (const k in roundImageProps) {
    const key = k as T;
    const url = getUrl(k, round);
    if (map.get(url)) {
      console.error('duplicate url!');
      return;
    }

    const props = roundImageProps[key];
    map.set(url, {
      ...props,
      url,
      round,
    });
  }
}

export function compareAddresses(addr1: string | undefined, addr2: string | undefined) {
  return !!addr1 && !!addr2 && addr1.toLowerCase() === addr2.toLowerCase();
}

export const getTextSize = (res: number) => Math.max(res * 10, 10);

const Bottoms: Record<RoundNumber, number> = {
  [RoundNumber.Round1]: 0,
  [RoundNumber.Round2]: 0,
  [RoundNumber.Round3]: 0,
  [RoundNumber.Round4]: 0,
  [RoundNumber.Round5]: 0,
};

export function getBottom(b: number, round: RoundNumber | undefined) {
  return (round ? Bottoms[round] : 0) + b;
}
