import autoBind from 'auto-bind';
import { ValhallaPlanet } from 'common-types';
import { planetFileName } from 'common-utils';
import JSZip from 'jszip';
import { MAX_FRAMES, PlanetRenderer } from './PlanetRenderer';
import { FileType } from './PlanetUtils';
const FileSaver = require('file-saver');

/*
To encode gifs with alpha: 
https://stackoverflow.com/questions/42146396/capture-video-with-alpha-channel-using-canvas-capturestream

ffmpeg -i in.webm -c:v libvpx -vf "chromakey=0x00ff00:0.1:0.1,format=yuva420p" -auto-alt-ref 0 out.webm
*/

export class FileRenderer extends PlanetRenderer {
  constructor(canvas: HTMLCanvasElement, size: number) {
    super(canvas, size, 0x332b31, 1);
    autoBind(this);
  }

  private getBase64(): string {
    const b64 = this.canvas.toDataURL('image/png').replace(/^data:image\/(png|jpg);base64,/, '');
    return b64;
  }

  private addSprite(dir: JSZip, planet: ValhallaPlanet) {
    this.setPlanet(planet);
    this.moveToFrame(0);
    this.render();

    const fileName = planetFileName(planet) + '.' + FileType.Png;
    dir.file(fileName, this.getBase64(), { base64: true });
  }

  private async addGif(dir: JSZip, planet: ValhallaPlanet) {
    this.setPlanet(planet);
    const planetName = planetFileName(planet);

    return new Promise<void>((resolve) => {
      for (let i = 0; i < MAX_FRAMES; i += 2) {
        if (i % 120 === 0) console.log(`saved ${i} frames!`);
        this.moveToFrame(i);
        this.render();

        dir.file(`${planetName}/frame${i.toString().padStart(3, '0')}.png`, this.getBase64(), {
          base64: true,
        });
      }
      resolve();
    });
  }

  private async addVideo(dir: JSZip, planet: ValhallaPlanet) {
    this.setPlanet(planet);
    const fileName = planetFileName(planet) + '.' + FileType.Webm;

    const capturer = new window.CCapture({
      format: 'webm',
      framerate: 30,
      quality: 0.999,
    });
    capturer.start();

    for (let i = 0; i < MAX_FRAMES; i += 2) {
      if (i % 120 === 0) console.log(`saved ${i} frames!`);
      this.moveToFrame(i);
      this.render();
      capturer.capture(this.canvas);
    }

    capturer.stop();
    return new Promise<void>((resolve) => {
      capturer.save((blob: Blob) => {
        dir.file(fileName, blob);
        resolve();
      });
    });
  }

  public async getAll(
    name: string,
    mode: FileType,
    planets: ValhallaPlanet[],
    sliceBegin: number | undefined = undefined,
    sliceEnd: number | undefined = undefined
  ) {
    this.setClearAlpha(mode === FileType.Webm ? 1 : 0);

    const zip = new JSZip();
    zip.folder('img');
    const dir = zip.folder('img');
    if (!dir) {
      console.error('jszip error');
      return;
    }

    const arr = planets.slice(sliceBegin, sliceEnd);

    for (const planet of arr) {
      if (mode === FileType.Webm) await this.addVideo(dir, planet);
      else if (mode === FileType.Png) await this.addSprite(dir, planet);
      else if (mode === FileType.Gif) await this.addGif(dir, planet);
      console.log(`saved ${planetFileName(planet)}!`, planet);
    }

    console.log('saving zip file now!');

    let lastPercent = 0;
    zip
      .generateAsync({ type: 'blob', compression: 'STORE' }, (metadata) => {
        if (metadata.percent > lastPercent + 1) {
          console.log('progression: ' + metadata.percent.toFixed(2) + ' %');
          if (metadata.currentFile) {
            console.log('current file = ' + metadata.currentFile);
          }

          lastPercent += 1;
        }
      })
      .then((content) => {
        FileSaver.saveAs(content, name + '.zip');
      });
  }
}
