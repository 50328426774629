import { css, keyframes } from 'styled-components';

const panX = (res: number, width: number) => keyframes`
  from {
    background-position: bottom left 0;
  } 
  to { 
    background-position: bottom left ${res * width}px;
  }
`;

export const panXAnim = (res: number, width = 640, dur = 2) => css`
  animation: ${panX(width, res)} ${dur}s linear infinite;
`;

const bob = (height: number) => keyframes`
  from {
    bottom: -${height}px;
  }
  to {
    bottom: ${height}px;
  }
`;

export const bobAnim = (amount: number, dur = 2) => css`
  animation: ${bob(amount)} ${dur}s ease-in-out infinite alternate;
`;

const tilt = (angle: number) => keyframes`
  from {
    transform: rotate(-${angle}deg);
  }
  to {
    transform: rotate(${angle}deg);
  }
`;

export const tiltAnim = (angle: number, dur = 2) => css`
  animation: ${tilt(angle)} ${dur}s ease-in-out infinite alternate;
`;
