// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "./perfect_dos_vga_437-webfont.woff2";
import ___CSS_LOADER_URL_IMPORT_1___ from "./perfect_dos_vga_437-webfont.woff";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on July 30, 2020 */\n\n@font-face {\n  font-family: 'perfect_dos_vga_437regular';\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format('woff2'),\n    url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format('woff');\n  font-weight: normal;\n  font-style: normal;\n}\n", "",{"version":3,"sources":["webpack://./src/Frontend/Styles/font/stylesheet.css"],"names":[],"mappings":"AAAA,gFAAgF;;AAEhF;EACE,yCAAyC;EACzC;0DACwD;EACxD,mBAAmB;EACnB,kBAAkB;AACpB","sourcesContent":["/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on July 30, 2020 */\n\n@font-face {\n  font-family: 'perfect_dos_vga_437regular';\n  src: url('perfect_dos_vga_437-webfont.woff2') format('woff2'),\n    url('perfect_dos_vga_437-webfont.woff') format('woff');\n  font-weight: normal;\n  font-style: normal;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
