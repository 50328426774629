import { PlanetType } from 'common-types';
import React, { useEffect, useMemo } from 'react';
import DataManager from '../../../Backend/DataManager';
import { Banner } from '../../Components/Banner';
import { Header } from '../../Components/Header';
import { planetUrl, FileType } from '../../Planet/PlanetUtils';
import { LoaderManager } from '../../Utils/ImageLoader';
import { getUrl } from '../../Utils/Utils';
import { R5FileName, RoundNumber } from '../ValhallaGlobal/ValhallaTypes';
import { R5Images } from './R5ImageProps';
import { R5Planets } from './R5Planets';

const R5_BG: string[] = [
  R5FileName.Tier_1,
  R5FileName.Tier_2,
  R5FileName.Tier_3,
  R5FileName.Tier_4,
  R5FileName.Tier_5,
  R5FileName.Tier_6,
].map((str) => getUrl(str, RoundNumber.Round5));

const R5_ALL: string[] = Object.values(R5FileName).map((str) => getUrl(str, RoundNumber.Round5));

export function R5Page({
  setLoaded,
  loaded,
  loaderManager,
  dataManager,
}: {
  setLoaded: (value: boolean) => void;
  loaderManager: LoaderManager;
  dataManager: DataManager;
  loaded: boolean;
}) {
  /* create loading lists */
  const r5planets = useMemo(
    () =>
      dataManager
        ? Array.from(dataManager.getPlanets().values())
            .filter((p) => p.planetType === PlanetType.WINNER)
            .map((p) => planetUrl(p, FileType.Png))
        : undefined,
    [dataManager]
  );

  useEffect(() => {
    if (!loaderManager) return;
    if (!r5planets) return;
    loaderManager.loadMany(
      [
        { urls: R5_BG, loaderId: 'background' },
        { urls: R5_ALL, loaderId: 'foreground' },
      ],
      () => setLoaded(true)
    );
  }, [loaderManager, r5planets]);

  if (!loaded) {
    return <></>;
  }

  return (
    <>
      <R5Images />
      <R5Planets />

      <Banner text={'Tier 1'} bottom={3806 + 2527 + 2700 + 1500 + 2800 - 275} />
      <Banner text={'Special Prizes'} bottom={3806 + 2527 + 2700 + 1500 + 200} />
      <Banner text={'Tier 2'} bottom={3806 + 2527 + 2800 - 450} />
      <Banner text={'Tier 3'} bottom={3806 + 2527 + 1150} />
      <Banner text={'Tier 4'} bottom={3806 + 2527} />
      <Banner text={'Tier 5'} bottom={3806} />
      <Banner text={'Tier 6'} bottom={1000} />
      <Banner text={'Scroll Up'} bottom={400} />
      <Header round={RoundNumber.Round5} bottom={100} />
    </>
  );
}
