import { PlanetType } from 'common-types';
import React, { useEffect, useMemo } from 'react';
import DataManager from '../../../Backend/DataManager';
import { Banner } from '../../Components/Banner';
import { Header } from '../../Components/Header';
import { planetUrl, FileType } from '../../Planet/PlanetUtils';
import { LoaderManager } from '../../Utils/ImageLoader';
import { getUrl } from '../../Utils/Utils';
import { R2FileName, RoundNumber } from '../ValhallaGlobal/ValhallaTypes';
import { R2Images } from './R2ImageProps';
import { R2Planets } from './R2Planets';

const R2_BG: string[] = [
  R2FileName.Intro,
  R2FileName.Tier_1,
  R2FileName.Tier_2,
  R2FileName.Tier_3,
  R2FileName.Tier_4,
  R2FileName.Tier_5,
  R2FileName.Tier_6,
].map((str) => getUrl(str, RoundNumber.Round2));

const R2_ALL: string[] = Object.values(R2FileName).map((str) => getUrl(str, RoundNumber.Round2));

export function R2Page({
  setLoaded,
  loaded,
  loaderManager,
  dataManager,
}: {
  setLoaded: (value: boolean) => void;
  loaderManager: LoaderManager;
  dataManager: DataManager;
  loaded: boolean;
}) {
  /* create loading lists */
  const r2Planets = useMemo(
    () =>
      dataManager
        ? Array.from(dataManager.getPlanets().values())
            .filter((p) => p.planetType === PlanetType.WINNER)
            .map((p) => planetUrl(p, FileType.Png))
        : undefined,
    [dataManager]
  );

  useEffect(() => {
    if (!loaderManager) return;
    if (!r2Planets) return;
    loaderManager.loadMany(
      [
        { urls: R2_BG, loaderId: 'background' },
        { urls: R2_ALL, loaderId: 'foreground' },
        // { urls: r2Planets, loaderId: 'planets' },
      ],
      () => setLoaded(true)
    );
  }, [loaderManager, r2Planets]);

  if (!loaded) {
    return <></>;
  }

  return (
    <>
      <R2Images />
      <R2Planets />

      <Header round={RoundNumber.Round2} bottom={100} />

      <Banner text={'Tier 1'} bottom={13994} />
      <Banner text={'Special Prizes'} bottom={12060} />
      <Banner text={'Tier 2'} bottom={10060} />
      <Banner text={'Tier 3'} bottom={7725} />
      <Banner text={'Tier 4'} bottom={5295} />
      <Banner text={'Tier 5'} bottom={3737} />
      <Banner text={'Tier 6'} bottom={1550} />
      <Banner text={'Scroll Up'} bottom={400} />
    </>
  );
}
