import React from 'react';
import { PlanetLevel } from '../ValhallaGlobal/ValhallaTypes';
import { R1Planet } from '../ValhallaGlobal/ValhallaPagePlanet';

export function R1Planets() {
  return (
    <>
      <>
        <R1Planet rank={63} bottom={2240} left={120} level={PlanetLevel.Level2} />
        <R1Planet rank={62} bottom={2295} left={395} level={PlanetLevel.Level2} />
        <R1Planet rank={61} bottom={2380} left={505} level={PlanetLevel.Level2} />
        <R1Planet rank={60} bottom={2360} left={46} level={PlanetLevel.Level2} />

        <R1Planet rank={59} bottom={2420} left={226} level={PlanetLevel.Level2} />
        <R1Planet rank={58} bottom={2520} left={380} level={PlanetLevel.Level2} />
        <R1Planet rank={57} bottom={2530} left={87} level={PlanetLevel.Level2} />
        <R1Planet rank={56} bottom={2610} left={250} level={PlanetLevel.Level2} />

        <R1Planet rank={55} bottom={2575} left={465} level={PlanetLevel.Level2} />
        <R1Planet rank={54} bottom={2700} left={155} level={PlanetLevel.Level2} />
        <R1Planet rank={53} bottom={2735} left={335} level={PlanetLevel.Level2} />
        <R1Planet rank={52} bottom={2775} left={37} level={PlanetLevel.Level2} />

        <R1Planet rank={51} bottom={2800} left={480} level={PlanetLevel.Level2} />
        <R1Planet rank={50} bottom={2838} left={238} level={PlanetLevel.Level2} />
        <R1Planet rank={49} bottom={2895} left={440} level={PlanetLevel.Level2} />
        <R1Planet rank={48} bottom={2970} left={120} level={PlanetLevel.Level2} />

        <R1Planet rank={47} bottom={3040} left={291} level={PlanetLevel.Level2} />
        <R1Planet rank={46} bottom={3085} left={523} level={PlanetLevel.Level2} />
        <R1Planet rank={45} bottom={3160} left={442} level={PlanetLevel.Level2} />
        <R1Planet rank={44} bottom={3155} left={157} level={PlanetLevel.Level2} />

        <R1Planet rank={43} bottom={3331} left={360} level={PlanetLevel.Level2} />
        <R1Planet rank={42} bottom={3360} left={73} level={PlanetLevel.Level2} />
        <R1Planet rank={41} bottom={3410} left={515} level={PlanetLevel.Level2} />
        <R1Planet rank={40} bottom={3520} left={308} level={PlanetLevel.Level2} />

        <R1Planet rank={39} bottom={3600} left={93} level={PlanetLevel.Level2} />
        <R1Planet rank={38} bottom={3654} left={515} level={PlanetLevel.Level2} />
        <R1Planet rank={37} bottom={3770} left={200} level={PlanetLevel.Level2} />
        <R1Planet rank={36} bottom={3830} left={380} level={PlanetLevel.Level2} />

        <R1Planet rank={35} bottom={3965} left={93} level={PlanetLevel.Level2} />
        <R1Planet rank={34} bottom={3985} left={517} level={PlanetLevel.Level2} />
        <R1Planet rank={33} bottom={4080} left={335} level={PlanetLevel.Level2} />
        <R1Planet rank={32} bottom={4150} left={493} level={PlanetLevel.Level2} />
      </>
      <>
        <R1Planet rank={31} bottom={4410} left={136} level={PlanetLevel.Level3} />
        <R1Planet rank={30} bottom={4600} left={447} level={PlanetLevel.Level3} />
        <R1Planet rank={29} bottom={4720} left={240} level={PlanetLevel.Level3} />
        <R1Planet rank={28} bottom={4850} left={85} level={PlanetLevel.Level3} />

        <R1Planet rank={27} bottom={4900} left={530} level={PlanetLevel.Level3} />
        <R1Planet rank={26} bottom={5100} left={400} level={PlanetLevel.Level3} />
        <R1Planet rank={25} bottom={5175} left={86} level={PlanetLevel.Level3} />
        <R1Planet rank={24} bottom={5280} left={490} level={PlanetLevel.Level3} />

        <R1Planet rank={23} bottom={5320} left={280} level={PlanetLevel.Level3} />
        <R1Planet rank={22} bottom={5480} left={55} level={PlanetLevel.Level3} />
        <R1Planet rank={21} bottom={5525} left={420} level={PlanetLevel.Level3} />
        <R1Planet rank={20} bottom={5647} left={188} level={PlanetLevel.Level3} />

        <R1Planet rank={19} bottom={5707} left={527} level={PlanetLevel.Level3} />
        <R1Planet rank={18} bottom={5820} left={285} level={PlanetLevel.Level3} />
        <R1Planet rank={17} bottom={5890} left={450} level={PlanetLevel.Level3} />
        <R1Planet rank={16} bottom={6012} left={110} level={PlanetLevel.Level3} />
      </>
      <>
        <R1Planet rank={15} bottom={6200} left={390} level={PlanetLevel.Level4} />
        <R1Planet rank={14} bottom={6400} left={200} level={PlanetLevel.Level4} />
        <R1Planet rank={13} bottom={6454} left={520} level={PlanetLevel.Level4} />
        <R1Planet rank={12} bottom={6615} left={100} level={PlanetLevel.Level4} />

        <R1Planet rank={11} bottom={6663} left={445} level={PlanetLevel.Level4} />
        <R1Planet rank={10} bottom={6780} left={264} level={PlanetLevel.Level4} />
        <R1Planet rank={9} bottom={6920} left={488} level={PlanetLevel.Level4} />
        <R1Planet rank={8} bottom={6965} left={28} level={PlanetLevel.Level4} />
      </>
      <>
        <R1Planet rank={7} bottom={7160} left={257} level={PlanetLevel.Level5} />
        <R1Planet rank={6} bottom={7310} left={490} level={PlanetLevel.Level5} />
        <R1Planet rank={5} bottom={7380} left={61} level={PlanetLevel.Level5} />
        <R1Planet rank={4} bottom={7517} left={300} level={PlanetLevel.Level5} />
      </>

      <R1Planet
        originalWinner={'0x0b652c646678ca09487435d8f329836fbc2ec6e1'}
        bottom={9100}
        left={390}
        level={PlanetLevel.Level3}
      />
      <R1Planet
        originalWinner={'0xd1e39e57aa8793737da45f9dd76f19e569da6db1'}
        bottom={9150}
        left={150}
        level={PlanetLevel.Level3}
      />
      <R1Planet
        originalWinner={'0xd4B3dbBCebc90A8fC14c67977967C444587dC808'}
        bottom={9250}
        left={20}
        level={PlanetLevel.Level3}
      />
      <R1Planet
        originalWinner={'0xb9A53628AFcD7A95c092FB6773dD3F033285cA44'}
        bottom={9300}
        left={250}
        level={PlanetLevel.Level3}
      />

      <R1Planet
        originalWinner={'0x9210fad7c7ba3d0ffdd977bcc29fb84b2579928a'}
        bottom={9363}
        left={400}
        level={PlanetLevel.Level3}
      />
      <R1Planet
        originalWinner={'0x0bb705e15d43cc9d21bc9c9bcbfa664e05633b02'}
        bottom={9554}
        left={400}
        level={PlanetLevel.Level3}
      />
      <R1Planet
        originalWinner={'0xc974f8a90b1bcbb2fb19547568a7aae1d4ef969c'}
        bottom={9500}
        left={50}
        level={PlanetLevel.Level3}
      />
      <R1Planet
        originalWinner={'0x4ddd223e5bc88422ae047cc57d4198f68519e42b'}
        bottom={9590}
        left={220}
        level={PlanetLevel.Level3}
      />
      <R1Planet
        originalWinner={'0xffcf7f66a936393a939debe192654ef99eabcc67'}
        bottom={9600}
        left={500}
        level={PlanetLevel.Level3}
      />
      <R1Planet
        originalWinner={'0xd28b7cc893e4f91692610d177dc115e8138c6a69'}
        bottom={9680}
        left={20}
        level={PlanetLevel.Level3}
      />
      <R1Planet
        originalWinner={'0x4750950cc2878e7441ecf6882e2ed2e487e938c2'}
        bottom={9850}
        left={120}
        level={PlanetLevel.Level5}
      />
      <R1Planet
        originalWinner={'0xe0a0a42de89c695cffee76c50c3da710bb22c112'}
        bottom={9850}
        left={400}
        level={PlanetLevel.Level5}
      />

      {/* Lv 6-7 */}
      <>
        <R1Planet rank={3} bottom={7800} left={400} level={PlanetLevel.Level6} />
        <R1Planet rank={2} bottom={8030} left={120} level={PlanetLevel.Level6} />

        <R1Planet rank={1} bottom={10785} left={300} level={PlanetLevel.Level7} centered />
      </>
    </>
  );
}
