import React from 'react';
import { R2Planet } from '../ValhallaGlobal/ValhallaPagePlanet';
import { PlanetLevel } from '../ValhallaGlobal/ValhallaTypes';

export function R2Planets() {
  return (
    <div>
      <>
        <>
          <R2Planet rank={63} bottom={1650} left={350} level={PlanetLevel.Level2} />
          <R2Planet rank={62} bottom={1750} left={395} level={PlanetLevel.Level2} />
          <R2Planet rank={61} bottom={1780} left={250} level={PlanetLevel.Level2} />
          <R2Planet rank={60} bottom={1820} left={46} level={PlanetLevel.Level2} />

          <R2Planet rank={59} bottom={1920} left={226} level={PlanetLevel.Level2} />
          <R2Planet rank={58} bottom={1990} left={380} level={PlanetLevel.Level2} />
          <R2Planet rank={57} bottom={2050} left={87} level={PlanetLevel.Level2} />
          <R2Planet rank={56} bottom={2080} left={250} level={PlanetLevel.Level2} />

          <R2Planet rank={55} bottom={2180} left={200} level={PlanetLevel.Level2} />
          <R2Planet rank={54} bottom={2280} left={155} level={PlanetLevel.Level2} />
          <R2Planet rank={53} bottom={2300} left={335} level={PlanetLevel.Level2} />
          <R2Planet rank={52} bottom={2400} left={37} level={PlanetLevel.Level2} />

          <R2Planet rank={51} bottom={2400} left={480} level={PlanetLevel.Level2} />
          <R2Planet rank={50} bottom={2450} left={238} level={PlanetLevel.Level2} />
          <R2Planet rank={49} bottom={2490} left={440} level={PlanetLevel.Level2} />
          <R2Planet rank={48} bottom={2500} left={120} level={PlanetLevel.Level2} />

          <R2Planet rank={47} bottom={2600} left={291} level={PlanetLevel.Level2} />
          <R2Planet rank={46} bottom={2650} left={400} level={PlanetLevel.Level2} />
          <R2Planet rank={45} bottom={2750} left={442} level={PlanetLevel.Level2} />
          <R2Planet rank={44} bottom={2700} left={157} level={PlanetLevel.Level2} />

          <R2Planet rank={43} bottom={2800} left={360} level={PlanetLevel.Level2} />
          <R2Planet rank={42} bottom={2950} left={73} level={PlanetLevel.Level2} />
          <R2Planet rank={41} bottom={2900} left={200} level={PlanetLevel.Level2} />
          <R2Planet rank={40} bottom={3000} left={308} level={PlanetLevel.Level2} />

          <R2Planet rank={39} bottom={3050} left={93} level={PlanetLevel.Level2} />
          <R2Planet rank={38} bottom={3100} left={515} level={PlanetLevel.Level2} />
          <R2Planet rank={37} bottom={3150} left={200} level={PlanetLevel.Level2} />
          <R2Planet rank={36} bottom={3200} left={380} level={PlanetLevel.Level2} />

          <R2Planet rank={35} bottom={3200} left={93} level={PlanetLevel.Level2} />
          <R2Planet rank={34} bottom={3350} left={480} level={PlanetLevel.Level2} />
          <R2Planet rank={33} bottom={3400} left={335} level={PlanetLevel.Level2} />
          <R2Planet rank={32} bottom={3550} left={400} level={PlanetLevel.Level2} />
        </>
        <>
          <R2Planet rank={31} bottom={3850} left={90} level={PlanetLevel.Level3} />
          <R2Planet rank={30} bottom={3850} left={447} level={PlanetLevel.Level3} />
          <R2Planet rank={29} bottom={3950} left={240} level={PlanetLevel.Level3} />
          <R2Planet rank={28} bottom={4100} left={85} level={PlanetLevel.Level3} />

          <R2Planet rank={27} bottom={4100} left={350} level={PlanetLevel.Level3} />
          <R2Planet rank={26} bottom={4200} left={200} level={PlanetLevel.Level3} />
          <R2Planet rank={25} bottom={4250} left={450} level={PlanetLevel.Level3} />
          <R2Planet rank={24} bottom={4360} left={100} level={PlanetLevel.Level3} />

          <R2Planet rank={23} bottom={4360} left={400} level={PlanetLevel.Level3} />
          <R2Planet rank={22} bottom={4250} left={55} level={PlanetLevel.Level3} />
          <R2Planet rank={21} bottom={4400} left={300} level={PlanetLevel.Level3} />
          <R2Planet rank={20} bottom={4590} left={188} level={PlanetLevel.Level3} />

          <R2Planet rank={19} bottom={4490} left={450} level={PlanetLevel.Level3} />
          <R2Planet rank={18} bottom={4690} left={285} level={PlanetLevel.Level3} />
          <R2Planet rank={17} bottom={4700} left={450} level={PlanetLevel.Level3} />
          <R2Planet rank={16} bottom={4800} left={150} level={PlanetLevel.Level3} />
        </>
        <>
          <R2Planet rank={15} bottom={6200} left={80} level={PlanetLevel.Level4} />
          <R2Planet rank={14} bottom={6400} left={200} level={PlanetLevel.Level4} />
          <R2Planet rank={13} bottom={6454} left={420} level={PlanetLevel.Level4} />
          <R2Planet rank={12} bottom={6615} left={100} level={PlanetLevel.Level4} />

          <R2Planet rank={11} bottom={6863} left={445} level={PlanetLevel.Level4} />
          <R2Planet rank={10} bottom={6980} left={264} level={PlanetLevel.Level4} />
          <R2Planet rank={9} bottom={7220} left={400} level={PlanetLevel.Level4} />
          <R2Planet rank={8} bottom={7365} left={100} level={PlanetLevel.Level4} />
        </>
        <>
          <R2Planet rank={7} bottom={9160} left={257} level={PlanetLevel.Level5} />
          <R2Planet rank={6} bottom={9310} left={490} level={PlanetLevel.Level5} />
          <R2Planet rank={5} bottom={9290} left={61} level={PlanetLevel.Level5} />
          <R2Planet rank={4} bottom={9417} left={300} level={PlanetLevel.Level5} />
        </>

        {/* Lv 6-7 */}
        <>
          <R2Planet rank={3} bottom={10830} left={250} level={PlanetLevel.Level6} />
          <R2Planet rank={2} bottom={11200} left={120} level={PlanetLevel.Level6} />
        </>

        {/* Special prize winners */}
        <>
          <R2Planet
            originalWinner={'0x9029ccc6563a8f2b1fa70e6265d90398061cb7a9'}
            bottom={12360}
            left={350}
            level={PlanetLevel.Level2}
          />

          <R2Planet
            originalWinner={'0xe9de7e3b83de99005f4df810a346a2ed9a07f4c1'}
            bottom={12360}
            left={200}
            level={PlanetLevel.Level2}
          />

          <R2Planet
            originalWinner={'0x65704dbbc00aa882e1dff7b9d26ffa3d835f2a6d'}
            bottom={12500}
            left={150}
            level={PlanetLevel.Level2}
          />

          <R2Planet
            originalWinner={'0x8d244dab7a5d80f0b9e5a1f9966e912100b3617a'}
            bottom={12560}
            left={350}
            level={PlanetLevel.Level2}
          />

          <R2Planet
            originalWinner={'0x6a7221d036b042a3f9ae9cd885d6a0b66bbf3c95'}
            bottom={12560}
            left={50}
            level={PlanetLevel.Level2}
          />

          <R2Planet
            originalWinner={'0x5faefab4c516826afd1d16e1dd9ddd3eb8cb5c4b'}
            bottom={12660}
            left={100}
            level={PlanetLevel.Level2}
          />

          <R2Planet
            originalWinner={'0xe682dead824514f730242e03ca5def19e52a3ac3'}
            bottom={12760}
            left={380}
            level={PlanetLevel.Level2}
          />

          <R2Planet
            originalWinner={'0xaa23208770da9ae738a7a5069bcdfdc06e487821'}
            bottom={12760}
            left={80}
            level={PlanetLevel.Level2}
          />

          <R2Planet
            originalWinner={'0x4ddd223e5bc88422ae047cc57d4198f68519e42b'}
            bottom={13660}
            left={390}
            level={PlanetLevel.Level3}
          />
          <R2Planet
            originalWinner={'0x4b7b6d3dc60adbfd35271bcdfacc5fca5e3a2107'}
            bottom={12960}
            left={150}
            level={PlanetLevel.Level3}
          />
          <R2Planet
            originalWinner={'0xa36ab50044b51c6f6f00a5da1ffa7727876d3b7d'}
            bottom={13060}
            left={20}
            level={PlanetLevel.Level3}
          />
          <R2Planet
            originalWinner={'0x2b9d9ddc96bc6e49bc94b6e2273621af7ec5b11e'}
            bottom={13060}
            left={250}
            level={PlanetLevel.Level3}
          />

          <R2Planet
            originalWinner={'0x8dc13e92246b9e9a494173f28b07262b30cc545c'}
            bottom={12960}
            left={400}
            level={PlanetLevel.Level3}
          />
          <R2Planet
            originalWinner={'0xbe293aef447ebd4907125c34f76e9821ad80350c'}
            bottom={13160}
            left={400}
            level={PlanetLevel.Level3}
          />
          <R2Planet
            originalWinner={'0x24aabe37478f1726f890bc6bda432b35c6c1a02c'}
            bottom={13260}
            left={50}
            level={PlanetLevel.Level3}
          />
          <R2Planet
            originalWinner={'0x0b652c646678ca09487435d8f329836fbc2ec6e1'}
            bottom={13260}
            left={220}
            level={PlanetLevel.Level3}
          />
          <R2Planet
            originalWinner={'0xe0a0a42de89c695cffee76c50c3da710bb22c112'}
            bottom={13460}
            left={400}
            level={PlanetLevel.Level3}
          />
          <R2Planet
            originalWinner={'0x4750950cc2878e7441ecf6882e2ed2e487e938c2'}
            bottom={13460}
            left={200}
            level={PlanetLevel.Level3}
          />
        </>
        <>
          <R2Planet rank={1} bottom={14500} left={300} level={PlanetLevel.Level7} centered />
        </>
      </>
    </div>
  );
}
