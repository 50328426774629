import { PlanetType } from 'common-types';
import React, { useEffect, useMemo } from 'react';
import DataManager from '../../../Backend/DataManager';
import { Banner } from '../../Components/Banner';
import { FloatingText } from '../../Components/FloatingText';
import { Header } from '../../Components/Header';
import { planetUrl, FileType } from '../../Planet/PlanetUtils';
import { TEXT_COLOR } from '../../Utils/consts';
import { bobAnim } from '../../Utils/CSSAnims';
import { LoaderManager } from '../../Utils/ImageLoader';
import { getUrl } from '../../Utils/Utils';
import { R1FileName, RoundNumber } from '../ValhallaGlobal/ValhallaTypes';
import { R1Images } from './R1Components';
import { R1Planets } from './R1Planets';

const R1_BG: string[] = [
  R1FileName.Background,
  R1FileName.Petals,
  R1FileName.Sun,
  R1FileName.Grass,
  R1FileName.Flowers_FG_L,
  R1FileName.Flowers_FG_R,
  R1FileName.Flowers_MG,
  R1FileName.Flowers_BG,
  R1FileName.Tree,
].map((str) => getUrl(str, RoundNumber.Round1));

const R1_ALL: string[] = Object.values(R1FileName).map((str) => getUrl(str, RoundNumber.Round1));

export function R1Page({
  loaded,
  setLoaded,
  loaderManager,
  dataManager,
}: {
  loaded: boolean;
  setLoaded: (value: boolean) => void;
  loaderManager: LoaderManager;
  dataManager: DataManager;
}) {
  /* create loading lists */
  const r1Planets = useMemo(
    () =>
      dataManager
        ? Array.from(dataManager.getPlanets().values())
            .filter((p) => p.planetType === PlanetType.WINNER)
            .map((p) => planetUrl(p, FileType.Png))
        : undefined,
    [dataManager]
  );

  useEffect(() => {
    if (!loaderManager) return;
    if (!r1Planets) return;
    loaderManager.loadMany(
      [
        { urls: R1_BG, loaderId: 'background' },
        { urls: R1_ALL, loaderId: 'foreground' },
        { urls: r1Planets, loaderId: 'planets' },
      ],
      () => setLoaded(true)
    );
  }, [loaderManager, r1Planets]);

  if (!loaded) {
    return <></>;
  }

  return (
    <>
      <R1Images />
      <R1Planets />

      <Header round={RoundNumber.Round1} bottom={1000} />

      <>
        <Banner text={'Tier 6'} bottom={2160} />
        <Banner text={'Tier 5'} bottom={4260} />
        <Banner text={'Tier 4'} bottom={6090} />
        <Banner text={'Tier 3'} bottom={7080} />
        <Banner text={'Tier 2'} bottom={7730} />
        <Banner text={'Special Mentions'} bottom={8920} />

        <FloatingText round={RoundNumber.Round1} bottom={10150} left={0} right={0}>
          Keep scrolling!
        </FloatingText>
        <FloatingText
          round={RoundNumber.Round1}
          bottom={350}
          left={0}
          right={0}
          color={TEXT_COLOR}
          anim={bobAnim(5, 1)}
        >
          {'^  Scroll up!  ^'}
        </FloatingText>
      </>
    </>
  );
}
