import autoBind from 'auto-bind';
import styled from 'styled-components';
import { monomitter, Monomitter } from './Monomitter';

export const LoaderDiv = styled.div`
  position: fixed;
  opacity: 0;
  pointer-events: none;
  max-width: 100px;
  max-height: 100px;
  overflow: hidden;

  img {
    position: absolute;
  }
`;

/* on first init, tell the LoaderManager that a new image is being loaded. on load, tell it that we're done loading.

  failure case: we load one image, that image tells the LoaderManager that it's done, and now we think we're done loading,
  but there's other images left to load. 

  however, due to the the way React and setState works, all images should push to the LoaderManager after React's first render,
  and only after all of the setLoadeds have been called will the LoaderManager know that we're done. so this case should never happen.
*/

export type LoadProgress = {
  loaderId: string;
  length: number;
  current: number;
};

export type LoadingSet = {
  loaderId: string;
  urls: string[];
};

export class LoaderManager {
  private loadings: { [k: string]: boolean };
  private div: HTMLDivElement;

  public loadedImage$: Monomitter<LoadProgress>;

  constructor(div: HTMLDivElement) {
    autoBind(this);

    this.loadedImage$ = monomitter();

    this.div = div;
    this.loadings = {};
  }

  public async loadImages(set: LoadingSet, cb: () => void = () => {}): Promise<void> {
    const { urls, loaderId } = set;
    // const loaderId = this.getId();

    const length = urls.length;
    this.loadedImage$.publish({ loaderId, length, current: 0 });

    let amt = 0;
    const getAmt = () => amt++;

    await Promise.all(
      urls.map((url) =>
        this.loadImage(url, () =>
          this.loadedImage$.publish({ loaderId, length, current: getAmt() })
        )
      )
    );

    this.loadedImage$.publish({ loaderId, length, current: length });
    cb();
  }

  public async loadMany(urlSets: LoadingSet[], cb: () => void): Promise<void> {
    await Promise.all(urlSets.map((urlSet) => this.loadImages(urlSet)));
    cb();
  }

  public async loadImage(url: string, cb: () => void = () => {}): Promise<void> {
    if (this.loadings[url]) return;

    const img = document.createElement('img');

    const myDiv = this.div;
    const setLoaded = () => {
      this.loadings[url] = true;
    };

    return new Promise<void>((resolve) => {
      myDiv.appendChild(img);
      img.src = url;

      const timeout = setTimeout(() => {
        resolve();
        console.error('failed to load image: ' + url);
      }, 5000);

      img.onload = () => {
        resolve();
        setLoaded();
        cb();

        clearTimeout(timeout);
      };

      // fallback if it doesn't load
    });
  }
}
