import autoBind from 'auto-bind';
import {
  WebGLRenderer,
  Scene,
  PerspectiveCamera,
  OrthographicCamera,
  Mesh,
  Group,
  SphereGeometry,
  BufferAttribute,
  ShaderMaterial,
  MeshBasicMaterial,
} from 'three';
import { makeCloud } from './Cloud';
import { PlanetUniforms, getPlanetMaterial } from '../Utils/PlanetShader';
import { PlanetId, ValhallaPlanet } from 'common-types';
import { getPlanetProps, planetRandom, planetRandomInt } from './PlanetUtils';
const noiseMaker = require('fast-simplex-noise');

export const MAX_FRAMES = 12 * 60; // 24 seconds

type NoiseFn = (x: number, y: number, z: number, w: number) => number;

const getNoise = (id: PlanetId): NoiseFn => {
  const rand = planetRandom(id);
  const noise4d = noiseMaker.makeNoise4D(rand); // [-1.15, 1.15]

  const n4d: NoiseFn = (x, y, z, w) => {
    const fac = 0.8;
    // 0 to 1
    const n = noise4d(fac * x, fac * y, fac * z, fac * w);
    const squashed = (n + 1.15) / 2.3;
    if (squashed > 1) return 1;
    if (squashed < 0) return 0;
    return squashed;
  };

  return n4d;
};

type Offset = [number, number, number, number];

function noiseWithOctaves(
  n4d: NoiseFn,
  [x, y, z]: [number, number, number],
  octaves: number,
  [offX, offY, offZ, w]: Offset
): number {
  let n = 0;
  let amp = 0;
  for (let i = 0; i < octaves; i++) {
    const h = 0.5 ** i;
    const freq = 2 ** i;
    n += h * n4d((x + offX) * freq, (y + offY) * freq, (z + offZ) * freq, w);
    amp += h;
  }

  return n / amp;
}

function smooth(n: number): number {
  return n > 0 ? n : 0;
}

// rgb(81, 108, 131)  blue
// rgb(162, 204, 140)  green
// rgb(225, 232, 177)  sand

export class PlanetRenderer {
  canvas: HTMLCanvasElement;
  size: number;

  planet: ValhallaPlanet | undefined;

  renderer: WebGLRenderer;
  scene: Scene;
  camera: PerspectiveCamera | OrthographicCamera;

  planetMesh: Mesh;
  cloudGroup: Group | undefined;

  uniforms: PlanetUniforms;
  planetMaterial: ShaderMaterial;

  constructor(canvas: HTMLCanvasElement, size: number, color: number, alpha: number) {
    autoBind(this);
    this.canvas = canvas;
    this.size = size;

    this.init(color, alpha);
  }

  public setPlanet(planet: ValhallaPlanet) {
    this.planet = planet;

    const planetProps = getPlanetProps(this.planet);
    const { colors, octaves, clouds } = planetProps;

    this.uniforms['u_colorL'].value = colors.landColor;
    this.uniforms['u_colorW'].value = colors.waterColor;
    this.uniforms['u_colorS'].value = colors.sandColor;
    this.uniforms['u_colorS2'].value = colors.sandColor2;
    this.uniforms['u_colorI'].value = colors.iceColor;

    this.clearScene();
    this.createPlanet(octaves, clouds, colors.cloudColor);
  }

  private clearScene() {
    this.scene.clear();
    this.scene.add(this.camera);
  }

  private createPlanet(octaves: number, clouds: boolean, cloudColor: undefined | THREE.Color) {
    if (!this.planet) return;
    /* create objects */
    const geometry = new SphereGeometry(1, 200, 200);
    const pos = geometry.attributes['position'];
    if (!pos) console.error('could not find pos attribute!');

    // set colors
    const colors: Float32Array = new Float32Array(pos.count * 3);
    const positions: Float32Array = new Float32Array(pos.count * 3);

    const randInt = planetRandomInt(this.planet.id);
    const offX = randInt() % 10000;
    const offY = randInt() % 10000;
    const offZ = randInt() % 10000;
    const w = randInt() % 10000;
    const offset: Offset = [offX, offY, offZ, w];

    const n4d = getNoise(this.planet.id);

    for (let i = 0; i < pos.count; i++) {
      const x = pos.getX(i); // -1 to 1
      const y = pos.getY(i); // -1 to 1
      const z = pos.getZ(i); // -1 to 1

      const nSum = noiseWithOctaves(n4d, [x, y, z], octaves, offset);

      const cutoff = 0.5; // water-land cutoff

      const nMap = nSum - cutoff; // [-0.5, 0.5]

      // map n > 0 -> land, n < 0 -> water
      const n = (1 / (1 - cutoff)) * smooth(nMap);

      const color = [n, n, n];
      colors.set(color, i * 3);

      const rad = 0.8 + n * 0.2;
      const newPos = [rad * x, rad * y, rad * z];
      positions.set(newPos, i * 3);
    }

    geometry.setAttribute('color', new BufferAttribute(colors, 3));
    geometry.setAttribute('position', new BufferAttribute(positions, 3));

    const planet = new Mesh(geometry, this.planetMaterial);
    this.scene.add(planet);

    this.planetMesh = planet;

    /* create clouds */
    if (clouds) {
      const cloudMaterial = new MeshBasicMaterial({ color: cloudColor });
      const cloudGroup = new Group();
      const rand = planetRandom(this.planet.id);
      for (let i = 0; i < 36; i++) {
        const cloud = makeCloud(this.scene.position, cloudMaterial, rand);
        cloudGroup.add(cloud);
      }
      this.scene.add(cloudGroup);
      this.cloudGroup = cloudGroup;
    }
  }

  public setClearAlpha(alpha: number) {
    this.renderer.setClearAlpha(alpha);
  }

  private init(color: number, alpha: number) {
    /* set up scene */
    const r = 1.1; // viewport size

    const scene = new Scene();
    const camera = new OrthographicCamera(-r, r, r, -r, 1, 1000);
    // const camera = new PerspectiveCamera(75, 1, 0.1, 1000);

    camera.position.z = 5;
    scene.add(camera);

    const renderer = new WebGLRenderer({ canvas: this.canvas, alpha: true });
    renderer.setSize(this.size, this.size);

    this.scene = scene;
    this.camera = camera;
    this.renderer = renderer;

    renderer.setClearColor(color);
    renderer.setClearAlpha(alpha);

    /* create objects */
    const { uniforms, material: planetMaterial } = getPlanetMaterial();
    this.uniforms = uniforms;
    this.planetMaterial = planetMaterial;
  }

  public render() {
    this.renderer.render(this.scene, this.camera);
  }

  public destroy() {
    return;
  }

  // [0, 180)
  public moveToFrame(frameNo: number) {
    if (!this.planet) return;

    this.planetMesh.rotation.y = ((Math.PI * 2) / MAX_FRAMES) * frameNo;
    if (this.cloudGroup) {
      this.cloudGroup.rotation.y = ((Math.PI * 2) / MAX_FRAMES) * 2 * frameNo;
    }
  }
}
