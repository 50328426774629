import React from 'react';
import { RoundImage } from '../ValhallaGlobal/ValhallaImage';
import {
  BackgroundPlacement,
  BoundingBox,
  R4FileName,
  ImageProps,
  RoundNumber,
} from '../ValhallaGlobal/ValhallaTypes';

export const R4ImageProps: Record<R4FileName, ImageProps> = {
  [R4FileName.Tier_1]: {
    imgWidth: 640,
    imgHeight: 1898,
    zIndex: 0,
    boundingBox: BoundingBox.FillXFitY,
    placement: BackgroundPlacement.Background,
  },
  [R4FileName.Tier_2]: {
    imgWidth: 640,
    imgHeight: 2274,
    zIndex: 0,
    boundingBox: BoundingBox.FillXFitY,
    placement: BackgroundPlacement.Background,
  },
  [R4FileName.Tier_3]: {
    imgWidth: 640,
    imgHeight: 1594,
    zIndex: 0,
    boundingBox: BoundingBox.FillXFitY,
    placement: BackgroundPlacement.Background,
  },
  [R4FileName.Tier_4]: {
    imgWidth: 640,
    imgHeight: 2167,
    zIndex: 0,
    boundingBox: BoundingBox.FillXFitY,
    placement: BackgroundPlacement.Background,
  },
  [R4FileName.Tier_5]: {
    imgWidth: 640,
    imgHeight: 2527,
    zIndex: 0,
    boundingBox: BoundingBox.FillXFitY,
    placement: BackgroundPlacement.Background,
  },
  [R4FileName.Tier_6]: {
    imgWidth: 640,
    imgHeight: 3806,
    zIndex: 0,
    boundingBox: BoundingBox.FillXFitY,
    placement: BackgroundPlacement.Background,
  },
};

export function R4Images() {
  return (
    <>
      <RoundImage fileName={R4FileName.Tier_1} round={RoundNumber.Round4} />
      <RoundImage fileName={R4FileName.Tier_2} round={RoundNumber.Round4} />
      <RoundImage fileName={R4FileName.Tier_3} round={RoundNumber.Round4} />
      <RoundImage fileName={R4FileName.Tier_4} round={RoundNumber.Round4} />
      <RoundImage fileName={R4FileName.Tier_5} round={RoundNumber.Round4} />
      <RoundImage fileName={R4FileName.Tier_6} round={RoundNumber.Round4} />
    </>
  );
}
