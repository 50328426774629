import {
  BoundingBox,
  BackgroundPlacement,
  R1FileName,
  ImageProps,
} from '../ValhallaGlobal/ValhallaTypes';

enum ZIndex {
  Background = 0,
  Background2 = 1,
  Sun = 2,
  Grass = 3,
  Flowers_BG = 4,
  Tree = 5,
  Flowers_MG = 6,
  Flowers_FG = 7,

  Clouds_Ground_1 = 2,
  Clouds_Ground_2 = 3,
  Clouds_Ground_3 = 4,

  BG_Objs = 1,

  Gems_1 = 2,
  Gems_2 = 3,
  Gems_3 = 4,
  Asteroids = 5,

  Galaxy_1 = 2,
  Galaxy_2 = 3,
  Galaxy_3 = 4,

  Feathers = 2,
  Angels = 3,

  Flower = 2,
  Clouds_Heaven_Top = 3,
  Clouds_Heaven_1 = 4,
  Clouds_Heaven_2 = 5,
  Clouds_Heaven_3 = 6,
}

const CLOUD_GROUND = { bottom: 809, imgWidth: 640, imgHeight: 1462 };
const cloudGroundProps = {
  imgWidth: CLOUD_GROUND.imgWidth,
  imgHeight: CLOUD_GROUND.imgHeight,
  boundingBox: BoundingBox.FillXFitY,
  placement: BackgroundPlacement.HorizontalLoop,
  position: { bottom: CLOUD_GROUND.bottom },
};

const ASTEROIDS = { bottom: 4207, imgWidth: 640, imgHeight: 1832 };
const gemsAsteroidsProps = {
  imgWidth: ASTEROIDS.imgWidth,
  imgHeight: ASTEROIDS.imgHeight,
  boundingBox: BoundingBox.FillXFitY,
  placement: BackgroundPlacement.HorizontalLoop,
  position: { bottom: ASTEROIDS.bottom },
};
export const ASTEROID_MIDDLE = ASTEROIDS.bottom + ASTEROIDS.imgHeight / 2;

const GALAXIES = { bottom: 5703, imgWidth: 1369, imgHeight: 1606 };
const galaxyProps = {
  imgWidth: GALAXIES.imgWidth,
  imgHeight: GALAXIES.imgHeight,
  boundingBox: BoundingBox.FillXFitY,
  placement: BackgroundPlacement.HorizontalLoopCenter,
};

const CLOUD_HEAVEN = { bottom: 8265, imgWidth: 640, imgHeight: 1826 };
const cloudHeavenProps = {
  imgWidth: CLOUD_HEAVEN.imgWidth,
  imgHeight: CLOUD_HEAVEN.imgHeight,
  boundingBox: BoundingBox.FillXFitY,
  placement: BackgroundPlacement.HorizontalLoop,
  position: { bottom: CLOUD_HEAVEN.bottom },
};

const CLOUD_TOP = 10418;
const FLOWER = { bottom: CLOUD_TOP, imgWidth: 144, imgHeight: 117 };
const flowerProps = {
  imgWidth: FLOWER.imgWidth,
  imgHeight: FLOWER.imgHeight,
  zIndex: ZIndex.Flower,
  boundingBox: BoundingBox.FitXFitY,
  placement: BackgroundPlacement.BottomCenterObject,
  position: { bottom: FLOWER.bottom, right: 0, left: 0 },
};

export const SPACESHIP_BOTTOM = 2830;

export const R1ImageProps: Record<R1FileName, ImageProps> = {
  [R1FileName.Background]: {
    imgWidth: 640,
    imgHeight: 11146,
    zIndex: ZIndex.Background,
    boundingBox: BoundingBox.FillXFitY,
    placement: BackgroundPlacement.Background,
  },
  [R1FileName.Petals]: {
    imgWidth: 640,
    imgHeight: 400,
    zIndex: ZIndex.Background2,
    boundingBox: BoundingBox.FillXFitY,
    placement: BackgroundPlacement.HorizontalLoopCenter,
    position: { bottom: 0 },
  },
  [R1FileName.Sun]: {
    imgWidth: 640,
    imgHeight: 200,
    zIndex: ZIndex.Sun,
    boundingBox: BoundingBox.FillXFitY,
    placement: BackgroundPlacement.BottomCenterObject,
    position: { bottom: 0 },
  },
  [R1FileName.Grass]: {
    imgWidth: 640,
    imgHeight: 41,
    zIndex: ZIndex.Grass,
    boundingBox: BoundingBox.FillXFitY,
    placement: BackgroundPlacement.HorizontalLoop,
    position: { bottom: 0 },
  },
  [R1FileName.Flowers_FG_L]: {
    imgWidth: 333,
    imgHeight: 124,
    zIndex: ZIndex.Flowers_FG,
    boundingBox: BoundingBox.FitXFitY,
    placement: BackgroundPlacement.BottomLeftObject,
    position: { bottom: 0, left: 0 },
  },
  [R1FileName.Flowers_FG_R]: {
    imgWidth: 278,
    imgHeight: 124,
    zIndex: ZIndex.Flowers_FG,
    boundingBox: BoundingBox.FitXFitY,
    placement: BackgroundPlacement.BottomRightObject,
    position: { bottom: 0, right: 0 },
  },
  [R1FileName.Flowers_MG]: {
    imgWidth: 640,
    imgHeight: 46,
    zIndex: ZIndex.Flowers_MG,
    boundingBox: BoundingBox.FillXFitY,
    placement: BackgroundPlacement.HorizontalLoop,
    position: { bottom: 0 },
  },
  [R1FileName.Flowers_BG]: {
    imgWidth: 640,
    imgHeight: 45,
    zIndex: ZIndex.Flowers_BG,
    boundingBox: BoundingBox.FillXFitY,
    placement: BackgroundPlacement.HorizontalLoop,
    position: { bottom: 0 },
  },
  [R1FileName.Tree]: {
    imgWidth: 300,
    imgHeight: 281,
    zIndex: ZIndex.Tree,
    boundingBox: BoundingBox.FitXFitY,
    placement: BackgroundPlacement.BottomCenterObject,
    position: { bottom: 20, left: 0, right: 0 },
  },
  [R1FileName.Clouds_Ground_1]: {
    zIndex: ZIndex.Clouds_Ground_1,
    ...cloudGroundProps,
  },
  [R1FileName.Clouds_Ground_2]: {
    zIndex: ZIndex.Clouds_Ground_2,
    ...cloudGroundProps,
  },
  [R1FileName.Clouds_Ground_3]: {
    zIndex: ZIndex.Clouds_Ground_3,
    ...cloudGroundProps,
  },
  [R1FileName.Satellite]: {
    imgWidth: 73,
    imgHeight: 73,
    zIndex: ZIndex.BG_Objs,
    boundingBox: BoundingBox.FitXFitY,
    placement: BackgroundPlacement.BottomCenterObject,
    position: { bottom: 2169, right: 46 },
  },
  [R1FileName.Spaceship]: {
    imgWidth: 96,
    imgHeight: 160,
    zIndex: ZIndex.BG_Objs,
    boundingBox: BoundingBox.FitXFitY,
    placement: BackgroundPlacement.BottomCenterObject,
    position: { bottom: SPACESHIP_BOTTOM, left: 102 },
  },
  [R1FileName.Moon]: {
    imgWidth: 175,
    imgHeight: 175,
    zIndex: ZIndex.BG_Objs,
    boundingBox: BoundingBox.FitXFitY,
    placement: BackgroundPlacement.BottomCenterObject,
    position: { bottom: 3940, left: 125 },
  },
  [R1FileName.UFO]: {
    imgWidth: 119,
    imgHeight: 121,
    zIndex: ZIndex.BG_Objs,
    boundingBox: BoundingBox.FitXFitY,
    placement: BackgroundPlacement.BottomCenterObject,
    position: { bottom: 4239, right: 26 },
  },
  [R1FileName.Gems_1]: {
    zIndex: ZIndex.Gems_1,
    ...gemsAsteroidsProps,
  },
  [R1FileName.Gems_2]: {
    zIndex: ZIndex.Gems_2,
    ...gemsAsteroidsProps,
  },
  [R1FileName.Gems_3]: {
    zIndex: ZIndex.Gems_3,
    ...gemsAsteroidsProps,
  },
  [R1FileName.Asteroids]: {
    zIndex: ZIndex.Asteroids,
    ...gemsAsteroidsProps,
  },
  [R1FileName.Galaxy_1]: {
    zIndex: ZIndex.Galaxy_1,
    ...galaxyProps,
    position: { bottom: GALAXIES.bottom },
  },
  [R1FileName.Galaxy_2]: {
    zIndex: ZIndex.Galaxy_2,
    ...galaxyProps,
    position: { bottom: GALAXIES.bottom },
  },
  [R1FileName.Galaxy_3]: {
    zIndex: ZIndex.Galaxy_3,
    ...galaxyProps,
    position: { bottom: GALAXIES.bottom },
  },
  [R1FileName.AngelLeft]: {
    imgWidth: 182,
    imgHeight: 106,
    zIndex: ZIndex.Angels,
    boundingBox: BoundingBox.FitXFitY,
    placement: BackgroundPlacement.BottomLeftObject,
    position: { bottom: 7778, left: 0 },
  },
  [R1FileName.AngelRight]: {
    imgWidth: 65,
    imgHeight: 86,
    zIndex: ZIndex.Angels,
    boundingBox: BoundingBox.FitXFitY,
    placement: BackgroundPlacement.BottomRightObject,
    position: { bottom: 7925, right: 18 },
  },
  [R1FileName.AngelTop]: {
    imgWidth: 104,
    imgHeight: 99,
    zIndex: ZIndex.Angels,
    boundingBox: BoundingBox.FitXFitY,
    placement: BackgroundPlacement.BottomCenterObject,
    position: { bottom: 8160, left: 163 },
  },
  [R1FileName.Feathers]: {
    imgWidth: 640,
    imgHeight: 1491,
    zIndex: ZIndex.Feathers,
    boundingBox: BoundingBox.FillXFitY,
    placement: BackgroundPlacement.HorizontalLoopCenter,
    position: { bottom: 6961 },
  },
  [R1FileName.Clouds_Heaven_1]: {
    zIndex: ZIndex.Clouds_Heaven_1,
    ...cloudHeavenProps,
  },
  [R1FileName.Clouds_Heaven_2]: {
    zIndex: ZIndex.Clouds_Heaven_2,
    ...cloudHeavenProps,
  },
  [R1FileName.Clouds_Heaven_3]: {
    zIndex: ZIndex.Clouds_Heaven_3,
    ...cloudHeavenProps,
  },
  [R1FileName.Clouds_Top_L]: {
    imgWidth: 640,
    imgHeight: 430,
    zIndex: ZIndex.Clouds_Heaven_Top,
    boundingBox: BoundingBox.FitXFitY,
    placement: BackgroundPlacement.BottomLeftObject,
    position: { bottom: CLOUD_TOP, left: 0 },
  },
  [R1FileName.Clouds_Top_R]: {
    imgWidth: 640,
    imgHeight: 430,
    zIndex: ZIndex.Clouds_Heaven_Top,
    boundingBox: BoundingBox.FitXFitY,
    placement: BackgroundPlacement.BottomRightObject,
    position: { bottom: CLOUD_TOP, right: 0 },
  },
  [R1FileName.Flower_Start]: {
    ...flowerProps,
  },
  [R1FileName.Flower_Blooming]: {
    ...flowerProps,
  },
  [R1FileName.Flower_Bloomed]: {
    ...flowerProps,
  },
};
