import { PlanetId, ValhallaPlanet } from 'common-types';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import DataManager, { DataManagerEvent } from '../../Backend/DataManager';
import { Planet } from '../../_types/global';
import { SubmittedTx, TxIntent } from '../../_types/transactions';
import { FileRenderer } from '../Planet/FileRenderer';
import { PlanetRenderer } from '../Planet/PlanetRenderer';
import { PreviewRenderer } from '../Planet/PreviewRenderer';
import { AnimationManager, makeParallaxHandler } from './AnimationManager';
import { createDefinedContext } from './createDefinedContext';
import { useEmitterSubscribe } from './EmitterHooks';
import { LoaderManager } from './ImageLoader';
import { compareAddresses } from './Utils';

export const { useDefinedContext: useAnimManager, provider: AnimManagerProvider } =
  createDefinedContext<AnimationManager>();

export const ResContext = React.createContext<number>(2);
export const useRes = () => useContext<number>(ResContext);

export const MobileContext = React.createContext<boolean>(false);
export const useMobile = () => useContext<boolean>(MobileContext);

export const { useDefinedContext: useLoaderManager, provider: LoaderManagerProvider } =
  createDefinedContext<LoaderManager>();

export const { useDefinedContext: useDataManager, provider: DataManagerProvider } =
  createDefinedContext<DataManager>();

export const AccountContext = React.createContext<string | undefined>(undefined);
export const useAccount = () => useContext<string | undefined>(AccountContext);

export const NetworkContext = React.createContext<boolean>(false);
export const useIsCorrectNetwork = () => useContext<boolean>(NetworkContext);

/**
 * Generate a parallax animation handler
 * @param manager `AnimmationManager` instance
 * @param res current resolution
 * @param anchorBot normal `bottom` of component (measured in world pixels)
 * @param velocity parallax velocity
 * @returns returns a ref setter
 */
export function useParallax(
  manager: AnimationManager,
  res: number,
  anchorBot: number,
  velocity: number
): (ref: HTMLDivElement | null) => void {
  const [div, setDiv] = useState<HTMLDivElement | null>(null);
  useEffect(() => {
    if (!div) return;
    const remove = manager.addScrollHandler(makeParallaxHandler(div, res, anchorBot, velocity));

    return remove;
  }, [div, manager, res, anchorBot, velocity]);

  return (ref: HTMLDivElement | null) => setDiv(ref);
}

/* planet renderer hooks */

export function useRenderer<T extends PlanetRenderer>(
  size: number,
  planet: ValhallaPlanet | undefined,
  getRenderer: (canvas: HTMLCanvasElement, size: number) => T
): {
  renderer: T | undefined;
  setter: (el: HTMLCanvasElement | null) => void;
} {
  const [renderer, setRenderer] = useState<T | undefined>();
  const [canvas, setCanvas] = useState<HTMLCanvasElement | null>(null);

  useEffect(() => {
    if (!canvas) return;

    const newRenderer = getRenderer(canvas, size);
    setRenderer(newRenderer);
    return () => newRenderer.destroy();
  }, [canvas, size, getRenderer]);

  useEffect(() => {
    if (planet) renderer?.setPlanet(planet);
  }, [renderer, planet]);

  return {
    setter: (el) => setCanvas(el),
    renderer,
  };
}

const getPreviewRenderer = (canvas: HTMLCanvasElement, size: number) =>
  new PreviewRenderer(canvas, size);

export function usePreviewRenderer(
  size: number,
  planet: ValhallaPlanet | undefined
): {
  setter: (el: HTMLCanvasElement | null) => void;
  renderer: PreviewRenderer | undefined;
} {
  const { setter, renderer } = useRenderer<PreviewRenderer>(size, planet, getPreviewRenderer);
  return { setter, renderer };
}

const getFileRenderer = (canvas: HTMLCanvasElement, size: number) => new FileRenderer(canvas, size);

export function useFileRenderer(
  size: number,
  planet: ValhallaPlanet | undefined
): {
  renderer: FileRenderer | undefined;
  setter: (el: HTMLCanvasElement | null) => void;
} {
  useEffect(() => {
    const script = document.createElement('script');

    script.src = '/public/CCapture.all.min.js';
    script.async = true;

    document.body.appendChild(script);
  }, []);

  const { renderer, setter } = useRenderer<FileRenderer>(size, planet, getFileRenderer);

  return { setter, renderer };
}

/* DataManager hooks */
export function useCanClaim(account: string | undefined, planet: Planet | undefined): boolean {
  return !!account && !!planet && compareAddresses(account, planet.originalWinner);
}

export function usePlanetWithId(
  dataManager: DataManager,
  id: PlanetId | undefined
): Planet | undefined {
  const [planet, setPlanet] = useState<Planet | undefined>(dataManager.getPlanetById(id));

  const update = useCallback(
    (newId: PlanetId | undefined) => {
      const p = dataManager?.getPlanetById(newId);
      setPlanet(p ? { ...p } : undefined);
    },
    [dataManager]
  );

  useEffect(() => {
    update(id);
  }, [dataManager, id, update]);

  const syncPlanet = useCallback(
    (newId: PlanetId | undefined) => {
      if (id === newId) update(newId);
    },
    [id, update]
  );

  useEmitterSubscribe(dataManager.planetUpdated$, syncPlanet);

  return planet;
}

export function useTxIntents(dataManager: DataManager): {
  intents: TxIntent[];
  error: string | undefined;
} {
  const [txIntents, setTxIntents] = useState<TxIntent[]>([]);
  const [error, setError] = useState<string | undefined>();

  useEffect(() => {
    const onTxInit = (txIntent: TxIntent) => {
      setTxIntents((intents: TxIntent[]) => {
        intents.push(txIntent);
        return [...intents];
      });
    };

    const onTxSubmit = (submittedTx: SubmittedTx) => {
      setTxIntents((intents: TxIntent[]) => {
        let exists = false;
        for (let i = 0; i < intents.length; i++) {
          const txIntent = intents[i];
          if (txIntent.actionId === submittedTx.actionId) {
            intents[i] = submittedTx;
            exists = true;
            break;
          }
        }
        if (!exists) {
          intents.push(submittedTx);
        }
        return [...intents];
      });
    };

    const onTxFailed = (txIntent: TxIntent, reason: string) => {
      setTxIntents((intents: TxIntent[]) => {
        for (let i = 0; i < intents.length; i++) {
          const current = intents[i];
          if (current.actionId === txIntent.actionId) {
            intents.splice(i, 1);
            break;
          }
        }
        setError(reason);
        return [...intents];
      });
    };

    const onTxConfirmed = (submittedTx: SubmittedTx) => {
      setTxIntents((intents: TxIntent[]) => {
        for (let i = 0; i < intents.length; i++) {
          const txIntent = intents[i];
          if (txIntent.actionId === submittedTx.actionId) {
            intents.splice(i, 1);
            break;
          }
        }
        return [...intents];
      });
    };

    dataManager.addListener(DataManagerEvent.TxInitialized, onTxInit);
    dataManager.addListener(DataManagerEvent.TxSubmitted, onTxSubmit);
    dataManager.addListener(DataManagerEvent.TxFailed, onTxFailed);
    dataManager.addListener(DataManagerEvent.TxConfirmed, onTxConfirmed);
    return () => {
      dataManager.removeListener(DataManagerEvent.TxInitialized, onTxInit);
      dataManager.removeListener(DataManagerEvent.TxSubmitted, onTxSubmit);
      dataManager.removeListener(DataManagerEvent.TxFailed, onTxFailed);
      dataManager.removeListener(DataManagerEvent.TxConfirmed, onTxConfirmed);
    };
  }, [dataManager, setTxIntents, setError]);

  return { intents: txIntents, error };
}
