import { Group, Mesh, MeshBasicMaterial, SphereGeometry, Vector3 } from 'three';

export function makeCloud(
  origin: Vector3,
  cloudMaterial: MeshBasicMaterial,
  rand: () => number
): Group {
  const cloud = new Group();

  const cloudSpread = 0.05 + rand() * 0.2;
  for (let i = 0; i < 12; i++) {
    // const geometry = new SphereGeometry(0.01 + rand() * 0.02, 20, 20);
    const geometry = new SphereGeometry(0.01 + rand() * 0.02, 2, 2);
    const sphere = new Mesh(geometry, cloudMaterial);
    sphere.position.x = rand() * cloudSpread;
    sphere.position.y = rand() * cloudSpread;
    cloud.add(sphere);
  }

  const theta = rand() * 2 * Math.PI;
  const phi = rand() * Math.PI;
  const rho = 1;

  const x = rho * Math.cos(phi) * Math.sin(theta);
  const y = rho * Math.sin(phi) * Math.sin(theta);
  const z = rho * Math.cos(theta);

  cloud.position.x = x;
  cloud.position.y = y;
  cloud.position.z = z;

  cloud.lookAt(origin);

  return cloud;
}
