import autoBind from 'auto-bind';
import { PlanetRenderer } from './PlanetRenderer';

export class PreviewRenderer extends PlanetRenderer {
  frameRequestId: number;
  frameCount = 0;

  constructor(canvas: HTMLCanvasElement, size: number) {
    super(canvas, size, 0x000000, 0);
    autoBind(this);

    this.loop();
  }

  private loop() {
    this.moveToFrame(this.frameCount);
    this.render();
    this.frameCount++;

    window.requestAnimationFrame(this.loop);
  }

  public destroy() {
    window.cancelAnimationFrame(this.frameRequestId);
  }
}
