import { JsonRpcProvider, JsonRpcSigner, TransactionReceipt } from '@ethersproject/providers';
import { ContractInterface, Contract, ethers } from 'ethers';
import { Valhalla, ValhallaGetters } from 'common-contracts/typechain';
import { CORE_CONTRACT_ADDRESS, GETTERS_CONTRACT_ADDRESS, NETWORK } from 'common-contracts';
import { CORE_ABI } from 'common-contracts/abis/Valhalla';
import { GETTERS_ABI } from 'common-contracts/abis/ValhallaGetters';
import { sleep } from './BackendUtils';
import { EthConnectionType } from '../_types/transactions';
import { ConnectionErrors } from './ConnectionErrors';

const isProd = process.env.NODE_ENV === 'production';
let RPC_ENDPOINT = 'http://localhost:8545';

if (isProd) {
  if ((NETWORK as string) === 'mainnet') {
    RPC_ENDPOINT = 'https://mainnet.infura.io/v3/d5da2e6629094d3aa01b0f05a04ead23';
  } else if ((NETWORK as string) === 'rinkeby') {
    RPC_ENDPOINT = 'https://rinkeby.infura.io/v3/d5da2e6629094d3aa01b0f05a04ead23';
  } else if ((NETWORK as string) === 'xdai') {
    RPC_ENDPOINT = 'https://rpc.xdaichain.com/';
  }
}

class EthConnection {
  private provider: JsonRpcProvider;
  private signer: JsonRpcSigner | undefined;

  public constructor(connectionType: EthConnectionType) {
    if (connectionType === EthConnectionType.DEFAULT_RPC) {
      this.provider = new ethers.providers.StaticJsonRpcProvider(RPC_ENDPOINT);
    } else {
      this.loadInjectedSigner();
    }
  }

  public async getChainId(): Promise<number | undefined> {
    const network = await this.provider.getNetwork();
    return network.chainId;
  }

  public static async enableMetamask(): Promise<string> {
    if (!window.ethereum || !window.ethereum.isMetaMask) {
      throw new Error(ConnectionErrors.METAMASK_NOT_INSTALLED);
    }

    if (window.ethereum.selectedAddress) {
      console.log('selectedaddress', window.ethereum.selectedAddress);
      // already enabled!
      return window.ethereum.selectedAddress;
    }

    console.error('this should not get run');

    let myAddress: string;
    try {
      const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
      myAddress = accounts[0];
    } catch (e) {
      throw new Error(ConnectionErrors.METAMASK_NOT_ENABLED);
    }
    return myAddress;
  }

  // upgrades to metamask
  public loadInjectedSigner() {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    this.provider = provider;
    this.signer = provider.getSigner();
  }

  public loadContract<C extends Contract>(
    contractAddress: string,
    contractABI: ContractInterface,
    signerEnabled = false
  ): C {
    if (signerEnabled && this.signer) {
      return new Contract(contractAddress, contractABI, this.signer) as C;
    } else {
      console.log('WARNING: loading contract from provider (no signer)');
      return new Contract(contractAddress, contractABI, this.provider) as C;
    }
  }

  public loadCoreContract(signerEnabled: boolean): Valhalla {
    return this.loadContract(CORE_CONTRACT_ADDRESS, CORE_ABI, signerEnabled);
  }

  public loadGettersContract(signerEnabled: boolean): ValhallaGetters {
    return this.loadContract(GETTERS_CONTRACT_ADDRESS, GETTERS_ABI, signerEnabled);
  }

  public async waitForTransaction(txHash: string): Promise<TransactionReceipt> {
    return new Promise(async (resolve) => {
      let receipt = undefined;
      let tries = 0;

      // waitForTransaction tends to hang on xDAI. but if we have a txHash
      // the tx WILL get confirmed (or reverted) eventually, so for sure
      // just keep retrying
      while (!receipt) {
        console.log(`[wait-tx] WAITING ON tx hash: ${txHash} tries ${tries}`);

        receipt = await Promise.race([
          sleep(30 * 1000, undefined),
          this.provider.getTransactionReceipt(txHash).catch((e) => {
            console.error(`[wait-tx] TIMED OUT tx hash: ${txHash} tries ${tries} error:`, e);
            return undefined;
          }),
        ]);

        if (receipt) {
          console.log(`[wait-tx] FINISHED tx hash: ${txHash} tries ${tries}`);
          resolve(receipt);
          return;
        }

        // exponential backoff, in seconds:
        // 2 * (1, 1, 2, 3, 4, 6, 9, 13, 19, 29, 43, 65 ...)
        // But never more than a minute
        const sleepTime = Math.min(2000 * 1.5 ** tries, 60000);
        console.log(
          `[wait-tx] SLEEPING tx hash: ${txHash} tries ${tries} sleeping for: ${sleepTime} `
        );
        await sleep(sleepTime);
        tries += 1;
      }
    });
  }
}

export default EthConnection;
