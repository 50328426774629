import React from 'react';
import { R4Planet } from '../ValhallaGlobal/ValhallaPagePlanet';
import { PlanetLevel } from '../ValhallaGlobal/ValhallaTypes';

export function R4Planets() {
  return (
    <div>
      <>
        <>
          <R4Planet rank={63} bottom={1170} left={105} level={PlanetLevel.Level2} />
          <R4Planet rank={62} bottom={1300} left={300} level={PlanetLevel.Level2} />
          <R4Planet rank={61} bottom={1300} left={100} level={PlanetLevel.Level2} />
          <R4Planet rank={60} bottom={1440} left={250} level={PlanetLevel.Level2} />

          <R4Planet rank={59} bottom={1580} left={350} level={PlanetLevel.Level2} />
          <R4Planet rank={58} bottom={1580} left={525} level={PlanetLevel.Level2} />
          <R4Planet rank={57} bottom={1720} left={100} level={PlanetLevel.Level2} />
          <R4Planet rank={56} bottom={1720} left={400} level={PlanetLevel.Level2} />

          <R4Planet rank={55} bottom={1860} left={450} level={PlanetLevel.Level2} />
          <R4Planet rank={54} bottom={1860} left={280} level={PlanetLevel.Level2} />
          <R4Planet rank={53} bottom={2000} left={100} level={PlanetLevel.Level2} />
          <R4Planet rank={52} bottom={2000} left={510} level={PlanetLevel.Level2} />

          <R4Planet rank={51} bottom={2139} left={350} level={PlanetLevel.Level2} />
          <R4Planet rank={50} bottom={2279} left={220} level={PlanetLevel.Level2} />
          <R4Planet rank={49} bottom={2349} left={480} level={PlanetLevel.Level2} />
          <R4Planet rank={48} bottom={2349} left={100} level={PlanetLevel.Level2} />

          <R4Planet rank={47} bottom={2419} left={270} level={PlanetLevel.Level2} />
          <R4Planet rank={46} bottom={2559} left={80} level={PlanetLevel.Level2} />
          <R4Planet rank={45} bottom={2630} left={430} level={PlanetLevel.Level2} />
          <R4Planet rank={44} bottom={2700} left={200} level={PlanetLevel.Level2} />

          <R4Planet rank={43} bottom={2740} left={350} level={PlanetLevel.Level2} />
          <R4Planet rank={42} bottom={2840} left={120} level={PlanetLevel.Level2} />
          <R4Planet rank={41} bottom={2910} left={300} level={PlanetLevel.Level2} />
          <R4Planet rank={40} bottom={2980} left={520} level={PlanetLevel.Level2} />

          <R4Planet rank={39} bottom={2980} left={150} level={PlanetLevel.Level2} />
          <R4Planet rank={38} bottom={3120} left={300} level={PlanetLevel.Level2} />
          <R4Planet rank={37} bottom={3190} left={100} level={PlanetLevel.Level2} />
          <R4Planet rank={36} bottom={3260} left={420} level={PlanetLevel.Level2} />

          <R4Planet rank={35} bottom={3400} left={265} level={PlanetLevel.Level2} />
          <R4Planet rank={34} bottom={3400} left={510} level={PlanetLevel.Level2} />
          <R4Planet rank={33} bottom={3540} left={100} level={PlanetLevel.Level2} />
          <R4Planet rank={32} bottom={3540} left={350} level={PlanetLevel.Level2} />
        </>

        <>
          <R4Planet rank={31} bottom={3980} left={200} level={PlanetLevel.Level3} />
          <R4Planet rank={30} bottom={4120} left={100} level={PlanetLevel.Level3} />
          <R4Planet rank={29} bottom={4260} left={210} level={PlanetLevel.Level3} />
          <R4Planet rank={28} bottom={4400} left={450} level={PlanetLevel.Level3} />

          <R4Planet rank={27} bottom={4540} left={140} level={PlanetLevel.Level3} />
          <R4Planet rank={26} bottom={4680} left={340} level={PlanetLevel.Level3} />
          <R4Planet rank={25} bottom={4820} left={170} level={PlanetLevel.Level3} />
          <R4Planet rank={24} bottom={4960} left={490} level={PlanetLevel.Level3} />

          <R4Planet rank={23} bottom={5100} left={310} level={PlanetLevel.Level3} />
          <R4Planet rank={22} bottom={5240} left={100} level={PlanetLevel.Level3} />
          <R4Planet rank={21} bottom={5380} left={250} level={PlanetLevel.Level3} />
          <R4Planet rank={20} bottom={5520} left={360} level={PlanetLevel.Level3} />

          <R4Planet rank={19} bottom={5660} left={170} level={PlanetLevel.Level3} />
          <R4Planet rank={18} bottom={5800} left={470} level={PlanetLevel.Level3} />
          <R4Planet rank={17} bottom={5940} left={380} level={PlanetLevel.Level3} />
          <R4Planet rank={16} bottom={6080} left={200} level={PlanetLevel.Level3} />
        </>
        <>
          <R4Planet rank={15} bottom={6520} left={110} level={PlanetLevel.Level4} />
          <R4Planet rank={14} bottom={6545} left={280} level={PlanetLevel.Level4} />
          <R4Planet rank={13} bottom={6670} left={350} level={PlanetLevel.Level4} />
          <R4Planet rank={12} bottom={6795} left={200} level={PlanetLevel.Level4} />

          <R4Planet rank={11} bottom={6920} left={380} level={PlanetLevel.Level4} />
          <R4Planet rank={10} bottom={7045} left={120} level={PlanetLevel.Level4} />
          <R4Planet rank={9} bottom={7170} left={460} level={PlanetLevel.Level4} />
          <R4Planet rank={8} bottom={7295} left={190} level={PlanetLevel.Level4} />
        </>
        <>
          <R4Planet rank={7} bottom={7600} left={400} level={PlanetLevel.Level5} />
          <R4Planet rank={6} bottom={7800} left={100} level={PlanetLevel.Level5} />
          <R4Planet rank={5} bottom={8000} left={210} level={PlanetLevel.Level5} />
          <R4Planet rank={4} bottom={8200} left={480} level={PlanetLevel.Level5} />
        </>

        {/* Lv 6-7 */}
        <>
          <R4Planet rank={3} bottom={9300} left={100} level={PlanetLevel.Level6} />
          <R4Planet rank={2} bottom={9300} left={400} level={PlanetLevel.Level6} />
        </>

        {/* special prize winners */}
        <>
          <R4Planet
            originalWinner='0xcf1a82c49364d4523e23e24a1c342466613b1e03'
            bottom={10800}
            left={100 + 120}
            level={PlanetLevel.Level2}
          />
          <R4Planet
            originalWinner='0xe8d3dd97cd3a33b7b8f94e3195e98d3912ac50e9'
            bottom={10900}
            left={100 + 210}
            level={PlanetLevel.Level2}
          />
          <R4Planet
            originalWinner='0xa36ab50044b51c6f6f00a5da1ffa7727876d3b7d'
            bottom={11000}
            left={100 + 250}
            level={PlanetLevel.Level2}
          />
          <R4Planet
            originalWinner='0xa765ae8b428617728c0a6fc26674e6fa157ce7c5'
            bottom={11100}
            left={100 + 200}
            level={PlanetLevel.Level2}
          />
          <R4Planet
            originalWinner='0x8c8B07C2501b3A3b034202ba11f270cfE5F11c27'
            bottom={11200}
            left={100 + 180}
            level={PlanetLevel.Level2}
          />
          <R4Planet
            originalWinner='0xdeadeE24fF359b01B1B64312e3DFC42B8CCa8780'
            bottom={11300}
            left={100 + 190}
            level={PlanetLevel.Level2}
          />
          <R4Planet
            originalWinner='0xdead080e55b6918788d33959774c19ff7e914367'
            bottom={11400}
            left={100 + 80}
            level={PlanetLevel.Level2}
          />
          <R4Planet
            originalWinner='0x752aE801cdA83797AC73aEBfB61ab5A5551E5739'
            bottom={11500}
            left={100 + 300}
            level={PlanetLevel.Level2}
          />
          <R4Planet
            originalWinner='0x102e277c34668e96cbed6169fa1195002c11d746'
            bottom={11600}
            left={100 + 250}
            level={PlanetLevel.Level2}
          />
          <R4Planet
            originalWinner='0xaa23208770da9ae738a7a5069bcdfdc06e487821'
            bottom={11700}
            left={100 + 220}
            level={PlanetLevel.Level2}
          />
          <R4Planet
            originalWinner='0x4ddd223e5bc88422ae047cc57d4198f68519e42b'
            bottom={11800}
            left={100 + 170}
            level={PlanetLevel.Level2}
          />
          <R4Planet
            originalWinner='0x4750950cc2878e7441ecf6882e2ed2e487e938c2'
            bottom={11900}
            left={100 + 100}
            level={PlanetLevel.Level2}
          />
          <R4Planet
            originalWinner='0x0b652c646678ca09487435d8f329836fbc2ec6e1'
            bottom={10700}
            left={100 + 125}
            level={PlanetLevel.Level2}
          />

          <R4Planet
            originalWinner='0xe8d31274ba15d59d17fa2d54c750724aa969b4af'
            bottom={12100}
            left={100 + 240}
            level={PlanetLevel.Level3}
          />
          <R4Planet
            originalWinner='0x4b7b6d3dc60adbfd35271bcdfacc5fca5e3a2107'
            bottom={12000}
            left={100 + 100}
            level={PlanetLevel.Level3}
          />
          <R4Planet
            originalWinner='0xb5ce86c2ab9e2403ab47acfbe501845e2480fad9'
            bottom={12250}
            left={100 + 190}
            level={PlanetLevel.Level4}
          />
        </>

        <>
          <R4Planet rank={1} bottom={13500} left={300} level={PlanetLevel.Level7} centered />
        </>
      </>
    </div>
  );
}
