import { useEffect, useState } from 'react';
import { Monomitter, Callback } from './Monomitter';

/**
 * Execute something on emitter callback
 * @param emitter `Monomitter` to subscribe to
 * @param callback callback to subscribe
 */
export function useEmitterSubscribe<T>(emitter: Monomitter<T>, callback: Callback<T>) {
  useEffect(() => {
    const sub = emitter.subscribe(callback);

    return sub.unsubscribe;
  }, [emitter, callback]);
}

/**
 * Use returned value from an emitter
 * @param emitter `Monomitter` to subscribe to
 * @param initialVal initial state value
 */
export function useEmitterValue<T>(emitter: Monomitter<T>, initialVal: T) {
  const [val, setVal] = useState<T>(initialVal);

  useEffect(() => {
    const sub = emitter.subscribe((v) => setVal(v));

    return sub.unsubscribe;
  }, [emitter]);

  return val;
}
