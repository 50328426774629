export enum R1FileName {
  /* Round 1 */
  Background = 'background.jpg',
  Petals = 'petals.gif',
  Sun = 'sun.png',
  Grass = 'grass.jpg',
  Flowers_FG_L = 'flowers_fg_l.png',
  Flowers_FG_R = 'flowers_fg_r.png',
  Flowers_MG = 'flowers_mg.png',
  Flowers_BG = 'flowers_bg.png',
  Tree = 'tree.gif',
  Clouds_Ground_1 = 'clouds_ground_1.png',
  Clouds_Ground_2 = 'clouds_ground_2.png',
  Clouds_Ground_3 = 'clouds_ground_3.png',
  Satellite = 'satellite.png',
  Spaceship = 'spaceship.png',
  Moon = 'moon.png',
  UFO = 'ufo.png',
  Gems_1 = 'gems_1.png',
  Gems_2 = 'gems_2.png',
  Gems_3 = 'gems_3.png',
  Asteroids = 'asteroids.png',
  Galaxy_1 = 'galaxy_1.png',
  Galaxy_2 = 'galaxy_2.png',
  Galaxy_3 = 'galaxy_3.png',
  AngelLeft = 'angel_left.gif',
  AngelRight = 'angel_right.gif',
  AngelTop = 'angel_top.gif',
  Feathers = 'feathers.png',
  Clouds_Heaven_1 = 'clouds_heaven_1.png',
  Clouds_Heaven_2 = 'clouds_heaven_2.png',
  Clouds_Heaven_3 = 'clouds_heaven_3.png',
  Clouds_Top_L = 'clouds_heaven_top_l.png',
  Clouds_Top_R = 'clouds_heaven_top_r.png',
  Flower_Start = 'flower_start.gif',
  Flower_Blooming = 'flower_blooming.gif',
  Flower_Bloomed = 'flower_bloomed.png',
}

export enum R2FileName {
  Intro = 'intro.png',
  Intro_Banner = 'intro_banner.png',
  Tier_1 = 'tier_1.png',
  Tier_2 = 'tier_2.png',
  Tier_3 = 'tier_3.png',
  Tier_4 = 'tier_4.png',
  Tier_5 = 'tier_5.png',
  Tier_6 = 'tier_6.png',
}

export enum R3FileName {
  Tier_1 = 'Layer 1.jpg',
  Tier_2 = 'Layer 2.jpg',
  Tier_3 = 'Layer 3.jpg',
  Tier_4 = 'Layer 4.jpg',
  Tier_5 = 'Layer 5.jpg',
  Tier_6 = 'Layer 6.jpg',
}

export enum R4FileName {
  Tier_1 = 'Tier_1.png',
  Tier_2 = 'Tier_2_Special_Prizes.png',
  Tier_3 = 'Tier_3.png',
  Tier_4 = 'Tier_4.png',
  Tier_5 = 'Tier_5.png',
  Tier_6 = 'Tier_6.png',
}
export enum R5FileName {
  Tier_1 = 'Tier_1.jpg',
  Special_Mentions = 'Special_Mentions.jpg',
  Tier_2 = 'Tier_2.jpg',
  Tier_3 = 'Tier_3.jpg',
  Tier_4 = 'Tier_4.jpg',
  Tier_5 = 'Tier_5.jpg',
  Tier_6 = 'Tier_6.jpg',
}

export type FileName = R1FileName | R2FileName | R3FileName | R4FileName | R5FileName;

export enum ValhallaZIndex {
  Background = 0,
  Planets = 100,
  Banners = 200,
  Integraton = 300,
}

export enum BoundingBox {
  FillXFitY,
  FitXFitY,
}

export enum BackgroundPlacement {
  Background,
  BottomCenterObject,
  HorizontalLoop,
  HorizontalLoopCenter,
  BottomLeftObject,
  BottomRightObject,
}

type Position = { bottom: number; left?: number; right?: number };

export enum RoundNumber {
  Round1 = `round1`,
  Round2 = `round2`,
  Round3 = `round3`,
  Round4 = `round4`,
  Round5 = `round5`,
}

export const roundNumberNames: Record<RoundNumber, string> = {
  [RoundNumber.Round1]: 'Round 1',
  [RoundNumber.Round2]: 'Round 2',
  [RoundNumber.Round3]: 'Round 3',
  [RoundNumber.Round4]: 'Round 4',
  [RoundNumber.Round5]: 'Round 5',
};

export type ImageProps = {
  imgWidth: number;
  imgHeight: number;
  zIndex: number;
  boundingBox: BoundingBox;
  placement: BackgroundPlacement;
  position?: Position;
};

export type LoadedImageProps = ImageProps & { round?: RoundNumber; url: string };

export enum PlanetLevel {
  Level2 = 2,
  Level3 = 3,
  Level4 = 4,
  Level5 = 5,
  Level6 = 6,
  Level7 = 7,
}
